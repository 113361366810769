import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_GAMES, GET_GAME_BY_ID } from "graphql/query/admin";
import { gameConfigStyles } from "../gameStyles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loader from "Components/Loader";
import { SwitchStyles1 } from "Modules/Admin/Administrator/administratorStyles";
import { UPDATE_GAME } from "graphql/mutation/admin";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";

const formatTimestampToDateTimeLocal = (timestamp) => {
  const date = timestamp ? new Date(parseInt(timestamp, 10)) : new Date();
  return date.toISOString().slice(0, 16);
};

const GameConfiguration = () => {
  const classes = gameConfigStyles();
  const { data, error, loading } = useQuery(GET_ALL_GAMES, {
    variables: { first: 10, orderBy: "createdAt_DESC" },
    fetchPolicy: "network-only",
  });

  const [updateGame] = useMutation(UPDATE_GAME);
  const [selectedGameId, setSelectedGameId] = useState(null);

  useEffect(() => {
    if (data?.getAllGames?.edges.length > 0) {
      setSelectedGameId(data.getAllGames.edges[0].node.id);
    }
  }, [data]);

  const {
    data: gameIdData,
    error: errorById,
    loading: loadingById,
    refetch: refetchById,
  } = useQuery(GET_GAME_BY_ID, {
    variables: { getGameById: selectedGameId },
    skip: !selectedGameId,
    fetchPolicy: "network-only",
  });

  const handleSave = async (values) => {
    try {
      const { maintenanceStartTime, maintenanceEndTime, ...otherValues } =
        values;
      const response = await updateGame({
        variables: {
          updateGameId: selectedGameId,
          input: {
            ...otherValues,
            // maintenanceSchedule: {
            //   startTime: values.maintenanceStartTime,
            //   endTime: values.maintenanceEndTime,
            // },
          },
        },
      });
      console.log(response, "res");
      refetchById();
      toast.success(
        response?.data?.updateGame?.message || "Game updated successfully!"
      );
    } catch (error) {
      console.error("Error updating game:", error);
      toast.error(error?.message || "Failed to update game. Please try again.");
    }
  };

  if (loading || loadingById) {
    return <Loader />;
  }

  if (error || errorById) {
    return <p>Error: {error.message}</p>;
  }

  const getInitialValues = (game) => {
    const commonFields = {
      betLimit: game.betLimit || "",
      // payoutRatio: game.payoutRatio || "",
      isEnabled: game.isEnabled || false,
      // maintenanceStartTime: formatTimestampToDateTimeLocal(
      //   game?.maintenanceSchedule?.startTime
      // ),
      // maintenanceEndTime: formatTimestampToDateTimeLocal(
      //   game?.maintenanceSchedule?.endTime
      // ),
      // totalActiveRooms: game.totalActiveRooms || "",
    };

    switch (game.name) {
      case "Roulette":
        return {
          ...commonFields,
          straight_up: game.straight_up || "",
          split: game.split || "",
          street: game.street || "",
          corner: game.corner || "",
          line: game.line || "",
          column: game.column || "",
          dozen: game.dozen || "",
          red_black: game.red_black || "",
          odd_even: game.odd_even || "",
          high_low: game.high_low || "",
          roundStartsInSeconds: game.roundStartsInSeconds || "",
          playersAllowedInRoom: game.playersAllowedInRoom || "",
        };
      case "Blackjack":
        return {
          ...commonFields,
          blackjackPlayerWinnerMultipier:
            game.blackjackPlayerWinnerMultipier || "",
          blackjackDealerBustedMultiplier:
            game.blackjackDealerBustedMultiplier || "",
          // time_per_player_in_sec: game.time_per_player_in_sec || "",
          roundStartsInSeconds: game.roundStartsInSeconds || "",
          playersAllowedInRoom: game.playersAllowedInRoom || "",
        };
      case "Baccarat":
        return {
          ...commonFields,
          bankerMultiplier: game.bankerMultiplier || "",
          playerMultiplier: game.playerMultiplier || "",
          tieMultiplier: game.tieMultiplier || "",
          // time_per_player_in_sec: game.time_per_player_in_sec || "",
          roundStartsInSeconds: game.roundStartsInSeconds || "",
          playersAllowedInRoom: game.playersAllowedInRoom || "",
        };
      case "Slots":
        return {
          ...commonFields,
          slotWinningMultiplier: game.slotWinningMultiplier || "",
          // roundStartsInSeconds: game.roundStartsInSeconds || "",
        };
      case "Poker":
        return {
          ...commonFields,
          smallBlindAmount: game.smallBlindAmount || "",
          rakePercentage: game.rakePercentage || "",
          playersAllowedInRoom: game.playersAllowedInRoom || "",
          // roundStartsInSeconds: game.roundStartsInSeconds || "",
          // time_per_player_in_sec: game.time_per_player_in_sec || "",
        };
      default:
        return commonFields;
    }
  };

  const validationSchema = Yup.object().shape({
    betLimit: Yup.number().nullable(),
    // payoutRatio: Yup.string()
    //   .matches(
    //     /^\d+[:/]\d+$/,
    //     "Payout ratio must be in the format 'x:y' or 'x/y'"
    //   )
    //   .nullable(),
    isEnabled: Yup.boolean(),
    // maintenanceStartTime: Yup.date().nullable(),
    // maintenanceEndTime: Yup.date().nullable(),
  });

  return (
    <div className={classes.gameConfig}>
      <div className={classes.gameConfigHeader}>
        {data?.getAllGames?.edges?.map((game) => (
          <div
            key={game.node.id}
            className={`${classes.gameConfigBox} ${
              selectedGameId === game.node.id ? classes.selectedGame : ""
            }`}
            onClick={() => setSelectedGameId(game.node.id)}
          >
            {game.node.name}
          </div>
        ))}
      </div>

      {gameIdData && (
        <Formik
          initialValues={getInitialValues(gameIdData.getGameById)}
          validationSchema={validationSchema}
          onSubmit={handleSave}
        >
          {({ setFieldValue, values, initialValues }) => {
            const hasChanges =
              JSON.stringify(values) !== JSON.stringify(initialValues);
            const game = gameIdData.getGameById.name;

            return (
              <Form>
                <Grid lg={12} md={12} sm={12} className={classes.formContainer}>
                  {/* Common Fields */}
                  {/* Common Fields for All Games */}

                  <Grid lg={4} md={6} sm={12}>
                    <div className={classes.formFlex}>
                      <div className={`${classes.dflex}`}>
                        <label className={classes.inputLabel}>
                          Is Enabled:
                        </label>
                        <SwitchStyles1
                          name="isEnabled"
                          checked={values.isEnabled}
                          onChange={(e) =>
                            setFieldValue("isEnabled", e.target.checked)
                          }
                        />
                      </div>
                      <div className={`${classes.dflex}`}>
                        <label className={classes.inputLabel}>Bet Limit:</label>
                        <Field
                          className={`${classes.inputHeight}`}
                          type="number"
                          name="betLimit"
                        />
                      </div>
                      {/* <div className={`${classes.dflex}`}>
                        <label className={classes.inputLabel}>
                          Payout Ratio:
                        </label>
                        <Field
                          className={`${classes.inputHeight}`}
                          type="text"
                          name="payoutRatio"
                        />
                      </div> */}
                      {/* <div className={`${classes.dflex}`}>
                        <label className={classes.inputLabel}>
                          Total Active Rooms:
                        </label>
                        <Field
                          className={`${classes.inputHeight}`}
                          type="number"
                          name="totalActiveRooms"
                        />
                      </div> */}
                      {/* <div className={`${classes.dflex}`}>
                        <label className={classes.inputLabel}>
                          Maintenance Start:
                        </label>
                        <Field
                          className={`${classes.inputHeight}`}
                          type="datetime-local"
                          name="maintenanceStartTime"
                        />
                      </div>
                      <div className={`${classes.dflex}`}>
                        <label className={classes.inputLabel}>
                          Maintenance End:
                        </label>
                        <Field
                          className={`${classes.inputHeight}`}
                          type="datetime-local"
                          name="maintenanceEndTime"
                        />
                      </div> */}

                      {/* Slot Specific Fields */}
                      {game === "Slots" && (
                        <>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Winning Multiplier:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="slotWinningMultiplier"
                            />
                          </div>
                          {/* <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Round Start Time (sec):
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="roundStartsInSeconds"
                            />
                          </div> */}
                        </>
                      )}

                      {/* Blackjack Specific Fields */}
                      {game === "Blackjack" && (
                        <>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Players Allowed:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="playersAllowedInRoom"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Player Winner Multiplier:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="blackjackPlayerWinnerMultipier"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Dealer Busted Multiplier:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="blackjackDealerBustedMultiplier"
                            />
                          </div>
                          {/* <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Time Per Player (sec):
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="time_per_player_in_sec"
                            />
                          </div> */}
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Round Start Time (sec):
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="roundStartsInSeconds"
                            />
                          </div>
                        </>
                      )}

                      {/* Baccarat Specific Fields */}
                      {game === "Baccarat" && (
                        <>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Players Allowed:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="playersAllowedInRoom"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Banker Multiplier:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="bankerMultiplier"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Player Multiplier:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="playerMultiplier"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Tie Multiplier:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="tieMultiplier"
                            />
                          </div>
                          {/* <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Time Per Player (sec):
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="time_per_player_in_sec"
                            />
                          </div> */}
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Round Start Time (sec):
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="roundStartsInSeconds"
                            />
                          </div>
                        </>
                      )}

                      {/* Poker Specific Fields */}
                      {game === "Poker" && (
                        <>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Players Allowed:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="playersAllowedInRoom"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Small Blind Amount:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="smallBlindAmount"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Rake Percentage:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="rakePercentage"
                            />
                          </div>
                          {/* <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Time Per Player (sec):
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="time_per_player_in_sec"
                            />
                          </div> */}
                        </>
                      )}

                      {/* Roulette Specific Fields half*/}
                      {game === "Roulette" && (
                        <>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Players Allowed:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="playersAllowedInRoom"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Straight Up:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="straight_up"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>Split:</label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="split"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Street:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="street"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Corner:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="corner"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>

                  <Grid lg={4} md={6} sm={12}>
                    <div className={classes.formFlex}>
                      {/* Roulette Specific Fields other half */}
                      {game === "Roulette" && (
                        <>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>Line:</label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="line"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Column:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="column"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>Dozen:</label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="dozen"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Red/Black:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="red_black"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Odd/Even:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="odd_even"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              High/Low:
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="high_low"
                            />
                          </div>
                          <div className={`${classes.dflex}`}>
                            <label className={classes.inputLabel}>
                              Round Start Time (sec):
                            </label>
                            <Field
                              className={`${classes.inputHeight}`}
                              type="number"
                              name="roundStartsInSeconds"
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>

                  <Grid xs={12} sm={12} md={3} lg={3} xl={3}>
                    <div className={classes.buttonContainer}>
                      <button
                        className={`${
                          hasChanges ? classes.button : classes.disableButton
                        }`}
                        type="submit"
                        disabled={!hasChanges}
                      >
                        Save
                      </button>
                    </div>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default GameConfiguration;
