/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  Container, Grid, TextField, Button, CircularProgress, Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { useNftMarketplaceContract, useUserAccount } from 'hooks';
import { formStyles } from './FormStyles';

const validationSchema = yup.object({
  contractAddress: yup
    .string('Enter the Contract Address')
    .required('Contract Address is required'),
});

const CreateContract = ({
  handleOnSuccess, toggleDrawer,
}) => {
  const classes = formStyles();
  const marketplaceContract = useNftMarketplaceContract();
  const { account } = useUserAccount();
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const handleCreateForm = async (values) => {
    try {
      setLoading(true);
      setErrorMsg('');

      // Add contract address using web3 contract integration
      await
      marketplaceContract.methods.whitelistNFTContracts([values.contractAddress]).send({
        from: account,
      });
      setLoading(false);
      handleOnSuccess();
      toggleDrawer('right')();
    } catch (err) {
      if (err.code === 'INVALID_ARGUMENT') { setErrorMsg('Invalid Contract Address'); } else if (err.code === 4001) { setErrorMsg('User declined transaction'); } else { setErrorMsg('Something went wrong !'); }
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      contractAddress: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleCreateForm(values);
    },
  });

  return (
    <Container
      maxWidth={false}
      disableGutters
      className={classes.app}
    >
      <Grid
        container
        className={classes.formBox}
      >
        <form
          noValidate
          autoComplete="off"
          id="adminForm"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <TextField
            id="contractAddress"
            name="contractAddress"
            // label="Category Name"
            placeholder="Contract Address"
            variant="outlined"
            className={classes.width100}
            value={formik.values.contractAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.contractAddress && Boolean(formik.errors.contractAddress)}
            helperText={formik.touched.contractAddress && formik.errors.contractAddress}
            InputProps={{
              classes: {
                input: classes.placeHolder,
              },
              className: classes.inputHeight,
            }}
          />
          {errorMsg.length > 0 && <Typography variant="subtitle1" className={classes.errorText}>{errorMsg}</Typography> }

          <Button color="primary" variant="contained" fullWidth type="submit" className={classes.submitBtn}>
            { loading && <CircularProgress size={20} />}
            { !loading && 'Submit' }
          </Button>
        </form>
      </Grid>
    </Container>
  );
};

export default CreateContract;
