/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Box } from "@material-ui/core";

import { Loader } from "Components/TableLoader";
import { GET_ALL_PLATFORM_VARIABLES } from "graphql/query/admin";
import Table from "Components/Table/Table";
import EditVariable from "Components/Modal/EditPlatformModal";
import SuccessModal from "Components/Modal/SuccessModal";

const PlatformTable = ({ setErrorMsg, editFlag, setEditFlag }) => {
  const [editConfirmation, setEditConfirmation] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [variables, setVariables] = useState([]);
  const [editId, setEditId] = useState({});
  const { loading, error, data, refetch } = useQuery(
    GET_ALL_PLATFORM_VARIABLES
  );

  const setAllVariables = async () => {
    if (data?.variables) {
      // Adjust this to ensure correct data extraction based on your data structure
      const extractedVariables = data.variables?.variables.map(
        (item, index) => {
          return {
            // id: index + 1,
            isActive: item?.isActive ?? "N/A",
            name: item?.name ?? "N/A",
            value: item?.value ?? "N/A",
            _id: item?.id,
            tokenId: item?.tokenId,
          };
        }
      );
      setVariables(extractedVariables);
      setEditId(data.variables?.id);
      setErrorMsg("");
    }
  };

  useEffect(() => {
    if (error) {
      setErrorMsg("Error in loading data !");
    } else if (data) {
      setAllVariables();
    }
  }, [data, error]);

  const handleSuceessClose = () => {
    setOpenSuccessModal(false);
    setEditConfirmation(false);
  };
  const handleOneditFlagSuccess = () => {
    setEditFlag(false);
  };
  const handleEditFlagClose = () => {
    setEditFlag(false);
    setOpenSuccessModal(true);
  };

  const handleOnEditSuccess = () => {
    setEditConfirmation(true);
    refetch();
    setTimeout(() => {
      setEditConfirmation(false);
    }, 3000);
  };
  const handleViewButton = () => {};
  const handleEditButton = () => {};
  const handleDeleteButton = () => {};

  const TABLE_HEAD = [
    {
      id: "id",
      flag: "id",
      label: "Sr.No",
      alignRight: false,
    },
    {
      id: "isActive",
      flag: "isActive",
      label: "Status",
      alignRight: false,
    },
    {
      id: "name",
      flag: "name",
      label: "Variable Name",
      alignRight: false,
    },
    {
      id: "value",
      flag: "value",
      label: "Value (In percantage)",
      alignRight: false,
    },
    {
      id: "actions",
      flag: { read: false, edit: false, remove: false },
      callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: "Actions",
      alignRight: false,
    },
  ];

  if (loading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Table
        noPagination
        USERLIST={variables}
        TABLE_HEAD={TABLE_HEAD}
        type=""
      />
      <EditVariable
        variables={variables}
        type="platform"
        editId={editId}
        handleOnEditSuccess={handleOnEditSuccess}
        handleClose={handleEditFlagClose}
        open={editFlag}
        heading="Edit Platform Variable"
        button1="Submit"
        onButton1Click={handleEditFlagClose}
        onButton2Click={handleOneditFlagSuccess}
      />
      {editConfirmation && (
        <SuccessModal
          handleClose={handleSuceessClose}
          open={openSuccessModal}
          heading="Variable edited successfully"
          subtitle=""
          type="edit"
        />
      )}
    </>
  );
};

export default PlatformTable;
