import { Container, Grid, Typography, Button, Box } from "@material-ui/core";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";

import Default from "Assets/Images/default.png";
import SuccessModal from "Components/Modal/SuccessModal";
import ConfirmationModal from "Components/Modal/ConfirmationModal";
import { Loader } from "Components/TableLoader/index";
import {
  VIEW_USER,
  GET_USER_TRANSACTIONS,
  GET_NEXT_USER_TRANSACTIONS,
  GET_PREV_USER_TRANSACTIONS,
  GET_USER_BETS,
  GET_NEXT_USER_BETS,
  GET_PREV_USER_BETS,
  ADMIN_PROFILE,
} from "graphql/query/admin";
import { BLOCK_USER } from "graphql/mutation/user";
import userDefault from "Assets/Svg/icon4.svg";
import { useStyles } from "./ViewUserStyles";
import MainTable from "Components/Table/Table";
import { copyToClipboard } from "utils";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const ViewUser = () => {
  const classes = useStyles();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [nsfw, setNsfw] = useState(false);
  const [nsfwConfirmation, setNsfwConfirmation] = useState(false);
  const { state } = useLocation();
  const { id = "" } = state || "";
  const [isBlocked, setIsBlocked] = useState(false);

  const [userBettingData, setuserBettingData] = useState([]);
  const [betPageInfo, setBetPageInfo] = useState("");
  const [betPage, setBetPage] = useState(0);

  const [userTransactionData, setUserTransactionData] = useState([]);
  const [transactionPageInfo, setTransactionPageInfo] = useState("");
  const [transactionPage, setTransactionPage] = useState(0);

  const [getBetNextPage, { loading: nextBetPageLoader }] =
    useLazyQuery(GET_NEXT_USER_BETS);
  const [getBetPrevPage, { loading: prevBetPageLoader }] =
    useLazyQuery(GET_PREV_USER_BETS);

  const [getTransactionNextPage, { loading: nextTransactionPageLoader }] =
    useLazyQuery(GET_NEXT_USER_TRANSACTIONS);
  const [getTransactionPrevPage, { loading: prevTransactionPageLoader }] =
    useLazyQuery(GET_PREV_USER_TRANSACTIONS);

  const handleBetNextPage = async () => {
    if (betPageInfo.hasNextPage) {
      const nextPageData = await getBetNextPage({
        variables: {
          first: 10,
          after: betPageInfo.endCursor,
          orderBy: "createdAt_DESC",
          filters: {
            _user: id,
          },
        },
        fetchPolicy: "network-only",
      });

      if (nextPageData.data.userBetting) {
        const list = nextPageData.data.userBetting.edges.map((bet) => bet.node);
        setuserBettingData(list);
        setBetPage(betPage + 1);
        setBetPageInfo(nextPageData.data.userBetting.pageInfo);
      }
    }
  };

  const handleBetPreviousPage = async () => {
    if (betPageInfo.hasPreviousPage) {
      const prevPageData = await getBetPrevPage({
        variables: {
          last: 10,
          before: betPageInfo.startCursor,
          orderBy: "createdAt_DESC",
          filters: {
            _user: id,
          },
        },
        fetchPolicy: "network-only",
      });

      if (prevPageData.data.userBetting) {
        const list = prevPageData.data.userBetting.edges.map((bet) => bet.node);
        setuserBettingData(list);
        setBetPage(betPage - 1);
        setBetPageInfo(prevPageData.data.userBetting.pageInfo);
      }
    }
  };

  const handleTransactionNextPage = async () => {
    if (transactionPageInfo.hasNextPage) {
      const nextPageData = await getTransactionNextPage({
        variables: {
          first: 10,
          after: transactionPageInfo.endCursor,
          orderBy: "createdAt_DESC",
          filters: {
            _user: id,
          },
        },
        fetchPolicy: "network-only",
      });

      if (nextPageData.data.userTransaction) {
        const list = nextPageData.data.userTransaction.edges.map(
          (txn) => txn.node
        );
        setUserTransactionData(list);
        setTransactionPage(transactionPage + 1);
        setTransactionPageInfo(nextPageData.data.userTransaction.pageInfo);
      }
    }
  };

  const handleTransactionPreviousPage = async () => {
    if (transactionPageInfo.hasPreviousPage) {
      const prevPageData = await getTransactionPrevPage({
        variables: {
          last: 10,
          before: transactionPageInfo.startCursor,
          orderBy: "createdAt_DESC",
          filters: {
            _user: id,
          },
        },
        fetchPolicy: "network-only",
      });

      if (prevPageData.data.userTransaction) {
        const list = prevPageData.data.userTransaction.edges.map(
          (txn) => txn.node
        );
        setUserTransactionData(list);
        setTransactionPage(transactionPage - 1);
        setTransactionPageInfo(prevPageData.data.userTransaction.pageInfo);
      }
    }
  };

  const { data, loading, error, refetch } = useQuery(VIEW_USER, {
    variables: { userId: id },
    fetchPolicy: "network-only",
  });

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
    refetch: profileRefetch,
  } = useQuery(ADMIN_PROFILE, {
    fetchPolicy: "network-only",
  });

  const canBlockUser = profileData?.me?.permissions?.User?.BLOCK === true;

  const {
    data: userTransaction,
    loading: userTransactionLoading,
    error: userTransactionError,
  } = useQuery(GET_USER_TRANSACTIONS, {
    variables: {
      first: 10,
      orderBy: "createdAt_DESC",
      filters: { _user: id },
    },
    fetchPolicy: "network-only",
  });

  const {
    data: userBetting,
    loading: userBettingLoading,
    error: userBettingError,
  } = useQuery(GET_USER_BETS, {
    variables: {
      first: 10,
      orderBy: "createdAt_DESC",
      filters: { _user: id },
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (userBetting) {
      setuserBettingData(
        userBetting.userBetting.edges.map((bet) => ({
          ...bet.node,
          winnings: bet.node.status,
          txnHash:
            bet?.node?.transactionData?.txnHash?.length > 10
              ? bet?.node?.transactionData?.txnHash.slice(0, 10) + "..."
              : bet?.node?.transactionData?.txnHash,
          fullTxnHash: bet?.node?.transactionData?.txnHash,
        }))
      );
      setBetPageInfo(userBetting.userBetting.pageInfo);
    }
  }, [userBetting]);

  useEffect(() => {
    if (userTransaction) {
      setUserTransactionData(
        userTransaction.userTransaction.edges.map((txn) => ({
          ...txn.node,
          txnHash:
            txn.node.txnHash.length > 10
              ? txn.node.txnHash.slice(0, 10) + "..."
              : txn.node.txnHash,
          fullTxnHash: txn.node.txnHash,
        }))
      );
      setTransactionPageInfo(userTransaction.userTransaction.pageInfo);
    }
  }, [userTransaction]);

  const [blockUser, { error: blockUserError, loading: blockUserLoading }] =
    useMutation(BLOCK_USER, {
      variables: { input: { id, isBlockedByAdmin: isBlocked } },
    });

  useEffect(() => {
    if (state === null) {
      console.error("No role exists !");
    }
  }, [state]);

  const handleblockUser = async () => {
    try {
      // Get the current block status directly from the data
      const currentIsBlocked =
        data?.user?.user?.accountMetadata?.isBlockedByAdmin;

      // Perform the mutation directly with the updated value
      await blockUser({
        variables: {
          input: {
            id,
            isBlockedByAdmin: !currentIsBlocked, // Toggle the status
          },
        },
      });

      setOpenSuccessModal(true);
      setNsfwConfirmation(true);
    } catch (err) {
      console.error(err, blockUserError);
    }
    setNsfw(false);
  };

  const handleblockUserSuceessClose = () => {
    refetch();
    setOpenSuccessModal(false);
    setNsfwConfirmation(false);
  };

  const handleModalClose = () => {
    setNsfw(false);
  };

  if (
    loading ||
    blockUserLoading ||
    userTransactionLoading ||
    userBettingLoading
  ) {
    return <Loader />;
  }

  if (error || blockUserError || userTransactionError || userBettingError) {
    return (
      <Container
        maxWidth={false}
        disableGutters
        className={classes.roleContainer}
      >
        <Typography variant="subtitle1" className={classes.error}>
          Something Went Wrong !
        </Typography>
      </Container>
    );
  }

  const BET_TABLE_HEAD = [
    { id: "id", label: "Sr.No", alignRight: false },
    { id: "gameType", label: "Game", alignRight: false },
    { id: "betAmount", label: "Bet Played", alignRight: false },
    { id: "createdAt", label: "Date", alignRight: false },
    { id: "txnHash", label: "Txn Hash", alignRight: false },
    { id: "winnings", label: "Winnings", alignRight: false },
  ];

  const TXN_TABLE_HEAD = [
    { id: "id", label: "Sr.No", alignRight: false },
    { id: "type", label: "Mode", alignRight: false },
    { id: "amount", label: "Amount", alignRight: false },
    { id: "txnHash", label: "Txn Hash", alignRight: false },
    { id: "createdAt", label: "Date", alignRight: false },
  ];

  return (
    <Container className={classes.root} maxWidth={false} disableGutters>
      <Typography className={classes.path}>
        <Link to="/admin/user" className={classes.link}>
          User
        </Link>
        &gt;
        <Typography className={classes.cursorPointer}> View User </Typography>
      </Typography>
      <Grid container className={classes.profileHeadContainer}>
        <Grid lg={12} md={12} xs={12} item>
          <Grid container>
            <Grid lg={1} md={1} sm={1} item />
            <Grid lg={7} md={7} sm={7} item>
              <div
                style={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <table className={classes.profileInfo}>
                  <tbody>
                    <tr className={classes.profileRow}>
                      <td className={classes.profileColumn1}>User Name</td>
                      <td className={classes.profileColumn}>
                        {data.user?.user?.username || "-"}
                      </td>
                    </tr>
                    <tr className={classes.profileRow}>
                      <td className={classes.profileColumn1}>Email</td>
                      <td className={classes.profileColumn}>
                        {data.user?.user?.email || "-"}
                      </td>
                    </tr>
                    <tr className={classes.profileRow}>
                      <td className={classes.profileColumn1}>
                        Registration Date
                      </td>
                      <td className={classes.profileColumn}>
                        {moment(data.user?.user?.createdAt).format(
                          "MMMM Do YYYY"
                        ) || "-"}
                      </td>
                    </tr>
                    <tr className={classes.profileRow}>
                      <td className={classes.profileColumn1}>Status</td>
                      <td className={classes.profileColumn}>
                        {data.user?.user?.accountMetadata?.isBlockedByAdmin
                          ? "Blocked"
                          : "Not Blocked" || "-"}
                      </td>
                    </tr>
                    <tr className={classes.profileRow}>
                      <td className={classes.profileColumn1}>Wallet Address</td>
                      <td className={classes.profileColumn}>
                        {data.user?.user?.walletAddress || "-"}
                      </td>
                    </tr>
                    <tr className={classes.profileRow}>
                      <td className={classes.profileColumn1}>
                        Available Chips
                      </td>
                      <td className={classes.profileColumn}>
                        {data.user?.user?.availableBalance || "-"}
                      </td>
                    </tr>
                    <tr className={classes.profileRow}>
                      <td className={classes.profileColumn1}>
                        Total Bet Placed
                      </td>
                      <td className={classes.profileColumn}>
                        {data.user?.totalBetPlaced || "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {canBlockUser && (
                <Button
                  color="primary"
                  onClick={() => setNsfw(true)}
                  size="small"
                  variant="outlined"
                  style={{
                    width: "120px",
                    height: "40px",
                    background: "white",
                    marginRight: "20px",
                    fontWeight: "bold",
                    marginTop: "30px",
                  }}
                >
                  {data?.user?.user?.accountMetadata?.isBlockedByAdmin
                    ? "Unblock"
                    : "Block"}{" "}
                  User
                </Button>
              )}
            </Grid>
            <Grid lg={3} md={3} sm={3} item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <img
                  src={data.user?.user?.image?.url || userDefault}
                  onError={(e) => (e.target.src = Default)}
                  alt="User"
                  className={classes.avatar}
                />
              </div>
            </Grid>
            <Grid lg={1} md={1} sm={1} item />
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.paddingAll}>
        <Box className={classes.dFlex}>
          <Typography
            variant="h5"
            className={classes.header1}
            style={{ margin: "40px 0px" }}
          >
            User Bets
          </Typography>
        </Box>
        {nextBetPageLoader || prevBetPageLoader ? (
          <>
            <Loader />
          </>
        ) : (
          <MainTable
            TABLE_HEAD={BET_TABLE_HEAD}
            // USERLIST={userBettingData}
            USERLIST={userBettingData?.map((item, index) => {
              return {
                ...item,
                txnHash: item.txnHash ? (
                  <div className={classes.copyContainer}>
                    <span>{item?.txnHash}</span>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        copyToClipboard(
                          item.fullTxnHash,
                          "Txn Hash copied successfully"
                        )
                      }
                    >
                      <FileCopyIcon />
                    </div>
                  </div>
                ) : (
                  "-"
                ),
              };
            })}
            page={betPage}
            handleNextPage={handleBetNextPage}
            handlePreviousPage={handleBetPreviousPage}
          />
        )}
      </Box>
      <Box className={classes.paddingAll}>
        <Box className={classes.dFlex}>
          <Typography
            variant="h5"
            className={classes.header1}
            style={{ margin: "40px 0px" }}
          >
            User Transactions
          </Typography>
        </Box>
        {nextTransactionPageLoader || prevTransactionPageLoader ? (
          <>
            <Loader />
          </>
        ) : (
          <MainTable
            TABLE_HEAD={TXN_TABLE_HEAD}
            // USERLIST={userTransactionData}
            USERLIST={userTransactionData.map((txn, index) => ({
              ...txn,
              txnHash: txn.txnHash ? (
                <div className={classes.copyContainer}>
                  <span>{txn.txnHash}</span>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      copyToClipboard(
                        txn.fullTxnHash,
                        "Txn Hash copied successfully"
                      )
                    }
                  >
                    <FileCopyIcon />
                  </div>
                </div>
              ) : (
                "-"
              ),
            }))}
            page={transactionPage}
            handleNextPage={handleTransactionNextPage}
            handlePreviousPage={handleTransactionPreviousPage}
          />
        )}
      </Box>
      {nsfw && (
        <ConfirmationModal
          handleClose={handleModalClose}
          open={nsfw}
          heading={`${
            data?.user?.user?.accountMetadata?.isBlockedByAdmin
              ? "Unblock"
              : "Block"
          } User`}
          subtitle={`Are you sure you want to ${
            data?.user?.user?.accountMetadata?.isBlockedByAdmin
              ? "unblock"
              : "block"
          } ${data?.user?.user?.username}?`}
          button1="Cancel"
          button2="Confirm"
          onButton1Click={handleModalClose}
          onButton2Click={handleblockUser}
        />
      )}
      {nsfwConfirmation && (
        <SuccessModal
          handleClose={handleblockUserSuceessClose}
          open={openSuccessModal}
          heading={`User ${
            data?.user?.user?.accountMetadata?.isBlockedByAdmin
              ? "Unblock"
              : "Block"
          }`}
          subtitle={`User ${
            data?.user?.user?.accountMetadata?.isBlockedByAdmin
              ? "unblocked"
              : "blocked"
          } successfully`}
          type="delete"
        />
      )}
    </Container>
  );
};

export default ViewUser;
