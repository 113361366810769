/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Box,
  Typography,
  InputLabel,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import { Formik } from "formik";
import * as yup from "yup";
import { useQuery, useMutation } from "@apollo/client";

import { GET_ALL_ROLES } from "graphql/query/admin";
import { CREATE_ADMIN, EDIT_ADMIN } from "graphql/mutation/admin";
import { labels } from "_mocks/permissonLabels";
import ConfirmationModal from "Components/Modal/ConfirmationModal";
import { formStyles } from "./AdminManagementStyles";
import Switch from "../Switch/Switch2";
import { default as ReactSelect } from "react-select";
import CountryList from "country-list-with-dial-code-and-flag";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  fullName: yup.string("Enter your Name").required("Name is required"),
  countryCode: yup
    .string("Select the Country code")
    .required("Country code is required"),
  roleId: yup.string("Select the role").required("Role is required"),
  mobile: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits"),
  // .min(10, 'Must be exactly 10 digits')
  // .max(10, 'Must be exactly 10 digits'),
});

// eslint-disable-next-line react/prop-types
const CreateAndEditAdmin = ({
  initialValues,
  edit,
  handleOnSuccess,
  toggleDrawer,
}) => {
  const classes = formStyles();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [formValues, setFormValues] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [originalPermissions, setOriginalPermissions] = useState(
    initialValues?.permissions
  );
  const { data } = useQuery(GET_ALL_ROLES, {
    variables: {
      first: 10,
      orderBy: "createdAt_DESC",
      filters: {
        isActiveBool: "true",
      },
    },
    fetchPolicy: "network-only",
  });
  const [createAdmin] = useMutation(CREATE_ADMIN);
  const [editAdmin] = useMutation(EDIT_ADMIN);

  const handleConfirmationClose = () => {
    setOpenConfirmationModal(false);
  };

  const buttonOneClick = () => {
    setOpenConfirmationModal(false);
    setLoading(false);
  };

  const buttonTwoClick = async () => {
    try {
      setOpenConfirmationModal(false);
      setLoading(true);
      const { fullName, email, mobile, countryCode, roleId, id, permissions } =
        formValues;
      const response = await editAdmin({
        variables: {
          where: {
            id,
          },
          input: {
            fullName,
            email,
            mobile,
            countryCode,
            roleId,
            permissions,
          },
        },
      });

      const { status, message } = response.data.updateAdmin;

      // Error
      if (status === "error") {
        setErrorMsg(message);
      } else {
        // Admin updated Successfully
        handleOnSuccess();
        toggleDrawer("right", false)();
      }
      setLoading(false);
    } catch (err) {
      setErrorMsg("Something went wrong !");
      setLoading(false);
    }
  };

  const handleCreateForm = async (values) => {
    try {
      setErrorMsg("");

      // Edit Admin
      if (edit) {
        setOpenConfirmationModal(true);
        setFormValues(values);
      } else {
        setLoading(true);

        // Create admin api call
        const { permissions, ...otherValues } = values;
        const response = await createAdmin({
          variables: {
            input: otherValues,
          },
        });

        // Api response
        const { status, message } = response.data.createAdmin;

        // Error
        if (status === "error") {
          setErrorMsg(message);
        } else {
          // Admin created Successfully
          handleOnSuccess();
          toggleDrawer("right", false)();
        }
        setLoading(false);
      }
    } catch (err) {
      setErrorMsg("Something went wrong !");
      setLoading(false);
    }
  };

  const handleRoleChange = (e, setFieldValue) => {
    // Get the selected role ID
    const selectedRoleId = e.target.value;
    setFieldValue("roleId", selectedRoleId); // Update roleId in Formik

    // Find the selected role from data
    const selectedRole = data.roles.edges.find(
      (role) => role.node.id === selectedRoleId
    );

    if (selectedRole) {
      // Update permissions field in Formik with the selected role's permissions
      setFieldValue("permissions", selectedRole.node.permissions);
      setOriginalPermissions(selectedRole.node.permissions); // Optionally store this in state
    }
  };

  const countryOptions = CountryList.getAll().map((country) => ({
    value: country.dial_code,
    label: ` ${country.dial_code} (${country.code})`,
  }));

  const initialCountryCode =
    countryOptions.find((country) => country.value === "+1")?.value || "+1";

  return (
    <Container maxWidth={false} disableGutters className={classes.app}>
      <Grid container className={classes.formBox}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleCreateForm(values);
          }}
          render={({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            errors,
            setFieldValue,
          }) => {
            return (
              <form
                noValidate
                autoComplete="off"
                id="adminForm"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                <TextField
                  id="name"
                  name="fullName"
                  placeholder="Admin Name"
                  variant="outlined"
                  className={classes.width100}
                  value={values.fullName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.fullName && Boolean(errors.fullName)}
                  helperText={touched.fullName && errors.fullName}
                  InputProps={{
                    classes: {
                      input: classes.placeHolder,
                    },
                    className: classes.inputHeight,
                  }}
                />
                <Box
                  className={classes.dFlex}
                  style={{ justifyContent: "center", alignItems: "center" }}
                >
                  <Box>
                    <FormControl
                      style={{ zIndex: 100 }}
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <ReactSelect
                        id="countryCode"
                        name="countryCode"
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "125px",
                            marginRight: "3px",
                            backgroundColor: "#fff !important",
                            zIndex: 100,
                            borderRadius: "2px",
                            height: "41px",
                            marginTop: "30px",
                          }),
                        }}
                        error={
                          touched.countryCode && Boolean(errors.countryCode)
                        }
                        helperText={touched.countryCode && errors.countryCode}
                        value={countryOptions.find(
                          (option) => option.value === values.countryCode
                        )}
                        onChange={(option) =>
                          setFieldValue("countryCode", option.value)
                        }
                        options={countryOptions}
                        classNamePrefix="country-select"
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {touched.countryCode && errors.countryCode}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <TextField
                    id="mobile"
                    name="mobile"
                    placeholder="Admin Mobile Number"
                    variant="outlined"
                    className={classes.width100}
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.mobile && Boolean(errors.mobile)}
                    helperText={touched.mobile && errors.mobile}
                    InputProps={{
                      classes: {
                        input: classes.placeHolder,
                      },
                      className: classes.inputHeight1,
                    }}
                  />
                </Box>
                <TextField
                  id="email"
                  name="email"
                  placeholder="Admin Email"
                  variant="outlined"
                  className={classes.width100}
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    classes: {
                      input: classes.placeHolder,
                    },
                    className: classes.inputHeight2,
                  }}
                />
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.height35}`}
                  size="small"
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    className={classes.selectLabel}
                  >
                    Select Role
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    className={classes.inputHeight}
                    helperText={touched.roleId && errors.roleId}
                    label="Select Role"
                    name="roleId"
                    value={values.roleId}
                    onChange={(e) => handleRoleChange(e, setFieldValue)}
                    onBlur={handleBlur}
                    error={touched.roleId && Boolean(errors.roleId)}
                  >
                    {data &&
                      data.roles.edges.map((ev) => (
                        <MenuItem value={ev.node.id}>{ev.node.name}</MenuItem>
                      ))}
                  </Select>
                  <FormHelperText style={{ color: "red", marginBottom: "5px" }}>
                    {touched.roleId && errors.roleId}
                  </FormHelperText>
                </FormControl>
                <Typography className={classes.permissonTitle}>
                  Admin Permissions
                </Typography>
                {Object.entries(values.permissions).map(([key1, value]) => [
                  value ? (
                    <Switch
                      key={key1 + value.label}
                      value={value}
                      permissionLabel={labels[key1]}
                      permissionName={key1}
                      originalPermissions={originalPermissions || {}}
                      componentName="Admin"
                      formik={handleChange}
                      fromAdmin={true}
                    />
                  ) : null,
                ])}
                {errorMsg.length > 0 && (
                  <Typography variant="subtitle1" className={classes.errorText}>
                    {errorMsg}
                  </Typography>
                )}
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                  className={classes.submitBtn}
                >
                  {loading && <CircularProgress size={20} />}
                  {!loading && "Submit"}
                </Button>
              </form>
            );
          }}
        />
        <ConfirmationModal
          handleClose={handleConfirmationClose}
          open={openConfirmationModal}
          heading="Edit Admin"
          subtitle="Are you sure you want to edit admin?"
          button1="Cancel"
          button2="Edit"
          onButton1Click={buttonOneClick}
          onButton2Click={buttonTwoClick}
        />
      </Grid>
    </Container>
  );
};

export default CreateAndEditAdmin;
