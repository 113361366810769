import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useStyles } from "./securityStyles";
import { SwitchStyles1 } from "../Administrator/administratorStyles";
import QrCodeGenerator from "qrcode";
import { ADMIN_PROFILE } from "graphql/query/admin";
import { useMutation, useQuery } from "@apollo/client";
import { TWO_FA_VERIFY } from "graphql/mutation/admin";
import { toast } from "react-toastify";

const SecurityManagement = () => {
  const classes = useStyles();
  const [isEnabled, setIsEnabled] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [qrCodeUrl, setQrCodeUrl] = useState(""); // To store the QR code URL from the API
  const [secretKey, setSecretKey] = useState("");
  const [loadingQRCode, setLoadingQRCode] = useState(false); // Loading state for the QR code
  const [isLoading, setIsLoading] = useState(false); // Global loading state

  const {
    data,
    loading: queryLoading,
    error,
    refetch,
  } = useQuery(ADMIN_PROFILE, {
    fetchPolicy: "network-only",
  });

  const email = data?.me?.email;
  const isTwoFaEnabled = data?.me?.isTwoFaEnabled;
  const isQrGeneratedProfile = data?.me?.isQrGenerated;
  const secretKeyProfile = data?.me?.secret;
  const [twoFaVerify] = useMutation(TWO_FA_VERIFY);
  // useEffect to check if 2FA is enabled on initial load

  useEffect(() => {
    if (isTwoFaEnabled) {
      setIsEnabled(true);
    }
  }, [isTwoFaEnabled]);

  useEffect(() => {
    setIsLoading(queryLoading); // Set global loading to true when query is loading
  }, [queryLoading]);

  // Function to handle enabling 2FA and fetching QR code
  const handleEnableClick = () => {
    if (!email) return;
    // API call to enable 2FA (with empty code initially)
    setIsLoading(true);
    setLoadingQRCode(true); // Set loading to true before the QR code fetch
    twoFaVerify({
      variables: {
        input: {
          email: email,
          grantType: "qrGenerated", // This grantType indicates QR code generation
        },
        refetch: true, // Refetch the profile to update the state
      },
    })
      .then(async (response) => {
        const qrCodeResponse = response.data?.twoFaVerify?.qrCode; // Adjust this based on actual response
        const finalQR = await QrCodeGenerator.toDataURL(qrCodeResponse);
        if (finalQR) {
          setQrCodeUrl(finalQR); // Set the QR code URL to state
        }
        setSecretKey(response.data?.twoFaVerify?.secret); // Store the secret key in state
        setVerificationCode(""); // Clear the code input
        setLoadingQRCode(false); // Set loading to false after QR code is ready
        setIsLoading(false);
        toast.success(response.data.twoFaVerify?.message || "QR generated");
      })
      .catch((error) => {
        console.error("Error enabling 2FA", error);
        setLoadingQRCode(false); // Set loading to false in case of error
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  // Function to handle disabling 2FA
  const handleDisableClick = () => {
    if (!email) return;

    // API call to disable 2FA
    setIsLoading(true);
    twoFaVerify({
      variables: {
        input: {
          email: email,
          grantType: "disable2FA", // This grantType indicates 2FA disabling
        },
        refetch: true, // Refetch the profile to update the state
      },
    })
      .then((response) => {
        // Reset the QR code URL and state since 2FA is disabled
        setQrCodeUrl(""); // Clear QR code as 2FA is disabled
        setVerificationCode(""); // Clear the code input
        setSecretKey(""); // Clear the secret key
        setIsEnabled(false); // Disable the switch as well
        setIsLoading(false);
        toast.success(
          data?.twoFaVerify?.message || "2FA Disabled Successfully"
        );
        refetch();
      })
      .catch((error) => {
        console.error("Error disabling 2FA", error);
        setIsLoading(false);
        toast.error(data?.message || "2FA Disabled Failed");
      });
  };

  // Function to handle verification of the code
  const handleVerifyClick = () => {
    if (!email || !verificationCode) return;

    // API call to verify 2FA code entered by the admin
    setIsLoading(true);
    twoFaVerify({
      variables: {
        input: {
          email: email,
          grantType: "qrGenerated",
          code: verificationCode, // Code input by the admin
        },
        refetch: true, // Refetch the profile to update the state
      },
    })
      .then((response) => {
        setQrCodeUrl(""); // Clear QR code as 2FA is verified
        setIsEnabled(true); // Enable the switch as well
        setVerificationCode(""); // Clear the code input
        setIsLoading(false);
        toast.success(response.data?.twoFaVerify?.message); // Show success toast
        refetch();
        // Handle successful verification (e.g., show success message)
      })
      .catch((error) => {
        console.error("Error verifying 2FA", error);
        setIsLoading(false);
        toast.error(error.message);
      });
  };

  return (
    <>
      {/* Global Loader */}
      {isLoading && (
        <Box
          className={classes.loaderWrapper}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            background: "rgba(0, 0, 0, 0.5)",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={60} />
        </Box>
      )}

      {!isLoading && (
        <Box>
          <Box className={classes.paddingAll}>
            <Box className={classes.dFlex}>
              <Typography variant="h5" className={classes.header1}>
                Two-Factor Authentication
              </Typography>
            </Box>
            <Box className={classes.dFlex}>
              <Box className={classes.twofa}>
                <SwitchStyles1
                  name="isEnabled"
                  checked={isEnabled}
                  onChange={() => {
                    if (!isEnabled) {
                      handleEnableClick(); // Call handleEnableClick when enabling
                    } else {
                      handleDisableClick(); // Call handleDisableClick when disabling
                    }
                    setIsEnabled((prev) => !prev); // Toggle the state of the switch
                  }}
                />
                <label className={classes.label}>Enable 2FA</label>
              </Box>
              {isEnabled && !isTwoFaEnabled && (
                <Button
                  className={`${classes.button}`}
                  variant="contained"
                  style={
                    !verificationCode
                      ? { cursor: "not-allowed", opacity: 0.7, color: "white" }
                      : {}
                  }
                  color="primary"
                  disabled={!verificationCode}
                  onClick={handleVerifyClick}
                >
                  Verify
                </Button>
              )}
            </Box>
            <Typography variant="h6" className={classes.header6}>
              Two-Factor Authentication (2FA) is an additional measure to
              protect your account. In addition to your password you will be
              asked for a second proof on login. This can be provided by an app
              (such as Google).
            </Typography>

            {isTwoFaEnabled && (
              <Box className="mt-4">
                <Typography variant="body1">
                  2FA setup was successfully enabled.
                </Typography>
                <Typography variant="body1" className={classes.my}>
                  Here is the secret key: {secretKeyProfile}
                </Typography>
              </Box>
            )}

            {isEnabled && !isTwoFaEnabled && (
              <Box className="mt-4">
                <Typography variant="h6" className={classes.header6}>
                  To use an authenticator app, follow these steps:
                </Typography>
                <ol>
                  <li className={classes.marginUp}>
                    <Typography variant="body1">
                      Download a two-factor authenticator app{" "}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://google.com/landing/2step/"
                      >
                        Google Authenticator
                      </a>{" "}
                      for Android or iOS
                    </Typography>
                  </li>
                  <li className={classes.marginUp}>
                    <Typography variant="body1">
                      Scan the QR Code or enter the following key into your app:
                    </Typography>
                    <Box className={classes.qrSection}>
                      {/* Show loading state until QR code is available */}
                      {loadingQRCode ? (
                        <Box className={classes.loading}>
                          <CircularProgress size={24} />
                          <Typography variant="body1">Loading...</Typography>
                        </Box>
                      ) : qrCodeUrl ? (
                        <img
                          src={qrCodeUrl} // This will be the URL or base64 of the QR code
                          alt="QR Code"
                          className={classes.qrCode}
                        />
                      ) : (
                        <Typography variant="body1">
                          QR Code will appear after enabling 2FA.
                        </Typography>
                      )}
                      <Typography variant="body1" className={classes.my}>
                        OR
                      </Typography>
                      <Typography variant="body1">
                        Enter the secret key: {secretKey}
                      </Typography>
                      {/* <TextField
                      value={secretKey}
                      variant="outlined"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    /> */}
                    </Box>
                  </li>
                  <li className={classes.marginUp}>
                    <Typography variant="body1">
                      Your authenticator app will provide a unique code. Enter
                      the code below:
                    </Typography>
                    <TextField
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                      variant="outlined"
                      label="Verification Code"
                      fullWidth
                    />
                  </li>
                </ol>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default SecurityManagement;
