import React, { useState } from "react";
import { Formik, Form, Field } from "formik";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import { CREATE_ROOM } from "graphql/mutation/admin";
import { toast } from "react-toastify";
import { useStyles } from "./ModalStyles";

const RoomForm = ({
  setActionPerformed,
  handleClose,
  modalData,
  data,
  mode,
}) => {
  const [loading, setLoading] = useState(false);
  // const [errorMsg, setErrorMsg] = useState("");

  const classes = useStyles();

  // Validation schema
  const validationSchema = Yup.object({
    _game: Yup.string().required("Game is required"),
    // playersLimit: Yup.number().required("Players limit is required"),
    // entryRequirements: Yup.number().required("Entry requirements is required"),
  });

  const [createRoom] = useMutation(CREATE_ROOM);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const res = await createRoom({
        variables: {
          input: {
            _game: values._game,
          },
        },
      });
      if (res?.data?.createRoom?.status === "success") {
        setActionPerformed(true);
        handleClose();
        toast.success(res?.data?.createRoom?.message);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error creating room:", error);
      toast.error("Failed to create room");
    } finally {
      setLoading(false);
    }
  };

  const filteredGames = data?.getAllGames?.edges?.filter(
    (game) => game.node.name !== "Slots"
  );

  return (
    <>
      <div className={classes.formContainer}>
        <Formik
          initialValues={{
            _game: data?._game || "",
            // playersLimit: modalData?.playersLimit || "",
            // entryRequirements: modalData?.entryRequirements || "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched, isValid }) => (
            <Form>
              {/* Select Game Field */}
              <div className={classes.fieldContainer}>
                <label htmlFor="_game" className={classes.inputLabel}>
                  Select Game
                </label>
                {/* dropdown */}
                <Field
                  as="select"
                  id="_game"
                  name="_game"
                  className={classes.inputHeight101}
                  value={values._game}
                  onChange={(e) => {
                    setFieldValue("_game", e.target.value);
                  }}
                >
                  <option value="">Select Game</option>
                  {filteredGames?.map((game) => (
                    <option key={game?.node?.name} value={game?.node?.id}>
                      {game?.node?.name}
                    </option>
                  ))}
                </Field>

                {errors._game && touched._game && (
                  <Typography variant="body2" className={classes.errorText100}>
                    {errors._game}
                  </Typography>
                )}
              </div>

              {/* Players Limit Field */}
              {/* <div className={classes.fieldContainer}>
                <label htmlFor="playersLimit" className={classes.inputLabel}>
                  Players Limit
                </label>
                <Field
                  inputProps={{
                    classes: { input: classes.placeholder },
                    className: classes.inputHeight101,
                  }}
                  type="number"
                  name="playersLimit"
                  className={classes.inputHeight102}
                  placeholder="Enter players limit"
                />
                {errors.playersLimit && touched.playersLimit && (
                  <Typography variant="body2" className={classes.errorText100}>
                    {errors.playersLimit}
                  </Typography>
                )}
              </div> */}

              {/* Entry Requirements Field */}
              {/* <div className={classes.fieldContainer}>
                <label
                  htmlFor="entryRequirements"
                  className={classes.inputLabel}
                >
                  Entry Requirements
                </label>
                <Field
                  inputProps={{
                    classes: { input: classes.placeholder },
                  }}
                  type="number"
                  name="entryRequirements"
                  className={classes.inputHeight102}
                  placeholder="Enter entry requirements"
                />
                {errors.entryRequirements && touched.entryRequirements && (
                  <Typography variant="body2" className={classes.errorText100}>
                    {errors.entryRequirements}
                  </Typography>
                )}
              </div> */}

              {/* {errorMsg && (
                <Typography
                  variant="subtitle1"
                  className={classes.errorText100}
                >
                  {errorMsg}
                </Typography>
              )} */}

              {/* Submit Button */}
              <div className={classes.buttonContainer}>
                <Button
                  type="submit"
                  variant="contained"
                  className={classes.sendButton2}
                  disabled={loading || !isValid}
                >
                  {loading ? (
                    <CircularProgress
                      size={20}
                      style={{ color: "white !important" }}
                    />
                  ) : (
                    "Update Room"
                  )}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default RoomForm;
