import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import DashboardLayout from "Layouts/Admin/DashboardLayout";
import Administrator from "Modules/Admin/Administrator";
import AdminDetails from "Modules/Admin/Administrator/Components/AdminDetails/AdminDetails";
import ViewRole from "Modules/Admin/Administrator/Components/ViewRole/ViewRole";
import Dashboard from "Modules/Admin/Dashboard";
import Login from "Modules/Auth/Login";
import OTP from "Modules/Auth/Login/OTP";
import GoogleAuth from "Modules/Auth/Login/GoogleAuth";
import VerifySecurityCode from "Modules/Auth/Login/VerifySecurityCode";
// import Category from 'Modules/Admin/Category';
import Collectible from "Modules/Admin/Collectible";
import UnderDevelopment from "Modules/Admin/UnderDevelopment";
import ViewCollectible from "Modules/Admin/Collectible/Components/ViewMarketplace/ViewMarketplace";
import ResetPassword from "Modules/Auth/ResetPassword";
import Analytics from "Modules/Admin/Analytics";
import Cms from "Modules/Admin/Cms";
import Profile from "Modules/Admin/Profile";
import Transaction from "Modules/Admin/Transaction";
import User from "Modules/Admin/User";
import ViewUser from "Modules/Admin/User/ViewUser/ViewUser";
import Platform from "Modules/Admin/Platform";
import ContractManagement from "Modules/Admin/ContractManagement";
import ImportCollection from "Modules/Admin/ImportCollection";
import ViewCollectionRequest from "Modules/Admin/ImportCollection/Components/ViewCollectionRequest/ViewCollection";
import Game from "Modules/Admin/Game";
import SecurityManagement from "Modules/Admin/SecurityManagement";
import AnnouncementManagement from "Modules/Admin/AnnouncementManagement";
import { useQuery } from "@apollo/client";
import { ADMIN_PROFILE } from "graphql/query/admin";
import FundsManagement from "Modules/Admin/FundsManagement";
import StakingManagement from "Modules/Admin/StakingManagement";
// ----------------------------------------------------------------------

export default function Router() {
  const token = localStorage.getItem("auth-token");
  const isAdmin = !!token;

  const { data, loading, error, refetch } = useQuery(ADMIN_PROFILE, {
    fetchPolicy: "network-only",
  });

  const isSuperAdmin = data?.me?.role?.name === "SUPER_ADMIN";

  // Check user permissions to allow or deny access to specific routes
  const canViewUser = data?.me?.permissions?.User?.GET ?? false;
  const canViewAnnouncement = data?.me?.permissions?.Announcement?.GET ?? false;
  const canViewGame = data?.me?.permissions?.Game?.GET ?? false;
  const canViewRoom = data?.me?.permissions?.Room?.GET ?? false;

  return useRoutes([
    {
      path: "/admin",
      element: token ? <DashboardLayout /> : <Navigate to="/login" replace />,
      children: [
        // { path: "", element: <Navigate to="/admin/dashboard" replace /> },
        { path: "", element: <Dashboard replace /> },
        { path: "dashboard", element: <Dashboard /> },
        isSuperAdmin && { path: "administrator", element: <Administrator /> },
        isSuperAdmin && {
          path: "administrator/details",
          element: <AdminDetails />,
        },
        isSuperAdmin && {
          path: "administrator/view-role",
          element: <ViewRole />,
        },
        isSuperAdmin && {
          path: "funds-management",
          element: <FundsManagement />,
        },
        isSuperAdmin && {
          path: "staking-management",
          element: <StakingManagement />,
        },
        // { path: 'category', element: <Category /> },
        // { path: "transaction", element: <Transaction /> },
        // { path: "collectible", element: <Collectible /> },
        // { path: "collectible/viewCollectible", element: <ViewCollectible /> },
        // { path: "analytics-and-reports", element: <Analytics /> },
        canViewUser && { path: "user", element: <User /> },
        canViewUser && { path: "user/view-user", element: <ViewUser /> },
        // { path: "platform", element: <Platform /> },
        // { path: "cms", element: <Cms /> },
        // { path: "contract-management", element: <ContractManagement /> },
        // { path: "import-collection", element: <ImportCollection /> },
        // { path: "import-collection/view", element: <ViewCollectionRequest /> },
        (canViewGame || canViewRoom) && {
          path: "game-management",
          element: <Game />,
        },
        { path: "security-management", element: <SecurityManagement /> },
        canViewAnnouncement && {
          path: "announcement-management",
          element: <AnnouncementManagement />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
        { path: "*", element: <UnderDevelopment /> },
      ],
    },
    {
      path: "/login",
      element:
        !token && !isSuperAdmin ? <Login /> : <Navigate to="/admin" replace />,
    },
    {
      path: "/otp",
      element:
        !token && !isSuperAdmin ? <OTP /> : <Navigate to="/admin" replace />,
    },
    {
      path: "/google-auth",
      element:
        !token && !isSuperAdmin ? (
          <GoogleAuth />
        ) : (
          <Navigate to="/admin" replace />
        ),
    },
    {
      path: "/verify-security-code",
      element:
        !token && !isSuperAdmin ? (
          <VerifySecurityCode />
        ) : (
          <Navigate to="/admin" replace />
        ),
    },
    {
      path: "/reset-password",
      element:
        !token && !isSuperAdmin ? (
          <ResetPassword />
        ) : (
          <Navigate to="/admin" replace />
        ),
    },
    { path: "/", element: <Navigate to="/admin/dashboard" replace /> },
  ]);
}
