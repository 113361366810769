/* eslint-disable react/prop-types */
import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

import { ReactComponent as CloseIcon } from "Assets/Svg/close.svg";
import { useStyles } from "./ModalStyles";
import "./styles.scss";
import AnnouncementForm from "./AnnouncementForm";

const AnnouncementModal = ({
  open,
  handleClose,
  editId,
  heading,
  variables,
  setActionPerformed,
  mode,
  modalData,
}) => {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        classes={{ paper: classes.dialogPaper2 }}
      >
        <DialogContent classes={{ root: classes.dialogContext1 }}>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={() => {
              handleClose();
            }}
            onClick={() => {
              handleClose();
            }}
            className={classes.topBar2}
          >
            <span className={classes.title1}>{heading}</span>
            <span className={`${classes.onHover} ${classes.closeBtn1}`}>
              <CloseIcon />
            </span>
          </div>
          <AnnouncementForm
            editId={editId}
            handleClose={handleClose}
            setActionPerformed={setActionPerformed}
            variables={variables}
            mode={mode}
            modalData={modalData}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default AnnouncementModal;
