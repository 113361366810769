import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import * as Yup from "yup";
import { useMutation } from "@apollo/client";
import {
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  UPLOAD_IMAGE_MUTATION,
} from "graphql/mutation/admin";
import uploadOnS3 from "utils";
import { toast } from "react-toastify";
import { useStyles } from "./ModalStyles";

const AnnouncementForm = ({
  editId,
  setActionPerformed,
  handleClose,
  modalData,
  mode, // Mode can be 'create', 'edit', or 'view'
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  // GraphQL mutations
  const [createAnnouncement] = useMutation(CREATE_ANNOUNCEMENT);
  const [updateAnnouncement] = useMutation(UPDATE_ANNOUNCEMENT);
  const [uploadImage] = useMutation(UPLOAD_IMAGE_MUTATION);

  const classes = useStyles();

  // Validation schema
  const validationSchema = Yup.object({
    title: Yup.string()
      .max(100, "Title cannot be longer than 100 characters")
      .required("Title is required"),

    description: Yup.string()
      .max(500, "Description cannot be longer than 500 characters")
      .required("Description is required"),

    scheduleAt: Yup.date()
      .min(new Date(), "Schedule date must be in the future")
      .required("Schedule date is required"),

    image:
      mode !== "view" && !modalData?.imageUrl
        ? Yup.mixed().required("Image is required")
        : Yup.mixed().notRequired(),
  });

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      let uploadedImageUrl = imageUrl; // Default to current imageUrl if no upload happens

      // If there's a new image to upload, process it
      if (values.image && values.image !== modalData?.imageUrl) {
        const file = values.image;
        try {
          const uploadImageData = await uploadImage({
            variables: {
              input: {
                extension: file.name.split(".").pop(),
                name: file.name,
              },
            },
          });

          const preSignedUrl =
            uploadImageData?.data?.uploadImageMutation?.imageUrl?.preSignedUrl;
          const fullPath =
            uploadImageData?.data?.uploadImageMutation?.imageUrl?.fullPath;
          if (!preSignedUrl) throw new Error("Failed to get pre-signed URL.");

          const imageBuffer = await file.arrayBuffer();
          const s3UploadResponse = await uploadOnS3(preSignedUrl, imageBuffer);

          if (s3UploadResponse) {
            uploadedImageUrl = fullPath;
            setImageUrl(uploadedImageUrl);
          } else {
            throw new Error("Error uploading image to S3.");
          }
        } catch (error) {
          setErrorMsg("Error uploading image.");
          setLoading(false);
          return;
        }
      }

      // After image upload (or if no image is provided), create or update the announcement
      let response;
      if (editId) {
        response = await updateAnnouncement({
          variables: {
            input: {
              title: values.title,
              content: values.description,
              scheduledAt: new Date(values.scheduleAt).toISOString(),
              imageUrl: uploadedImageUrl,
            },
            updateAnnouncementId: editId,
          },
        });
      } else {
        response = await createAnnouncement({
          variables: {
            input: {
              title: values.title,
              content: values.description,
              scheduledAt: new Date(values.scheduleAt).toISOString(),
              imageUrl: uploadedImageUrl,
            },
          },
        });
      }

      const { status, message } =
        response.data[
          mode === "edit" ? "updateAnnouncement" : "createAnnouncement"
        ];

      if (status === "success") {
        setActionPerformed(true);
        setErrorMsg("");
        toast.success(message);
        handleClose();
      } else {
        setErrorMsg(message || "Something went wrong!");
      }

      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      setErrorMsg("Something went wrong!");
    }
  };

  useEffect(() => {
    if (modalData?.imageUrl) {
      setImageUrl(modalData.imageUrl);
    }
  }, [modalData]);

  return (
    <>
      <div className={classes.formContainer}>
        <Formik
          initialValues={{
            title: modalData?.title || "",
            description: modalData?.content || "",
            scheduleAt: modalData?.scheduledAt
              ? new Date(modalData.scheduledAt).toISOString().slice(0, 16)
              : "", // This will slice the string to match datetime-local input format
            image: modalData?.imageUrl || null,
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched, isValid }) => (
            <Form>
              {/* Title Field */}
              <div className={classes.fieldContainer}>
                <Typography variant="body2" className={classes.inputLabel}>
                  Title
                </Typography>
                <Field
                  inputProps={{
                    classes: { input: classes.placeholder },
                    className: classes.inputHeight101,
                  }}
                  type="text"
                  name="title"
                  className={classes.inputHeight102}
                  placeholder="Enter title"
                  disabled={mode === "view"}
                />
                {errors.title && touched.title && (
                  <Typography variant="body2" className={classes.errorText100}>
                    {errors.title}
                  </Typography>
                )}
              </div>

              {/* Description Field */}
              <div className={classes.fieldContainer}>
                <label htmlFor="description" className={classes.inputLabel}>
                  Content
                </label>
                <Field
                  inputProps={{
                    classes: { input: classes.placeholder },
                  }}
                  component="textarea"
                  name="description"
                  rows={4}
                  className={classes.inputHeight103}
                  placeholder="Enter description"
                  disabled={mode === "view"}
                />
                {errors.description && touched.description && (
                  <Typography variant="body2" className={classes.errorText100}>
                    {errors.description}
                  </Typography>
                )}
              </div>

              {/* Schedule Date/Time Field */}
              <div className={classes.fieldContainer}>
                <label htmlFor="scheduleAt" className={classes.inputLabel}>
                  Schedule Date/Time
                </label>
                <Field
                  inputProps={{
                    classes: { input: classes.placeholder },
                    className: classes.inputHeight101,
                  }}
                  type="datetime-local"
                  name="scheduleAt"
                  className={classes.inputHeight102}
                  disabled={mode === "view"}
                />
                {errors.scheduleAt && touched.scheduleAt && (
                  <Typography variant="body2" className={classes.errorText100}>
                    {errors.scheduleAt}
                  </Typography>
                )}
              </div>

              {/* Image Field */}
              {mode !== "view" && (
                <div className={classes.fieldContainer}>
                  <label htmlFor="image" className={classes.inputLabel}>
                    Upload Image
                  </label>
                  <input
                    id="image"
                    name="image"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      setFieldValue("image", event.currentTarget.files[0]);
                      setErrorMsg("");
                      setImageUrl(
                        URL.createObjectURL(event.currentTarget.files[0])
                      );
                    }}
                    className={classes.inputHeight102}
                    disabled={mode === "view"}
                  />
                  {errors.image && touched.image && (
                    <Typography
                      variant="body2"
                      className={classes.errorText100}
                    >
                      {errors.image}
                    </Typography>
                  )}
                </div>
              )}

              {/* Display image if available */}
              {(values.image || imageUrl) && (
                <div className={classes.imageContainer}>
                  <img
                    src={
                      imageUrl ||
                      (values.image instanceof File
                        ? URL.createObjectURL(values.image)
                        : "")
                    }
                    alt="Selected"
                    className={classes.image}
                    style={{ maxWidth: "200px", maxHeight: "200px" }}
                  />
                </div>
              )}

              {errorMsg && (
                <Typography
                  variant="subtitle1"
                  className={classes.errorText100}
                >
                  {errorMsg}
                </Typography>
              )}

              {/* Submit Button */}
              <div className={classes.buttonContainer}>
                {mode !== "view" && (
                  <Button
                    type="submit"
                    variant="contained"
                    className={classes.sendButton2}
                    disabled={loading || errorMsg || !isValid}
                  >
                    {loading ? (
                      <CircularProgress size={20} style={{ color: "white" }} />
                    ) : mode === "edit" ? (
                      "Update Announcement"
                    ) : (
                      "Create Announcement"
                    )}
                  </Button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AnnouncementForm;
