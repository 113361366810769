import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography,
} from '@material-ui/core';

import Filter from 'Assets/Svg/filter';
import ArrowDownIcon from 'Assets/Svg/arrowdown';
import ArrowDown from 'Components/DropDownIcon/ArrowDown';
import { ReactComponent as CloseIcon } from 'Assets/Svg/closeMedium.svg';
import { useStyles } from './FilterStyles';

// eslint-disable-next-line react/prop-types
export default function SimplePopover() {
  const classes = useStyles();
  const [selectedDate, handleDateChange] = React.useState(new Date());
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({
    category: '',
    subcategory: '',
  });
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    const { name } = event.target;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        startIcon={<Filter />}
        endIcon={<ArrowDownIcon />}
        className={classes.filterButton}
      >
        <Typography className={classes.filterText}>Filter</Typography>
      </Button>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={classes.popUp}
          style={{ marginTop: '7px' }}
          marginThreshold={20}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          anchorPosition={{ left: 20, top: 0 }}
        >
          <Box className={classes.popUp}>
            <div className={classes.topBar}>
              <span
                role="button"
                tabIndex={0}
                onKeyDown={handleClose}
                onClick={handleClose}
                className={`${classes.onHover} ${classes.closeBtn}`}
              >
                <CloseIcon />

              </span>
            </div>
            <Grid container style={{ width: '100%', marginTop: '30px' }}>
              <Grid item style={{ widht: '50%' }}>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <InputLabel id="demo-simple-select-outlined-label" className={classes.inputLabel}>Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    className={classes.selectTag}
                    IconComponent={ArrowDown}
                    id="demo-simple-select-outlined"
                    value={state.category}
                    onChange={handleChange}
                    name="category"
                    label="Category"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ widht: '50%' }}>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <InputLabel id="demo-simple-select-outlined-label" className={classes.inputLabel}>Price Range</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    className={classes.selectTag}
                    IconComponent={ArrowDown}
                    id="demo-simple-select-outlined"
                    value={state.subcategory}
                    onChange={handleChange}
                    label="Sub-Category"
                    name="subcategory"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ width: '100%', marginTop: '-20px' }}>
              <Grid item style={{ widht: '50%' }}>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <InputLabel id="demo-simple-select-outlined-label" className={classes.inputLabel}>From</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    className={classes.selectTag}
                    IconComponent={ArrowDown}
                    id="demo-simple-select-outlined"
                    value={state.category}
                    onChange={handleChange}
                    name="category"
                    label="Category"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item style={{ widht: '50%' }}>
                <FormControl variant="outlined" className={classes.formControl} size="small">
                  <InputLabel id="demo-simple-select-outlined-label" className={classes.inputLabel}>To</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    className={classes.selectTag}
                    IconComponent={ArrowDown}
                    id="demo-simple-select-outlined"
                    value={state.subcategory}
                    onChange={handleChange}
                    label="Sub-Category"
                    name="subcategory"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container style={{ width: '101%', marginTop: '-17px' }}>
              <Grid item className={classes.width100}>
                <FormControl variant="outlined" className={`${classes.formControl} ${classes.width93}`} size="small">
                  <InputLabel id="demo-simple-select-outlined-label" className={classes.inputLabel}>Creation Date</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    className={classes.selectTag}
                    IconComponent={ArrowDown}
                    id="demo-simple-select-outlined"
                    // value={age}
                    // onChange={handleChange}
                    label="Creation Date"
                  >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="date-pick1">
              <Grid item style={{ display: 'flex', marginBottom: '20px' }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label="From"
                    clearable
                    format="dd/MM/yyyy"
                    disableFuture
                    style={{
                      background: 'rgba(0, 0, 0, 0.02)',
                      border: '1px solid #CCCCCC',
                      borderRadius: '4px',
                      marginTop: '-10px',
                      marginLeft: '8px',
                      width: '46%',
                      paddingLeft: '5px',
                    }}
                    value={selectedDate}
                    views={['year', 'month', 'date']}
                    onChange={handleDateChange}
                  />
                  <DatePicker
                    autoOk
                    label="To"
                    clearable
                    format="dd/MM/yyyy"
                    disableFuture
                    style={{
                      background: 'rgba(0, 0, 0, 0.02)',
                      border: '1px solid #CCCCCC',
                      borderRadius: '4px',
                      marginTop: '-10px',
                      marginLeft: '8px',
                      paddingLeft: '5px',
                      width: '46%',
                    }}
                    value={selectedDate}
                    views={['year', 'month', 'date']}
                    onChange={handleDateChange}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid />
            </Grid>
            <Grid container>
              <Grid item style={{ widht: '50%' }}>
                <Button variant="contained" className={classes.buttonReset}>
                  Reset
                </Button>
              </Grid>
              <Grid item style={{ widht: '50%' }}>
                <Button variant="contained" className={classes.buttonApply} color="primary">
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </div>
    </div>
  );
}
