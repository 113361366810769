import { PublicKey } from "@solana/web3.js";

export const ADMIN_ADDRESS = new PublicKey(
  "J9VQ1xDqKBq52f18ne3SduwQBsTRZz24x1nGFjUA1Rwz"
);

export const BETTING_SYSTEM_PROGRAM_ID = new PublicKey(
  "HR9KmFYaVRFXvdY4iYMgvwaL9mqJU8bqs4SbJ7pAbXG8"
);
export const BETTING_STATE_ADDRESS = new PublicKey(
  "B2MSgnutq5eoa3f8sQQKX2cTP8Ejv9pokLp4qCcZhJrd"
);

export const UNKNOWNT_MINT = new PublicKey(
  "AnzBMKUKiCdcZmEsd14qD4Cfe2LkxgYHtWgVKhyLCiNz"
);

export const VAULT_ADDRESS = new PublicKey(
  "EpbbxHUgofU6mGNXvhqD9CVv6NLMEtu6cfxGtNY825tD"
);
