/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';

import { useNftMarketplaceContract } from 'hooks';
import Table from 'Components/Table/Table';
import { Loader } from 'Components/TableLoader';
import { useStyles } from '../../styles';

const ContractTable = ({
  actionPerformed,
  setActionPerformed,
}) => {
  const classes = useStyles();
  const handleViewButton = () => { };
  const handleEditButton = () => { };
  const handleDeleteButton = () => { };
  const [contracts, setContracts] = useState([]);
  const [noDataFound, setNoDataFound] = useState(false);
  const marketplaceContract = useNftMarketplaceContract();

  console.log(marketplaceContract);
  const loadData = async () => {
    const data = await marketplaceContract.methods.getWhitelistedContracts().call();
    if (data.length > 0) {
      const list = await data.map((val) => ({ contractAddress: val }));
      setContracts(list);
      setNoDataFound(false);
    } else {
      setNoDataFound(true);
    }
  };

  useEffect(() => {
    if (marketplaceContract) {
      loadData();
    }
    if (actionPerformed) {
      setContracts([]);
      loadData();
      setActionPerformed(false);
    }
  }, [marketplaceContract, actionPerformed]);

  const TABLE_HEAD = [
    {
      id: 'id', flag: 'id', label: 'Sr.No', alignRight: false,
    },
    {
      id: 'contractAddress', flag: 'contractAddress', label: 'Contract Address', alignRight: false,
    },
    {
      id: 'actions',
      flag: { read: false, edit: false, remove: false },
      callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: 'Actions',
      alignRight: false,
    },
  ];

  if (contracts.length === 0 && !noDataFound) {
    return <Loader />;
  }
  return (
    <Box className={classes.mainContainer}>
      { noDataFound
        ? <h2>No Data Found!</h2>
        : <Table USERLIST={contracts} TABLE_HEAD={TABLE_HEAD} /> }
    </Box>
  );
};

export default ContractTable;
