import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { tabStyles } from './styles';
import Table from './Components/Table/Table';

const ImportCollection = () => {
  const classes = tabStyles();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  return (
    <Box>
      <Box className={classes.paddingAll}>
        <Table
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          actionPerformed={actionPerformed}
          setActionPerformed={setActionPerformed}
        />
      </Box>
    </Box>
  );
};

export default ImportCollection;
