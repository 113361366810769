import React, { useMemo } from "react";
// import { useWeb3React } from '@web3-react/core';
// import { useEagerConnect, useInactiveListener } from 'hooks';
import Router from "Routes";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";

function App() {
  const network = "devnet"; // Set the Solana network
const endpoint = clusterApiUrl(network);

const wallets = [
  new SolflareWalletAdapter(), // Add Solflare Wallet Adapter
];
  // const [activatingConnector, setActivatingConnector] = useState();
  // const { connector, active } = useWeb3React();

  // const triedEager = useEagerConnect();
  // useEffect(() => {
  //   if (activatingConnector && activatingConnector === connector) {
  //     setActivatingConnector(undefined);
  //   }
  // }, [activatingConnector, connector]);

  // useInactiveListener(!triedEager || !!activatingConnector);

  // if (active) {
  //   localStorage.setItem('shouldEagerConnect', true);
  // }

  return (
    <>
      <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
          <Router />
        </WalletProvider>
      </ConnectionProvider>
    </>
  );
}

export default App;
