import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { statCardStyles } from "../dashboardStyles";
import { useLazyQuery } from "@apollo/client";
import { GET_GAME_DATA_DASHBOARD } from "graphql/query/admin";
import RouletteIcon from "Assets/Images/Roulette.png";
import SlotsIcon from "Assets/Images/Slots.png";
import BaccaratIcon from "Assets/Images/Baccarat.png";
import BlackjackIcon from "Assets/Images/Blackjack.png";
import PokerIcon from "Assets/Images/Poker.png";
import SportsbettingIcon from "Assets/Images/sportsBetting.jpg";

export default function GameTypeOverview() {
  const classes = statCardStyles();

  const [gameData, setGameData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state to show loading message until data is fetched

  useEffect(() => {
    getGameDataDashboard();
  }, []);

  const [getGameDataDashboard] = useLazyQuery(GET_GAME_DATA_DASHBOARD, {
    onCompleted: (data) => {
      if (data) {
        setGameData(data.gameDataDashboard.gameData); // Set the game data
      } else {
        console.error("Invalid data structure", data);
      }
      setLoading(false); // Data is loaded, set loading to false
    },
    onError: (error) => {
      console.error("Error fetching game data:", error); // Log error if query fails
      setLoading(false); // Stop loading even if there's an error
    },
  });

  // Function to determine background color based on the game type
  const getGameCardColor = (gameType) => {
    switch (gameType) {
      case "baccarat":
        return "#4bc0c080"; // Light blue
      case "blackjack":
        return "#F4FF77"; // Light yellow
      case "poker":
        return "#D7FFF2"; // Light green
      case "roulette":
        return "#FFDBDF"; // Light red
      case "slots":
        return "#D8FFB1"; // Light teal
      default:
        return "#FFDBDF"; // Default color
    }
  };

  // Function to determine game icon based on the game type
  const getGameIcon = (gameType) => {
    switch (gameType) {
      case "baccarat":
        return BaccaratIcon;
      case "blackjack":
        return BlackjackIcon;
      case "poker":
        return PokerIcon;
      case "roulette":
        return RouletteIcon;
      case "slots":
        return SlotsIcon;
      case "sportsBetting":
        return SportsbettingIcon;
      default:
        return BaccaratIcon; // Default icon
    }
  };

  if (loading) {
    return (
      <Grid container justify="center" alignItems="center">
        <Typography mar>Loading...</Typography>
      </Grid>
    );
  }

  return (
    <Grid
      container
      className={`${classes.root} ${classes.mainGrid}`}
      spacing={0}
    >
      <Grid item className={classes.marginTop}>
        <Typography className={classes.header}>Individual Games</Typography>
        {/* <Grid container item lg={12} spacing={2}> */}
        <div className={classes.flexBoxCards}>
          {gameData.length === 0 ? (
            <Typography>No game data available</Typography> // Show a message if no game data is available
          ) : (
            gameData.map((game) => (
              // <Grid
              //   className={classes.statscard}
              //   key={game.gameType}
              //   item
              //   xl={3}
              //   lg={3}
              //   md={6}
              //   xs={12}
              // >
              // <div className={classes.flexBoxCards}>
              // <Paper
              //   className={classes.paperGame}
              //   style={{
              //     backgroundColor: getGameCardColor(game.gameType), // Assign background color based on the game type
              //     borderRadius: "6px",
              //   }}
              // >
              //   <Grid item className={classes.iconBoxGame}>
              //     <Typography
              //       className={`${classes.icon} ${classes.backgroundTransparent}`}
              //     >
              //       <img
              //         alt={game.gameType}
              //         className={classes.gameIcons}
              //         src={getGameIcon(game.gameType)}
              //         width={40}
              //         height={40}
              //       />{" "}
              //       {/* Display the game-specific icon */}
              //     </Typography>
              //   </Grid>

              //   <Grid item>
              //     <Typography className={classes.titleBoxGame}>
              //       {game.gameType?.toUpperCase()}
              //     </Typography>
              //     <Typography className={classes.subTitleBox}>
              //       Total Bets: {game.totalBets}
              //     </Typography>
              //     <Typography className={classes.subTitleBox}>
              //       Win Count: {game.winCount}
              //     </Typography>
              //     <Typography className={classes.subTitleBox}>
              //       Loss Count: {game.lossCount}
              //     </Typography>
              //     <Typography className={classes.subTitleBox}>
              //       Win/Loss Ratio: {game.winLossRatio.toFixed(2)}
              //     </Typography>
              //     <Typography className={classes.subTitleBox}>
              //       Total Bet Amount: {game.totalBetAmount}
              //     </Typography>
              //     <Typography className={classes.subTitleBox}>
              //       Total Payout: {game.totalPayout}
              //     </Typography>
              //   </Grid>
              // </Paper>
              <Paper
                className={classes.paperGame}
                style={{
                  position: "relative", // Make the paper relative for the overlay to be positioned correctly
                  backgroundImage: `url(${getGameIcon(game.gameType)})`, // Use the game icon as background image
                  backgroundSize: "cover", // Cover the whole card with the image
                  backgroundPosition: "center", // Center the image
                  borderRadius: "6px",
                  overflow: "hidden", // Hide any overflowing content
                  zIndex: 2, // Ensure the overlay is above the background image
                }}
              >
                {/* Overlay div for darkening the background */}
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Black overlay with 50% opacity
                    zIndex: 4, // Place the overlay below the content but above the background image
                  }}
                />

                <Grid item className={classes.iconBoxGame}>
                  <Typography
                    className={`${classes.icon} ${classes.backgroundTransparent}`}
                  >
                    {/* Remove the img tag, as the background image now serves this purpose */}
                  </Typography>
                </Grid>

                <Grid item>
                  <Typography className={classes.titleBoxGame}>
                    {game.gameType?.toUpperCase()}
                  </Typography>
                  <Typography className={classes.subTitleBox}>
                    Total Bets: {game.totalBets}
                  </Typography>
                  <Typography className={classes.subTitleBox}>
                    Win Count: {game.winCount}
                  </Typography>
                  <Typography className={classes.subTitleBox}>
                    Loss Count: {game.lossCount}
                  </Typography>
                  <Typography className={classes.subTitleBox}>
                    Win/Loss Ratio: {game.winLossRatio.toFixed(2)}
                  </Typography>
                  <Typography className={classes.subTitleBox}>
                    Total Bet Amount: {game.totalBetAmount}
                  </Typography>
                  <Typography className={classes.subTitleBox}>
                    Total Payout: {game.totalPayout}
                  </Typography>
                </Grid>
              </Paper>

              // </div>
              // </Grid>
            ))
          )}
          {/* </Grid> */}
        </div>
      </Grid>
    </Grid>
  );
}
