/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paddingAll: {
    padding: '0px 20px',
  },
  header1: {
    fontWeight: 'bold',
    top: '120px',
    fontSize: '20px',
    position: 'absolute',
  },
  mainContainer: {
    minHeight: '70vh',
  },
  header3: {
    fontWeight: '450',
    fontSize: '28px',
    marginTop: '100px',
  },
}));

export { useStyles };
