/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
const arrowDown = () => (
  <svg width="15" height="15" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.446202C0.062435 0.242237 0.171696 0.0835963 0.37461 0.0212733C0.530697 -0.0240524 0.676379 0.00994189 0.806452 0.111925C0.848075 0.145919 0.889698 0.191245 0.931322 0.230905C2.25806 1.68133 3.59001 3.13175 4.92196 4.58218C4.94797 4.61051 4.97399 4.63317 5.02081 4.68416C5.04683 4.6445 5.06244 4.60484 5.08845 4.57651C6.4204 3.12042 7.75754 1.66433 9.09469 0.213908C9.29761 -0.00705528 9.51613 -0.0580468 9.72945 0.0665991C10.0052 0.219574 10.0884 0.610508 9.90114 0.876797C9.86993 0.922123 9.83351 0.961783 9.79709 1.00144C8.33507 2.59351 6.87305 4.18558 5.41103 5.77765C5.13528 6.07793 4.85952 6.07793 4.58897 5.77765C3.15297 4.21391 1.71696 2.6445 0.280957 1.08076C0.166493 0.956118 0.0520291 0.831472 0 0.655834C0 0.587846 0 0.514191 0 0.446202Z" fill="black" />
  </svg>

);

export default arrowDown;
