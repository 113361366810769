import React from 'react';
import { Box } from '@material-ui/core';
import Table from './Components/Table';
import analyticsList from '../../../_mocks/analytics';
import { AnalyticsStyles } from './styles';

const Analytics = () => {
  const classes = AnalyticsStyles();

  return (
    <Box>
      <Box className={classes.paddingAll}>
        <Table analyticsList={analyticsList} />
      </Box>
    </Box>
  );
};

export default Analytics;
