/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Box, Typography } from "@material-ui/core";

import {
  GET_ALL_USERS,
  GET_NEXT_USERS,
  GET_PREV_USERS,
} from "graphql/query/admin";
import Table from "Components/Table/Table";
import { Loader } from "Components/TableLoader";
import { formStyles } from "./TableStyles";

const UsersList = ({
  setErrorMsg,
  errorMsg,
  searchQuery,
  actionPerformed,
  setActionPerformed,
  filter,
  userFilter,
}) => {
  const navigate = useNavigate();
  const classes = formStyles();
  const [users, setUsers] = useState([]);
  const [pageInfo, setPageInfo] = useState("");
  const [page, setPage] = useState(0);

  const { data, refetch, error, loading } = useQuery(GET_ALL_USERS, {
    variables: {
      first: 10,
      orderBy: "createdAt_DESC",
      filters:
        searchQuery?.length === 0
          ? !filter
            ? {}
            : userFilter
          : {
              username: searchQuery,
            },
    },
    fetchPolicy: "network-only",
  });

  const [getNextPage, { loading: nextPageLoader }] =
    useLazyQuery(GET_NEXT_USERS);
  const [getPrevPage, { loading: prevPageLoader }] =
    useLazyQuery(GET_PREV_USERS);

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          first: 10,
          after: pageInfo.endCursor,
          orderBy: "createdAt_DESC",
          filters:
            searchQuery?.length === 0
              ? !filter
                ? {}
                : userFilter
              : {
                  username: searchQuery,
                },
        },
        fetchPolicy: "network-only",
      });

      if (nextPageData.data.users) {
        const list = await nextPageData.data.users?.edges.map(
          (user) => user.node
        );
        setUsers(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.users?.pageInfo);
        setErrorMsg("");
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          last: 10,
          before: pageInfo.startCursor,
          orderBy: "createdAt_DESC",
          filters:
            searchQuery?.length === 0
              ? !filter
                ? {}
                : userFilter
              : {
                  username: searchQuery,
                },
        },
        fetchPolicy: "network-only",
      });

      if (prevPageData.data.users) {
        const list = await prevPageData.data.users.edges.map(
          (user) => user.node
        );
        setUsers(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.users.pageInfo);
        setErrorMsg("");
      }
    }
  };

  const setAllUsers = async () => {
    // Display All Data
    if (data && data.users) {
      const list = await data.users.edges.map((user) => user.node);
      setPageInfo(data.users.pageInfo);
      setUsers(list);
      setErrorMsg("");
    }
    setPage(0);
  };

  useEffect(() => {
    if (actionPerformed) {
      refetch();
      setActionPerformed(false);
      setAllUsers();
    }
    if (error) {
      setErrorMsg("Error in loading data !");
    }

    setAllUsers();
  }, [data, actionPerformed, error, searchQuery]);
  const handleViewButton = (id) => {
    console.log(id);
    navigate("/admin/user/view-user", { state: { id } });
  };

  const TABLE_HEAD = [
    {
      id: "id",
      flag: "id",
      label: "Sr.No",
      alignRight: false,
    },
    {
      id: "username",
      flag: "username",
      label: "Username",
      alignRight: false,
    },
    {
      id: "createdAt",
      flag: "createdAt",
      label: "Registration Date",
      alignRight: false,
    },
    {
      id: "actions",
      flag: { read: true, edit: false, remove: false },
      callback: { handleViewButton },
      label: "Actions",
      alignRight: false,
    },
  ];

  if (loading || nextPageLoader || prevPageLoader) {
    return <Loader />;
  }

  if (users && users.length > 0) {
    return (
      <Box className={classes.mainContainer}>
        <Table
          USERLIST={users}
          page={page}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          TABLE_HEAD={TABLE_HEAD}
        />
      </Box>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      {errorMsg.length > 0 ? (
        <Typography variant="h2" className={classes.errorText}>
          {errorMsg}
        </Typography>
      ) : (
        <Typography variant="h5">No Data Found !</Typography>
      )}
    </Box>
  );
};

export default UsersList;
