/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@material-ui/core/Grid";
import Selected from "Components/Selected/Selected";
import Filter from "./Components/Filter/Filter";
import Search from "./Components/SearchBar/Search";
import Button1 from "./Components/Button/Button";
import { topBarStyles } from "./TopbarStyles";

export default function TopBar({
  setSearchQuery,
  searchQuery,
  userFilter,
  setUserFilter,
  setActionPerformed,
  setFilter,
  title,
  onButtonClick,
}) {
  const classes = topBarStyles();
  const onClick = () => {
    setFilter(false);
    setUserFilter();
  };
  return (
    <div className={classes.root} style={{ marginBottom: "30px" }}>
      <Grid container spacing={3}>
        <Grid
          item
          xs={6}
          xl={6}
          md={6}
          lg={6}
          className={classes.dFlex}
          style={{ justifyContent: "unset" }}
        >
          <Grid item className={classes.dFlex1}>
            {/* <Typography variant="h4" className={classes.header1}>
              Announcements
            </Typography> */}
            <Filter
              setFilter={setFilter}
              setActionPerformed={setActionPerformed}
              setCategoryFilter={setUserFilter}
            />
            {userFilter && <Selected text="Clear Filters" onClick={onClick} />}
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.dFlex}>
          <Grid item className={classes.positionRelative}>
            <Search
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              text="Search Announcements..."
              flag
            />
          </Grid>
          <Grid item>
            <Button1 title={title} onButtonClick={onButtonClick} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
