import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_GAMES,
  GET_ALL_ROOMS,
  GET_NEXT_ALL_ROOMS,
  GET_PREV_ALL_ROOMS,
} from "graphql/query/admin";
import { gameConfigStyles } from "../gameStyles";
import { Box, Button } from "@material-ui/core";
import MainTable from "Components/Table/Table";
import Loader from "Components/Loader";
import CreateRoomModal from "Components/Modal/CreateRoomModal";
import { UPDATE_ROOM } from "graphql/mutation/admin";
import { toast } from "react-toastify";

const TableRoomManagement = ({ canUpdateRoom }) => {
  const classes = gameConfigStyles();
  const [selectedGameName, setSelectedGameName] = useState("all");

  const [tableData, setTableData] = useState([]);
  const [pageInfo, setPageInfo] = useState({});
  const [page, setPage] = useState(0);
  const [openCreateRoomModal, setOpenCreateRoomModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);

  // Fetch all games
  const { data, error, loading } = useQuery(GET_ALL_GAMES, {
    variables: { first: 10, orderBy: "createdAt_DESC" },
    fetchPolicy: "network-only",
  });

  // Fetch rooms with dynamic filters based on selected game name
  const {
    data: allRoomsData,
    error: allRoomsError,
    loading: allRoomsLoading,
    refetch,
  } = useQuery(GET_ALL_ROOMS, {
    variables: {
      first: 10,
      orderBy: "createdAt_DESC",
      filters:
        selectedGameName !== "all" ? { gameName: selectedGameName } : undefined,
    },
    fetchPolicy: "network-only",
  });

  const [updateRoom] = useMutation(UPDATE_ROOM);

  useEffect(() => {
    if (actionPerformed) {
      refetch();
      // refetchRooms();
      setActionPerformed(false);
    }
  }, [actionPerformed]);

  const [getBetNextPage, { loading: nextBetPageLoader }] =
    useLazyQuery(GET_NEXT_ALL_ROOMS);

  const [getBetPrevPage, { loading: prevBetPageLoader }] =
    useLazyQuery(GET_PREV_ALL_ROOMS);

  // Map rooms data consistently for next and previous pages
  const mapRoomData = (roomsData) => {
    return roomsData.map((item, index) => ({
      id: item.node.id,
      index: index + 1, // Serial number for the rows
      name: item.node._game.name || "-",
      tableNo: item.node.roomId || "-",
      activePlayersCount: item.node.playersAllowedInRoom || "-",
      isActive: item.node.isActive || false,
      createdAt: item.node.createdAt || "-",
    }));
  };

  // Handle Next Page Click
  const handleNextPage = async () => {
    if (pageInfo?.hasNextPage) {
      const nextPageData = await getBetNextPage({
        variables: {
          first: 10,
          after: pageInfo.endCursor,
          orderBy: "createdAt_DESC",
          filters:
            selectedGameName !== "all"
              ? { gameName: selectedGameName }
              : undefined,
        },
        fetchPolicy: "network-only",
      });

      if (nextPageData?.data?.getAllRooms) {
        const newRooms = mapRoomData(nextPageData.data.getAllRooms.edges);
        setTableData(newRooms);
        setPage(page + 1);
        setPageInfo(nextPageData.data.getAllRooms.pageInfo);
      }
    }
  };

  // Handle Previous Page Click
  const handlePreviousPage = async () => {
    if (pageInfo?.hasPreviousPage) {
      const prevPageData = await getBetPrevPage({
        variables: {
          last: 10,
          before: pageInfo.startCursor,
          orderBy: "createdAt_DESC",
          filters:
            selectedGameName !== "all"
              ? { gameName: selectedGameName }
              : undefined,
        },
        fetchPolicy: "network-only",
      });

      if (prevPageData?.data?.getAllRooms) {
        const newRooms = mapRoomData(prevPageData.data.getAllRooms.edges); // Map the new rooms data
        setTableData(newRooms); // Prepend new rooms data
        setPage(page - 1);
        setPageInfo(prevPageData.data.getAllRooms.pageInfo);
      }
    }
  };

  // Fetch initial rooms data and transform it
  useEffect(() => {
    if (allRoomsData) {
      const transformedData = mapRoomData(allRoomsData?.getAllRooms?.edges); // Use mapRoomData for initial data
      setTableData(transformedData); // Set initial data
      setPageInfo(allRoomsData.getAllRooms.pageInfo); // Set page info for pagination
    }
  }, [allRoomsData]);

  const handleToggleActions = async (roomId, currentStatus) => {
    try {
      const res = await updateRoom({
        variables: {
          updateRoomId: roomId,
          input: {
            isActive: currentStatus,
          },
        },
      });

      if (res.data.updateRoom.status === "success") {
        // refetch();
        toast.success(
          res?.data?.updateRoom?.message || "Room status updated successfully!"
        );
        // refetchRooms();
      }
    } catch (error) {
      toast.error(error?.message || "Failed to update room status!");
      console.error("Error updating room status:", error);
    }
  };

  const TABLE_HEAD = [
    { id: "index", label: "Sr.No", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "tableNo", label: "Room Id", alignRight: false },
    {
      id: "createdAt",
      label: "Created At",
      alignRight: false,
      flag: "createdAt",
    },
    // { id: "activePlayersCount", label: "Active Players", alignRight: false },
    {
      id: "toggleActions",
      flag: {
        switchValue: (row) => row.isActive,
        switchId: (row) => row.id,
      },
      callback: {
        handleToggleActions: (id, isActive) =>
          handleToggleActions(id, isActive),
      },
      label: "Actions",
      alignRight: false,
    },
  ];

  if (allRoomsLoading || nextBetPageLoader || prevBetPageLoader) {
    return <Loader />;
  }

  if (allRoomsError) {
    return <p>Error: {allRoomsError.message}</p>;
  }

  const handleCreateRoomClose = () => {
    setOpenCreateRoomModal(false);
  };

  const filteredGames = data?.getAllGames?.edges?.filter(
    (game) => game.node.name !== "Slots"
  );

  const handleSelectGame = (game) => {
    setSelectedGameName(game.node.name);
    setPage(0);
    setPageInfo({});
  };

  return (
    <>
      <div
        className={`${classes.gameConfig} ${classes.gameConfigRoom} ${classes.paddingx0}`}
      >
        <div className={classes.gameConfigHeader}>
          {/* "All" option */}
          <div
            key="all"
            className={`${classes.gameConfigBox} ${
              selectedGameName === "all" ? classes.selectedGame : ""
            }`}
            onClick={() => setSelectedGameName("all")} // Set selectedGameName to "all"
          >
            All
          </div>
          {/* Game options */}
          {filteredGames?.map((game) => (
            <div
              key={game.node.id}
              className={`${classes.gameConfigBox} ${
                selectedGameName === game.node.name ? classes.selectedGame : ""
              }`}
              onClick={() => handleSelectGame(game)}
            >
              {game.node.name}
            </div>
          ))}
        </div>
        {canUpdateRoom && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenCreateRoomModal(true)}
            className={classes.button}
          >
            Create Table/Room
          </Button>
        )}
      </div>

      <Box className={classes.paddingAll}>
        <MainTable
          TABLE_HEAD={TABLE_HEAD}
          USERLIST={tableData}
          page={page}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          setTableData={setTableData}
        />
      </Box>
      {openCreateRoomModal && (
        <CreateRoomModal
          open={openCreateRoomModal}
          handleClose={handleCreateRoomClose}
          heading={"Create Table/Room"}
          data={data}
          setActionPerformed={setActionPerformed}
        />
      )}
    </>
  );
};
export default TableRoomManagement;
