/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";

import Filter from "Assets/Svg/filter";
import ArrowDown from "Components/DropDownIcon/ArrowDown";
import ArrowDownIcon from "Assets/Svg/arrowdown";
import { ReactComponent as CloseIcon } from "Assets/Svg/closeMedium.svg";
import { useStyles } from "./FilterStyles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

export default function SimplePopover({
  // eslint-disable-next-line no-unused-vars
  setFilter,
  setActionPerformed,
  setCategoryFilter,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userFilters, setUserFilters] = useState({
    status: "",
    createdAtFrom: new Date(),
    createdAtTo: new Date(),
  });
  const [finalUserFilters, setFinalUserFilters] = useState();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetFilter = () => {
    setFilter(false);
    setCategoryFilter();
    setUserFilters({
      createdById: "",
      createdAtFrom: new Date(),
      createdAtTo: new Date(),
    });
    setFinalUserFilters();
    handleClose();
  };

  const filterUser = () => {
    setFilter(true);
    setCategoryFilter(finalUserFilters);
    setActionPerformed(true);
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        startIcon={<Filter />}
        endIcon={<ArrowDownIcon />}
        className={classes.filterButton}
      >
        <Typography className={classes.filterText}>Filter</Typography>
      </Button>
      <div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: classes.popUp3 }}
          marginThreshold={20}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorPosition={{ left: 20, top: 0 }}
        >
          <Box className={classes.popUp1}>
            <div className={classes.topBar}>
              <span
                role="button"
                tabIndex={0}
                onKeyDown={handleClose}
                onClick={handleClose}
                className={`${classes.onHover} ${classes.closeBtn}`}
              >
                <CloseIcon />
              </span>
            </div>
            <Grid container style={{ width: "100%", marginTop: "25px" }}>
              <Grid item className={classes.width100}>
                <FormControl
                  variant="outlined"
                  className={`${classes.formControl} ${classes.width93}`}
                  size="small"
                >
                  <InputLabel
                    id="demo-simple-select-outlined-label"
                    className={classes.inputLabel}
                  >
                    Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    MenuProps={{ classes: { paper: classes.dropdownStyle } }}
                    className={classes.selectTag}
                    IconComponent={ArrowDown}
                    id="demo-simple-select-outlined"
                    // value={userFilters.status}
                    onChange={(e) => {
                      setUserFilters({
                        ...userFilters,
                        status: e?.target?.value,
                      });
                      setFinalUserFilters({
                        ...finalUserFilters,
                        status: e?.target?.value,
                      });
                    }}
                    label="Status"
                  >
                    <MenuItem value="Scheduled">Scheduled</MenuItem>
                    <MenuItem value="Published">Published</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container className="date-pick1">
              <Grid item style={{ display: "flex", marginBottom: "20px" }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    label="From"
                    clearable
                    format="dd/MM/yyyy"
                    disableFuture
                    style={{
                      background: "rgba(0, 0, 0, 0.02)",
                      border: "1px solid #CCCCCC",
                      borderRadius: "4px",
                      marginTop: "-10px",
                      marginLeft: "8px",
                      width: "45%",
                      paddingLeft: "5px",
                    }}
                    value={userFilters.createdAtFrom}
                    onChange={(e) => {
                      setUserFilters({
                        ...userFilters,
                        createdAtFrom: moment(e).startOf("day"),
                      });
                      setFinalUserFilters({
                        ...finalUserFilters,
                        createdAtFrom: moment(e).startOf("day"),
                      });
                    }}
                    views={["year", "month", "date"]}
                  />
                  <DatePicker
                    autoOk
                    label="To"
                    clearable
                    format="dd/MM/yyyy"
                    disableFuture
                    style={{
                      background: "rgba(0, 0, 0, 0.02)",
                      border: "1px solid #CCCCCC",
                      borderRadius: "4px",
                      marginTop: "-10px",
                      marginLeft: "8px",
                      paddingLeft: "5px",
                      width: "45%",
                    }}
                    value={userFilters.createdAtTo}
                    onChange={(e) => {
                      setUserFilters({
                        ...userFilters,
                        createdAtTo: moment(e).endOf("day"),
                      });
                      setFinalUserFilters({
                        ...finalUserFilters,
                        createdAtTo: moment(e).endOf("day"),
                      });
                    }}
                    views={["year", "month", "date"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid />
            </Grid>
            <Grid container>
              <Grid item style={{ widht: "50%" }}>
                <Button
                  onClick={resetFilter}
                  variant="contained"
                  className={classes.buttonReset}
                >
                  Reset
                </Button>
              </Grid>
              <Grid item style={{ widht: "50%", marginLeft: "10px" }}>
                <Button
                  variant="contained"
                  onClick={filterUser}
                  className={classes.buttonApply}
                  color="primary"
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      </div>
    </div>
  );
}
