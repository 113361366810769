import { Announcement, Security } from "@material-ui/icons";

export const permissons = {
  // Admin: {
  //   GET: "View Details",
  //   GET_ALL: "View All",
  //   CREATE: "Create",
  //   UPDATE: "Update",
  //   DELETE: "Delete",
  // },
  // Roles: {
  //   GET: "View Details",
  //   GET_ALL: "View All",
  //   CREATE: "Create",
  //   UPDATE: "Update",
  //   DELETE: "Delete",
  // },
  // Dashboard: {
  //   GET_REPORT: "Get Reports",
  //   GET_SALES: "Get Sales",
  //   // GET_NOTIFICATIONS: "Get Notifications",
  //   // MARK_NOTIFICATION_READ: "Mark Notification Read",
  //   GAME_PERFORMANCE: "Game Performance",
  //   REAL_TIME_STATISTICS: "Real Time Statistics",
  //   REVENUE_TRACKING: "Revenue Tracking",
  // },
  // Category: {
  //   GET: 'View Details',
  //   GET_ALL: 'View All',
  //   CREATE: 'Create',
  //   UPDATE: 'Update',
  //   DELETE: 'Delete',
  // },
  // Transaction: {
  //   GET: "View Details",
  //   GET_ALL: "View All",
  //   CREATE: "Create",
  //   UPDATE: "Update",
  //   DELETE: "Delete",
  // },
  // ActivityLog: {
  //   GET: "View Details",
  //   GET_ALL: "View All",
  // },
  // Report: {
  //   USER_ON_BOARDING: "User Onboarding",
  // },
  // Support: {
  //   GET: "View Details",
  //   GET_ALL: "View All",
  //   SEND: "Send",
  // },
  Game: {
    GET: "View",
    UPDATE: "Update",
  },
  // Analytics: {
  //   GET_ONBOARDING_REPORTS: "Get Onboarding Reports",
  //   EXPORT_ONBOARDING_REPORTS: "Export Onboarding Reports",
  //   GET_SALES_REPORTS: "Get Sales Reports",
  //   EXPORT_SALES_REPORTS: "Export Sales Reports",
  //   GET_ISSUES_REPORTS: "Get Issues Reports",
  //   EXPORT_ISSUES_REPORTS: "Export Issues Reports",
  // },
  User: {
    BLOCK: "Block",
    GET: "View",
    // UN_BLOCK: "Unblock",
    // GET_VERIFICATION_REQUESTS: "Get Verification Request",
    // ACCEPT_REQUEST: "Accept Request",
    // REJECT_REQUEST: "Reject Request",
    // UN_VERIFY: "Unverify",
  },
  // Game: {
  //   GET: "View Details",
  //   GET_ALL: "View All",
  //   UPDATE: "Update",
  // },
  Room: {
    GET: "View",
    UPDATE: "Update",
  },
  Announcement: {
    GET: "View",
    UPDATE: "Update",
  },
  // Security: {
  //   GET: "View",
  //   GET_ALL: "View All",
  //   UPDATE: "Update",
  // },
  // Platform_Variables: {
  //   UPDATE_PLATFORM_VARIABLES: "Update Platform Variables",
  //   UPDATE_FEATURED_COLLECTIONS: "Update Featured Collections",
  //   CREATE_PLATFORM_VARIABLES: "Create Platform Variables",
  //   CREATE_FEATURED_COLLECTIONS: "Create Featured Collections",
  // },
};

// export const labels = {
//   // Admin: "Admin Management",
//   // // Category: 'Category Management',
//   // Transaction: "Transaction Management",
//   // User: "User Management",
//   // Platform_Variables: "Platform Variables",
//   // Support: "Support Management",
//   // // Analytics: 'Analytics',
//   // Report: "Report",
//   // ActivityLog: "ActivityLog",
//   // Dashboard: "Dashboard",
// };

export const labels = {
  // Admin: "Admin Management",
  User: "User",
  // Roles: "Roles Management",
  // Dashboard: "Dashboard",
  // GameConfiguration: "Game Configuration",
  Game: "Game Management",
  Room: "Room Management",
  Announcement: "Announcement Management",
  // Security: "Security Management",
};
