/* eslint-disable brace-style */
import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
} from "@material-ui/core";
import QrCodeGenerator from "qrcode";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as yup from "yup";

import { SIGNIN } from "graphql/mutation/register/index";
import { useStyles } from "./LoginStyles";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup.string("Enter your password").required("Password is required"),
});

const Login = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState("");
  const [SigninAdmin] = useMutation(SIGNIN);
  const navigate = useNavigate();
  const handleLogin = async (values) => {
    try {
      setLoading(true);
      setErrorMsg("");

      // Sign in api call
      const { data } = await SigninAdmin({
        variables: {
          input: values,
        },
      });

      const {
        message,
        qrCode,
        mobile,
        countryCode,
        accountType,
        status,
        isTwoFaEnabled,
        token,
      } = data.signIn;

      // Wrong Password or Email
      if (status === "error") {
        setErrorMsg(message);
      } else if (isTwoFaEnabled) {
        // 2FA Login Flow
        if (status === "awaiting_otp") {
          navigate("/verify-security-code", {
            state: {
              email: values.email,
              password: values.password,
              mobile,
              countryCode,
            },
          });
        }
      } else if (!isTwoFaEnabled) {
        // Normal Login Flow
        if (status === "success") {
          await localStorage.setItem("auth-token", token);
          navigate("/admin/dashboard", {
            state: {
              email: values.email,
              password: values.password,
            },
          });
          toast.success(message || "Login Successfully");
        }
      }

      // // Super Admin Login Flow
      // else if (accountType === "SUPER_ADMIN") {
      //   // Generating Qrcode image if superadmin login for first time
      //   if (qrCode) {
      //     const qrCodeUrl = await QrCodeGenerator.toDataURL(qrCode);
      //     navigate("/google-auth", {
      //       state: {
      //         email: values.email,
      //         password: values.password,
      //         qrCodeUrl,
      //       },
      //     });
      //   } else {
      //     // Verify security code
      //     navigate("/verify-security-code", {
      //       state: {
      //         email: values.email,
      //         password: values.password,
      //       },
      //     });
      //   }
      // }

      // // Admin Login Flow
      // else if (accountType === "ADMIN") {
      //   // Verify 2FA Otp
      //   navigate("/otp", {
      //     state: {
      //       email: values.email,
      //       password: values.password,
      //       type: "login",
      //       mobile,
      //       countryCode,
      //     },
      //   });
      // }

      // Unexpected error
      else {
        setErrorMsg("Something Went Wrong !");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setErrorMsg("Invalid Username/Password");
      setLoading(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      grantType: "PASSWORD",
    },
    validationSchema,
    onSubmit: (values) => {
      handleLogin(values);
    },
  });

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        formik.handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <Container maxWidth={false} disableGutters className={classes.app}>
      <Grid
        container
        item
        className={classes.loginBox}
        sm={6}
        md={4}
        lg={4}
        xl={3}
      >
        <Grid item className={`${classes.displayFlex} ${classes.width100}`}>
          <Typography variant="h6" className={classes.heading1}>
            {" "}
            Trust
          </Typography>
          <Typography className={classes.heading2}>BET ON-CHAIN</Typography>
        </Grid>
        <Grid item className={`${classes.displayFlex} ${classes.width100}`}>
          <Typography variant="h6" className={classes.heading3}>
            {" "}
            Login
          </Typography>
        </Grid>
        {errorMsg.length > 0 && (
          <Typography
            variant="subtitle1"
            className={classes.error}
            style={{ lineHeight: "20px", margin: "10px 0px" }}
          >
            {errorMsg}
          </Typography>
        )}
        <form
          className={classes.loginForm}
          noValidate
          autoComplete="off"
          onSubmit={formik.handleSubmit}
        >
          <TextField
            id="email"
            name="email"
            label="Email"
            variant="outlined"
            className={classes.width100}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperrtext={formik.touched.email && formik.errors.email}
            InputProps={{
              className: classes.inputHeight,
              classes: {
                input: classes.placeHolder,
              },
            }}
          />
          <TextField
            id="password"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            className={`${classes.width100} ${classes.marginTop}`}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperrtext={formik.touched.password && formik.errors.password}
            InputProps={{
              className: classes.inputHeight,
            }}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            className={classes.loginBtn}
          >
            {loading && (
              <CircularProgress size={20} style={{ color: "white" }} />
            )}
            {!loading && "Submit"}
          </Button>
        </form>
      </Grid>
    </Container>
  );
};

export default Login;
