/* eslint-disable  */

import { gql } from "@apollo/client";

// Role Management

export const CREATE_ROLE = gql`
  mutation CreateRole($createRoleInput2: CreateRoleInput!) {
    createRole(input: $createRoleInput2) {
      message
      status
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation Mutation($where: RoleWhereUpdateInput!, $input: UpdateRoleInput!) {
    updateRole(where: $where, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation Mutation($where: RoleWhereUpdateInput!) {
    deleteRole(where: $where) {
      message
      status
    }
  }
`;

// Admin Management

export const CREATE_ADMIN = gql`
  mutation CreateAdmin($input: CreateAdminInput!) {
    createAdmin(input: $input) {
      message
      status
    }
  }
`;

export const EDIT_ADMIN = gql`
  mutation UpdateAdmin(
    $where: AdminWhereUpdateInput!
    $input: UpdateAdminInput!
  ) {
    updateAdmin(where: $where, input: $input) {
      message
      status
    }
  }
`;
export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($where: AdminWhereUpdateInput!) {
    deleteAdmin(where: $where) {
      message
      status
    }
  }
`;
export const DISABLE_ADMIN = gql`
  mutation DisableAdmin($input: disableAdminInput!) {
    disableAdmin(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_PASSWORD = gql`
  mutation Mutation($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      message
      status
      referenceCode
      mobile
      countryCode
    }
  }
`;
export const UPDATE_NUMBER = gql`
  mutation UpdateMobile($input: UpdateMobileInput!) {
    updateMobile(input: $input) {
      message
      referenceCode
      status
      mobile
      countryCode
    }
  }
`;

// Category Management

export const CREATE_CATEGORY = gql`
  mutation Mutation($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $where: CategoryWhereUniqueInput!
    $input: UpdateCategoryInput!
  ) {
    updateCategory(where: $where, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_CATEGORY = gql`
  mutation DeleteCategory($input: CategoryWhereUniqueInput) {
    deleteCategory(input: $input) {
      message
      status
    }
  }
`;

// Platform Variables

export const UPDATE_VARIABLE = gql`
  mutation UpdateVariable(
    $input: UpdateVariableInput!
    $where: VariableWhereUpdateInput!
  ) {
    updateVariable(input: $input, where: $where) {
      message
      status
    }
  }
`;

export const UPDATE_FEATURED_COLLECTION = gql`
  mutation Mutation($input: CollectionFeaturedInput!) {
    featuredCollection(input: $input) {
      message
      status
    }
  }
`;

// Announcement Management

export const CREATE_ANNOUNCEMENT = gql`
  mutation CreateAnnouncement($input: CreateAnnouncementInput!) {
    createAnnouncement(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_ANNOUNCEMENT = gql`
  mutation UpdateAnnouncement(
    $updateAnnouncementId: ID!
    $input: UpdateAnnouncementInput!
  ) {
    updateAnnouncement(id: $updateAnnouncementId, input: $input) {
      message
      status
    }
  }
`;

export const DELETE_ANNOUNCEMENT = gql`
  mutation DeleteAnnouncement($deleteAnnouncementId: ID!) {
    deleteAnnouncement(id: $deleteAnnouncementId) {
      message
      status
    }
  }
`;

export const UPLOAD_IMAGE_MUTATION = gql`
  mutation UploadImageMutation($input: UploadImageInput!) {
    uploadImageMutation(input: $input) {
      message
      status
      imageUrl {
        preSignedUrl
        keyName
        s3Url
        keyPath
        fullPath
      }
    }
  }
`;

// Import collection

export const APPROVE_IMPORT_COLLECTION_REQUEST = gql`
  mutation ImportCollection($input: ImportCollectionInput!) {
    importCollection(input: $input) {
      message
      status
    }
  }
`;
export const REJECT_IMPORT_COLLECTION_REQUEST = gql`
  mutation UpdateForm($where: FormWhereUniqueInput!, $input: UpdateFormInput!) {
    updateForm(where: $where, input: $input) {
      message
      status
    }
  }
`;

// CMS
export const CREATE_CMS = gql`
  mutation Mutation($input: CreateCmsInput!) {
    createCms(input: $input) {
      message
      status
    }
  }
`;

export const UPDATE_CMS = gql`
  mutation Mutation($input: UpdateCmsInput!, $updateCmsId: ID!) {
    updateCms(input: $input, id: $updateCmsId) {
      message
      status
    }
  }
`;

export const DELETE_CMS = gql`
  mutation DeleteCms($deleteCmsId: ID!) {
    deleteCms(id: $deleteCmsId) {
      message
      status
    }
  }
`;

// game management
export const UPDATE_GAME = gql`
  mutation UpdateGame($updateGameId: ID!, $input: UpdateGameInput!) {
    updateGame(id: $updateGameId, input: $input) {
      message
      status
    }
  }
`;

//TWO FACTOR AUTHENTICATION
export const TWO_FA_VERIFY = gql`
  mutation TwoFaVerify($input: TwoFaVerifyInput!) {
    twoFaVerify(input: $input) {
      message
      status
      token
      qrCode
      secret
      accountType
    }
  }
`;
