/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { Box, Typography } from "@material-ui/core";

import {
  GET_ALL_ANNOUNCEMENTS,
  GET_NEXT_ANNOUNCEMENTS,
  GET_PREV_ANNOUNCEMENTS,
  GET_ANNOUNCEMENT_BY_ID,
} from "graphql/query/admin";
import Table from "Components/Table/Table";
import { Loader } from "Components/TableLoader";
import { formStyles } from "./TableStyles";
import AnnouncementModal from "Components/Modal/AnnouncementModal";
import ConfirmationModal from "Components/Modal/ConfirmationModal";
import { DELETE_ANNOUNCEMENT } from "graphql/mutation/admin";
import { toast } from "react-toastify";

const AnnouncementTable = ({
  setErrorMsg,
  errorMsg,
  searchQuery,
  actionPerformed,
  setActionPerformed,
  filter,
  userFilter,
}) => {
  const navigate = useNavigate();
  const classes = formStyles();
  const [announcements, setAnnouncements] = useState([]);
  const [pageInfo, setPageInfo] = useState("");
  const [page, setPage] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState("create");
  const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
  const [deleteOpenModal, setDeleteOpenModal] = useState(false);

  const { data, refetch, error, loading } = useQuery(GET_ALL_ANNOUNCEMENTS, {
    variables: {
      first: 10,
      orderBy: "createdAt_DESC",
      filters:
        searchQuery?.length === 0
          ? !filter
            ? {}
            : userFilter
          : {
              title: searchQuery,
            },
    },
    fetchPolicy: "network-only",
  });

  const [getNextPage, { loading: nextPageLoader }] = useLazyQuery(
    GET_NEXT_ANNOUNCEMENTS
  );
  const [getPrevPage, { loading: prevPageLoader }] = useLazyQuery(
    GET_PREV_ANNOUNCEMENTS
  );

  const [deleteAnnouncement] = useMutation(DELETE_ANNOUNCEMENT);

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          first: 10,
          after: pageInfo.endCursor,
          orderBy: "createdAt_DESC",
        },
        fetchPolicy: "network-only",
      });

      if (nextPageData.data.getAllAnnouncements) {
        const list = nextPageData.data.getAllAnnouncements?.edges.map(
          (user) => ({
            ...user.node,
            _status: user.node.status,
          })
        );
        setAnnouncements(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.getAllAnnouncements?.pageInfo);
        setErrorMsg("");
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          last: 10,
          before: pageInfo.startCursor,
          orderBy: "createdAt_DESC",
        },
        fetchPolicy: "network-only",
      });

      if (prevPageData.data.getAllAnnouncements) {
        const list = prevPageData.data.getAllAnnouncements.edges.map(
          (user) => ({
            ...user.node,
            _status: user.node.status,
          })
        );
        setAnnouncements(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.getAllAnnouncements.pageInfo);
        setErrorMsg("");
      }
    }
  };

  const setAllUsers = async () => {
    // Display All Data
    if (data && data.getAllAnnouncements) {
      const list = data.getAllAnnouncements.edges.map((user) => ({
        ...user.node,
        _status: user.node.status,
      }));
      setPageInfo(data.getAllAnnouncements.pageInfo);
      setAnnouncements(list);
      setErrorMsg("");
    }
    setPage(0);
  };

  useEffect(() => {
    if (actionPerformed) {
      refetch();
      setActionPerformed(false);
      setAllUsers();
    }
    if (error) {
      setErrorMsg("Error in loading data !");
    }

    setAllUsers();
  }, [data, actionPerformed, error, searchQuery]);

  const handleEditButton = (announcementId) => {
    const announcementToEdit = announcements.find(
      (announcement) => announcement.id === announcementId
    );
    setSelectedAnnouncement(announcementToEdit);
    setModalMode("edit"); // Set mode to edit
    setOpenModal(true); // Open the modal
  };

  const handleViewButton = (announcementId) => {
    const announcementToView = announcements.find(
      (announcement) => announcement.id === announcementId
    );
    setSelectedAnnouncement(announcementToView);
    setModalMode("view"); // Set mode to view
    setOpenModal(true); // Open the modal
  };

  const handleDeleteButton = (announcementId) => {
    const announcementToDelete = announcements.find(
      (announcement) => announcement.id === announcementId
    );
    setSelectedAnnouncement(announcementToDelete);
    setDeleteOpenModal(true);
  };

  const handleDeleteAnnouncement = () => {
    deleteAnnouncement({
      variables: {
        deleteAnnouncementId: selectedAnnouncement?.id,
      },
    })
      .then((res) => {
        if (res.data.deleteAnnouncement) {
          setDeleteOpenModal(false);
          setActionPerformed(true);
          toast.success(
            res?.deleteAnnouncement?.message ||
              "Announcement deleted successfully"
          );
        }
      })
      .catch((err) => {
        setErrorMsg(err.message);
        toast.error(err.message);
      });
  };

  const getActionsVisibility = (row) => {
    return row._status === "scheduled"; // Show actions only for "scheduled" status
  };

  const TABLE_HEAD = [
    {
      id: "id",
      flag: "id",
      label: "Sr.No",
      alignRight: false,
    },
    {
      id: "title",
      flag: "title",
      label: "Title",
      alignRight: false,
    },
    {
      id: "content",
      flag: "content",
      label: "Content",
      alignRight: false,
    },
    {
      id: "_status",
      flag: "_status",
      label: "Status",
      alignRight: false,
    },
    {
      id: "createdAt",
      flag: "createdAt",
      label: "Date",
      alignRight: false,
    },
    {
      id: "actions",
      flag: {
        read: true,
        edit: (announcement) => announcement._status === "scheduled",
        remove: (announcement) => announcement._status === "scheduled",
      },
      callback: { handleViewButton, handleEditButton, handleDeleteButton },
      alt: "announcements",
      label: "Actions",
      alignRight: false,
    },
  ];

  useEffect(() => {
    // Open modal only after selectedAnnouncement is set
    if (modalMode === "edit" && selectedAnnouncement) {
      setOpenModal(true);
    }
  }, [selectedAnnouncement, modalMode]);

  if (loading || nextPageLoader || prevPageLoader) {
    return <Loader />;
  }

  if (announcements && announcements.length > 0) {
    return (
      <>
        <Box className={classes.mainContainer}>
          <Table
            USERLIST={announcements}
            page={page}
            handleNextPage={handleNextPage}
            handlePreviousPage={handlePreviousPage}
            TABLE_HEAD={TABLE_HEAD}
            getActionsVisibility={getActionsVisibility}
          />
        </Box>
        {/* Announcement Modal (Create, Edit, View) */}
        <AnnouncementModal
          open={openModal}
          handleClose={() => setOpenModal(false)}
          editId={selectedAnnouncement?.id || ""}
          setActionPerformed={setActionPerformed}
          modalData={selectedAnnouncement}
          mode={modalMode}
          heading={
            modalMode === "edit"
              ? "Edit Announcement"
              : modalMode === "view"
              ? "View Announcement"
              : "Create Announcement"
          }
          handleOnEditSuccess={() => {
            // Handle success (refetch data or update table)
            setOpenModal(false);
          }}
          variables={announcements} // Pass variables if necessary
        />
        {/* Delete Modal */}
        {deleteOpenModal && (
          <ConfirmationModal
            open={deleteOpenModal}
            handleClose={() => setDeleteOpenModal(false)}
            button2={"Confirm"}
            button1={"Cancel"}
            onButton2Click={handleDeleteAnnouncement}
            onButton1Click={() => setDeleteOpenModal(false)}
            heading="Delete Announcement"
            subtitle="Are you sure you want to delete this announcement?"
          />
        )}
      </>
    );
  }

  return (
    <Box className={classes.mainContainer}>
      {errorMsg.length > 0 ? (
        <Typography variant="h2" className={classes.errorText}>
          {errorMsg}
        </Typography>
      ) : (
        <Typography variant="h5">No Data Found !</Typography>
      )}
    </Box>
  );
};
export default AnnouncementTable;
