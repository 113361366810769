/* eslint-disable  */
import { gql } from "@apollo/client";

// User Management

export const VERIFY_USER = gql`
  mutation Mutation($input: Input!) {
    verifyUser(input: $input) {
      message
      status
    }
  }
`;

export const BLACKLIST_USER = gql`
  mutation Mutation($input: Input!) {
    blockUser(input: $input) {
      status
      message
    }
  }
`;

export const BAN_USER = gql`
  mutation BanUser($input: BanUserInput!) {
    banUser(input: $input) {
      message
      status
    }
  }
`;

export const BLOCK_USER = gql`
  mutation BlockUser($input: BlockUserInput!) {
    blockUser(input: $input) {
      message
      status
    }
  }
`;

export const WHITELIST_USER = gql`
  mutation Mutation($input: Input!) {
    whiteListUser(input: $input) {
      message
      status
    }
  }
`;
