/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";

import { CREATE_ROLE, UPDATE_ROLE } from "graphql/mutation/admin";
import { labels } from "_mocks/permissonLabels";
import ConfirmationModal from "Components/Modal/ConfirmationModal";
import { formStyles } from "./RoleManagementStyles";
import Switch from "../Switch/Switch1";

const validationSchema = yup.object({
  name: yup.string("Enter valid Name").required("Name is required"),
});

const CreateRole = ({ edit, handleOnSuccess, toggleDrawer, initialValues }) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [formValues, setFormValues] = useState("");
  const [createRole] = useMutation(CREATE_ROLE);
  const [updateRole] = useMutation(UPDATE_ROLE);
  const classes = formStyles();

  const buttonOneClick = () => {
    setOpenConfirmationModal(false);
    setLoading(false);
  };

  const buttonTwoClick = async () => {
    try {
      setOpenConfirmationModal(false);
      setLoading(true);

      // Update role api call
      const { data } = await updateRole({
        variables: {
          where: {
            id: formValues.id,
          },
          input: {
            name: formValues.name,
            permissions: formValues.permissions,
          },
        },
        // refetchQueries: [{
        //   query: VIEW_ROLE,
        // }],
      });

      const { message } = data.updateRole;

      if (message === "Role updated Successfully") {
        handleOnSuccess();
        toggleDrawer("right", false)();
      }
      setLoading(false);
    } catch (err) {
      setErrorMsg("Something went wrong !");
      setLoading(false);
    }
  };

  const handleCreateForm = async (values) => {
    try {
      setLoading(true);
      setErrorMsg("");
      if (edit) {
        setOpenConfirmationModal(true);
        setFormValues(values);
      } else {
        const { data } = await createRole({
          variables: {
            createRoleInput2: values,
          },
        });

        const { status, message } = data.createRole;
        if (status === "success") {
          handleOnSuccess();
          toggleDrawer("right", false)();
        }
        if (status === "error") {
          setErrorMsg(message);
          setLoading(false);
        }
      }
      setLoading(false);
    } catch (err) {
      setErrorMsg("Something went wrong !");
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values) => {
      handleCreateForm(values);
    },
  });

  return (
    <Container maxWidth={false} disableGutters className={classes.app}>
      <Grid container className={classes.formBox}>
        <form
          noValidate
          autoComplete="off"
          id="adminForm"
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
          }}
        >
          <TextField
            id="name"
            name="name"
            placeholder="Role Name"
            variant="outlined"
            className={classes.width100}
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            style={{
              marginTop: "20px",
            }}
            InputProps={{
              classes: {
                input: classes.placeHolder,
              },
              className: classes.inputHeight,
            }}
          />
          <Typography className={classes.permissonTitle}>
            Role Permissions
          </Typography>
          {Object.entries(formik.values.permissions).map(([key, value]) => [
            value ? (
              <Switch
                key={key + value.label}
                value={value}
                permissionLabel={labels[key]}
                permissionName={key}
                componentName="Role"
                formik={formik}
              />
            ) : null,
          ])}
          {errorMsg.length > 0 && (
            <Typography variant="subtitle1" className={classes.error}>
              {errorMsg}
            </Typography>
          )}
          <Button
            color="primary"
            disabled={loading}
            variant="contained"
            fullWidth
            type="submit"
            className={classes.submitBtn}
          >
            {loading && <CircularProgress size={20} />}
            {!loading && "Submit"}
          </Button>
        </form>
      </Grid>
      <ConfirmationModal
        handleClose={buttonOneClick}
        open={openConfirmationModal}
        heading="Edit Role"
        subtitle="Are you sure you want to edit role?"
        button1="Cancel"
        button2="Edit"
        onButton1Click={buttonOneClick}
        onButton2Click={buttonTwoClick}
      />
    </Container>
  );
};

export default CreateRole;
