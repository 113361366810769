/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
import {
  Container, Grid, Typography, Button, Table,
} from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

import ApproveCollection from 'Components/Modal/ApproveCollection';
import Default from 'Assets/Images/default.png';
import { Loader } from 'Components/TableLoader/index';
import { VIEW_COLLECTION_REQUEST } from 'graphql/query/admin';
import { REJECT_IMPORT_COLLECTION_REQUEST } from 'graphql/mutation/admin';
import SuccessModal from 'Components/Modal/SuccessModal';
import ConfirmationModal from 'Components/Modal/ConfirmationModal';
import { useStyles } from './ViewCollectionStyles';

const ViewCollectionRequest = () => {
  const classes = useStyles();
  const [rejectCollection] = useMutation(REJECT_IMPORT_COLLECTION_REQUEST);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openSuccessModal1, setOpenSuccessModal1] = useState(false);
  const [disableConfirmation, setDisableConfirmation] = useState(false);
  const [disable, setDisable] = useState(false);
  const [disable2, setDisable2] = useState(false);
  const [nsfwConfirmation, setNsfwConfirmation] = useState(false);
  const { state } = useLocation();
  const { id = '' } = state || '';
  const {
    data, loading, refetch,
  } = useQuery(VIEW_COLLECTION_REQUEST, {
    variables: {
      formId: id,
    },
    fetchPolicy: 'network-only',
  });

  const onHandleRejectSuccess = async () => {
    try {
      const response = await rejectCollection({
        variables: {
          where: {
            id,
          },
          input: {
            status: 'Rejected',
          },
        },
      });
      const { message, status } = response.data.updateForm;
      if (status === 'error') {
        console.log(message);
        return;
      }
      refetch();
      setOpenSuccessModal(true);
      setDisableConfirmation(true);
      setDisable(false);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSuceessClose = () => {
    setOpenSuccessModal(false);
    setDisableConfirmation(false);
  };
  const handleDisableClose = () => {
    setDisable(false);
  };

  const handleDisableClose2 = () => {
    setDisable2(false);
  };

  const onHandleApproveSuccess = async () => {
    refetch();
    setOpenSuccessModal1(true);
    setNsfwConfirmation(true);
    setTimeout(() => {
      setOpenSuccessModal1(false);
      setNsfwConfirmation(false);
    }, 3000);
  };
  const handleSuceessClose1 = () => {
    setOpenSuccessModal(false);
    setNsfwConfirmation(false);
  };

  if (loading) {
    return <Loader />;
  }

  const { form } = data;
  const {
    collectionName,
    collectionAddress,
    collectionTotalNFTCount,
    ownerAddress,
    status,
    category,
    createdAt,
    pendingNFTs,
    // eslint-disable-next-line no-unused-vars
    collectionDescription, collectionLinkExampleUri, contactInfo, avatar, backgroundImg, owner,
  } = form;

  return (
    <Container
      maxWidth={false}
      disableGutters
      className={classes.app}
    >
      <Typography className={classes.path}>
        <Link to="/admin/import-collection" className={classes.link}>Import Collection Requests</Link>
        &gt;
        <Typography className={classes.cursorPointer}> View Collection </Typography>
      </Typography>
      <Grid container className={classes.profileHeadContainer}>
        <Grid lg={12} md={12} xs={12} item>
          <Grid container>
            <Grid lg={8} md={8} sm={8} item>
              <div style={{
                display: 'flex', alignItems: 'center', width: '100%', height: '100',
              }}
              >
                <Table className={classes.profileInfo}>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Collection Name</td>
                    <td className={classes.profileColumn}>{ collectionName }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Description</td>
                    <td className={classes.profileColumn}>
                      { collectionDescription }
                    </td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Collection Address</td>
                    <td className={classes.profileColumn} style={{ color: 'blue', textDecoration: 'underline' }}>{ collectionAddress }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Collection Link</td>
                    <a target="_blank" rel="noreferrer" href={collectionLinkExampleUri}>
                      <td className={classes.profileColumn}>{ collectionLinkExampleUri }</td>
                    </a>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Collection Length</td>
                    <td className={classes.profileColumn}>{ collectionTotalNFTCount }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Category Type</td>
                    <td className={classes.profileColumn}>{ category?.name }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Owner Address</td>
                    <td className={classes.profileColumn} style={{ color: 'blue', textDecoration: 'underline' }}>{ ownerAddress }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Owner Name</td>
                    <td className={classes.profileColumn}>{ owner.username }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Contact Info</td>
                    <a target="_blank" rel="noreferrer" href={contactInfo}>
                      <td className={classes.profileColumn}>{ contactInfo }</td>
                    </a>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Pending NFT&apos;s</td>
                    <td className={classes.profileColumn}>{ pendingNFTs.length }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Status</td>
                    <td className={classes.profileColumn}>{ status }</td>
                  </tr>
                  <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Requested At</td>
                    <td className={classes.profileColumn}>
                      {moment(createdAt).format('MMMM Do YYYY')}
                    </td>
                  </tr>
                </Table>
              </div>
            </Grid>
            <Grid lg={3} md={3} sm={3} item>
              { ((status === 'Pending') || (status === 'OnReview')) && (
              <Button
                color="primary"
                onClick={() => setDisable2(true)}
                size="small"
                variant="outlined"
                style={{
                  width: '140px', height: '40px', background: 'white', marginRight: '20px', fontWeight: 'bold', marginLeft: '-30px', marginTop: '-50px', fontSize: '15px',
                }}
              >
                Approve
              </Button>
              )}
              { (status === 'OnReview') && (
              <Button
                color="primary"
                onClick={() => setDisable(true)}
                size="small"
                variant="contained"
                style={{
                  width: '140px', height: '40px', background: 'linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)', fontWeight: 'bold', marginTop: '-50px', fontSize: '15px',
                }}
              >
                Reject
              </Button>
              )}
              <div style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                marginLeft: '0px',
                marginTop: '-10px',
                justifyContent: 'center',
              }}
              >
                <img
                  src={avatar}
                  onError={(e) => e.target.src = Default}
                  className={classes.avatar}
                  alt="image1"
                />
              </div>
            </Grid>
            <Grid lg={1} md={1} sm={1} item />
          </Grid>
        </Grid>
      </Grid>
      { disableConfirmation && <SuccessModal handleClose={handleSuceessClose} open={openSuccessModal} heading="Collection Rejected" subtitle="Import collection request rejected" type="delete" />}
      { disable && <ConfirmationModal handleClose={handleDisableClose} open={handleDisableClose} heading="Reject Collection" subtitle="Are you sure you want to reject this import collection request." button1="Cancel" button2="Confirm" onButton1Click={handleDisableClose} onButton2Click={onHandleRejectSuccess} /> }
      { nsfwConfirmation && <SuccessModal handleClose={handleSuceessClose1} open={openSuccessModal1} heading="Collection Approved" subtitle="Import collection request approved" type="delete" />}
      <ApproveCollection setActionPerformed={onHandleApproveSuccess} id={id} handleClose={handleDisableClose2} open={disable2} heading="Approve Collection" subtitle="" button1="Submit" button2="Disable" onButton1Click={handleDisableClose} onButton2Click={onHandleApproveSuccess} />
    </Container>
  );
};
export default ViewCollectionRequest;
