import React, { useCallback } from "react";
import InputBase from "@material-ui/core/InputBase";
import { ReactComponent as SearchIcon } from "Assets/Svg/Header/searchBlack.svg";
import ClearIcon from "@material-ui/icons/Clear";
import IconButton from "@material-ui/core/IconButton";
import { searchBarStyles } from "./SearchStyles";

export default function Search({ text, flag, setSearchQuery, searchQuery }) {
  const classes = searchBarStyles();
  const searchClass = flag ? classes.searchClass : classes.search;

  const handleSearchChange = useCallback(
    (event) => {
      const value = event.target.value;
      // Directly update search query without trimming to maintain typing experience
      setSearchQuery(value);
    },
    [setSearchQuery]
  );

  const handleKeyPress = useCallback(
    (event) => {
      if (event.key === "Enter") {
        // Trim on enter press
        setSearchQuery(searchQuery.trim());
      }
    },
    [searchQuery, setSearchQuery]
  );

  const handleClear = useCallback(() => {
    setSearchQuery("");
  }, [setSearchQuery]);

  return (
    <div className={`${searchClass}`}>
      <div className={`${classes.searchIcon}`}>
        <SearchIcon className={classes.colorBlack} />
      </div>
      <InputBase
        placeholder={text}
        value={searchQuery}
        onChange={handleSearchChange}
        onKeyPress={handleKeyPress}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{
          "aria-label": "search",
          type: "text",
          autoComplete: "off",
          spellCheck: "false",
        }}
        endAdornment={
          searchQuery ? (
            <IconButton
              size="small"
              aria-label="clear search"
              onClick={handleClear}
              className={classes.clearButton}
            >
              <ClearIcon fontSize="small" />
            </IconButton>
          ) : null
        }
      />
    </div>
  );
}
