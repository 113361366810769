import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { tabStyles } from './CollectibleStyles';
// import Topbar from './Components/TopBar/TopBar';
import Table from './Components/Table/Table';

const Category = () => {
  const classes = tabStyles();
  const [errorMsg, setErrorMsg] = useState('');

  return (
    <Box>
      {/* <Topbar title="Create New Category" /> */}
      <Box className={classes.paddingAll}>
        <Table
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
        />
      </Box>
    </Box>
  );
};

export default Category;
