// import { clusterApiUrl } from "@solana/web3.js";

import { IDL } from "../types/betting_system";

// export const CLUSTER = import.meta.env.VITE_STAGING_CLUSTER as SupportedNetwork;
// export const CLUSTER = import.meta.env.VITE_CLUSTER as SupportedNetwork;
export const CLUSTER = "devnet";

export const PROGRAM_IDL = IDL;

// export const PRIVATE_MAINNET_CLUSTER_URL =
//   "https://mainnet.helius-rpc.com/?api-key=30159576-62b3-40a4-829f-7f195176081f";

// export const PRIVATE_DEVNET_CLUSTER_URL =
//   "https://devnet.helius-rpc.com/?api-key=4e5294ae-a339-472f-ae5c-9bba6bf68c8a";

export const PRIVATE_MAINNET_CLUSTER_URL =
  "https://devnet.helius-rpc.com/?api-key=4e5294ae-a339-472f-ae5c-9bba6bf68c8a";

export const PRIVATE_DEVNET_CLUSTER_URL =
  "https://devnet.helius-rpc.com/?api-key=4e5294ae-a339-472f-ae5c-9bba6bf68c8a";

export const ENDPOINT =
  CLUSTER === "devnet"
    ? PRIVATE_DEVNET_CLUSTER_URL
    : PRIVATE_MAINNET_CLUSTER_URL;

export const price = 0.1;
export const vaultMintDecimals = 9;
