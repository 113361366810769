export const IDL= {
  "version": "0.1.0",
  "name": "betting_system",
  "instructions": [
    {
      "name": "initializeAdminStats",
      "accounts": [
        {
          "name": "adminStats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "initialize",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "mint",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "rent",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": []
    },
    {
      "name": "depositTokens",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "withdrawTokens",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "placeCasinoBet",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "betState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "roomId",
          "type": "string"
        },
        {
          "name": "betAmount",
          "type": "u64"
        },
        {
          "name": "gameType",
          "type": {
            "defined": "GameType"
          }
        }
      ]
    },
    {
      "name": "increaseBet",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "betState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "additionalAmount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "declareCasinoWinner",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "betState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "winnerState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminStats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "roomId",
          "type": "string"
        },
        {
          "name": "payoutAmount",
          "type": "u64"
        },
        {
          "name": "adminProfit",
          "type": "i64"
        },
        {
          "name": "adminLoss",
          "type": "i64"
        }
      ]
    },
    {
      "name": "placeSportsBet",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "userState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "sportsBetState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "user",
          "isMut": true,
          "isSigner": true
        },
        {
          "name": "systemProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "eventId",
          "type": "string"
        },
        {
          "name": "outcomeId",
          "type": "string"
        },
        {
          "name": "betAmount",
          "type": "u64"
        },
        {
          "name": "odds",
          "type": "string"
        }
      ]
    },
    {
      "name": "finalizeRound",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminStats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "roomId",
          "type": "string"
        },
        {
          "name": "betStates",
          "type": {
            "vec": "publicKey"
          }
        },
        {
          "name": "winners",
          "type": {
            "vec": "publicKey"
          }
        },
        {
          "name": "payouts",
          "type": {
            "vec": "u64"
          }
        },
        {
          "name": "adminProfit",
          "type": "i64"
        },
        {
          "name": "adminLoss",
          "type": "i64"
        }
      ]
    },
    {
      "name": "declareSportsBetWinner",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "sportsBetState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminStats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": [
        {
          "name": "eventId",
          "type": "string"
        },
        {
          "name": "winners",
          "type": {
            "vec": "publicKey"
          }
        },
        {
          "name": "payouts",
          "type": {
            "vec": "u64"
          }
        },
        {
          "name": "adminProfit",
          "type": "i64"
        },
        {
          "name": "adminLoss",
          "type": "i64"
        }
      ]
    },
    {
      "name": "adminDepositTbet",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminStats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "adminWithdraw",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "adminStats",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vault",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "vaultAuthority",
          "isMut": false,
          "isSigner": false
        },
        {
          "name": "recipientTokenAccount",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": false,
          "isSigner": true
        },
        {
          "name": "tokenProgram",
          "isMut": false,
          "isSigner": false
        }
      ],
      "args": [
        {
          "name": "amount",
          "type": "u64"
        }
      ]
    },
    {
      "name": "pauseContract",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": []
    },
    {
      "name": "unpauseContract",
      "accounts": [
        {
          "name": "bettingState",
          "isMut": true,
          "isSigner": false
        },
        {
          "name": "admin",
          "isMut": false,
          "isSigner": true
        }
      ],
      "args": []
    }
  ],
  "accounts": [
    {
      "name": "adminStats",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "totalProfit",
            "type": "u64"
          },
          {
            "name": "totalLoss",
            "type": "u64"
          },
          {
            "name": "netBalance",
            "type": "i64"
          },
          {
            "name": "totalDeposits",
            "type": "u64"
          },
          {
            "name": "totalWithdrawn",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "bettingState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "admin",
            "type": "publicKey"
          },
          {
            "name": "paused",
            "type": "bool"
          },
          {
            "name": "vaultBump",
            "type": "u8"
          }
        ]
      }
    },
    {
      "name": "userState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "owner",
            "type": "publicKey"
          },
          {
            "name": "balance",
            "type": "u64"
          },
          {
            "name": "betCounter",
            "type": "u64"
          }
        ]
      }
    },
    {
      "name": "betState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "roomId",
            "type": "string"
          },
          {
            "name": "user",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "betType",
            "type": {
              "defined": "BetType"
            }
          },
          {
            "name": "gameType",
            "type": {
              "option": {
                "defined": "GameType"
              }
            }
          },
          {
            "name": "status",
            "type": {
              "defined": "BetStatus"
            }
          },
          {
            "name": "createdAt",
            "type": "i64"
          }
        ]
      }
    },
    {
      "name": "sportsBetState",
      "type": {
        "kind": "struct",
        "fields": [
          {
            "name": "eventId",
            "type": "string"
          },
          {
            "name": "outcomeId",
            "type": "string"
          },
          {
            "name": "user",
            "type": "publicKey"
          },
          {
            "name": "amount",
            "type": "u64"
          },
          {
            "name": "odds",
            "type": "string"
          },
          {
            "name": "status",
            "type": {
              "defined": "BetStatus"
            }
          },
          {
            "name": "createdAt",
            "type": "i64"
          }
        ]
      }
    }
  ],
  "types": [
    {
      "name": "BetType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Casino"
          },
          {
            "name": "Sports"
          }
        ]
      }
    },
    {
      "name": "GameType",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Baccarat"
          },
          {
            "name": "Blackjack"
          },
          {
            "name": "Slots"
          },
          {
            "name": "Poker"
          },
          {
            "name": "Roulette"
          }
        ]
      }
    },
    {
      "name": "BetStatus",
      "type": {
        "kind": "enum",
        "variants": [
          {
            "name": "Active"
          },
          {
            "name": "Completed"
          },
          {
            "name": "Cancelled"
          }
        ]
      }
    }
  ],
  "events": [
    {
      "name": "DepositEvent",
      "fields": [
        {
          "name": "user",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "amount",
          "type": "u64",
          "index": false
        }
      ]
    },
    {
      "name": "WithdrawEvent",
      "fields": [
        {
          "name": "user",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "amount",
          "type": "u64",
          "index": false
        }
      ]
    },
    {
      "name": "CasinoBetPlaced",
      "fields": [
        {
          "name": "user",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "roomId",
          "type": "string",
          "index": false
        },
        {
          "name": "amount",
          "type": "u64",
          "index": false
        },
        {
          "name": "gameType",
          "type": {
            "defined": "GameType"
          },
          "index": false
        }
      ]
    },
    {
      "name": "BetIncreased",
      "fields": [
        {
          "name": "user",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "roomId",
          "type": "string",
          "index": false
        },
        {
          "name": "additionalAmount",
          "type": "u64",
          "index": false
        }
      ]
    },
    {
      "name": "CasinoGameWinnerDeclared",
      "fields": [
        {
          "name": "roomId",
          "type": "string",
          "index": false
        },
        {
          "name": "winner",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "payoutAmount",
          "type": "u64",
          "index": false
        },
        {
          "name": "adminProfit",
          "type": "i64",
          "index": false
        },
        {
          "name": "adminLoss",
          "type": "i64",
          "index": false
        },
        {
          "name": "adminNetBalance",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "SportsBetPlaced",
      "fields": [
        {
          "name": "user",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "eventId",
          "type": "string",
          "index": false
        },
        {
          "name": "outcomeId",
          "type": "string",
          "index": false
        },
        {
          "name": "amount",
          "type": "u64",
          "index": false
        },
        {
          "name": "odds",
          "type": "string",
          "index": false
        }
      ]
    },
    {
      "name": "RoundFinalized",
      "fields": [
        {
          "name": "roomId",
          "type": "string",
          "index": false
        },
        {
          "name": "winners",
          "type": {
            "vec": "publicKey"
          },
          "index": false
        },
        {
          "name": "payouts",
          "type": {
            "vec": "u64"
          },
          "index": false
        },
        {
          "name": "adminProfit",
          "type": "i64",
          "index": false
        },
        {
          "name": "adminLoss",
          "type": "i64",
          "index": false
        },
        {
          "name": "adminNetBalance",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "SportsBetWinnerDeclared",
      "fields": [
        {
          "name": "eventId",
          "type": "string",
          "index": false
        },
        {
          "name": "winners",
          "type": {
            "vec": "publicKey"
          },
          "index": false
        },
        {
          "name": "payouts",
          "type": {
            "vec": "u64"
          },
          "index": false
        },
        {
          "name": "adminProfit",
          "type": "i64",
          "index": false
        },
        {
          "name": "adminLoss",
          "type": "i64",
          "index": false
        },
        {
          "name": "adminNetBalance",
          "type": "i64",
          "index": false
        }
      ]
    },
    {
      "name": "AdminWithdrawEvent",
      "fields": [
        {
          "name": "amount",
          "type": "u64",
          "index": false
        },
        {
          "name": "recipient",
          "type": "publicKey",
          "index": false
        },
        {
          "name": "totalWithdrawn",
          "type": "u64",
          "index": false
        }
      ]
    },
    {
      "name": "AdminTBETDepositEvent",
      "fields": [
        {
          "name": "amount",
          "type": "u64",
          "index": false
        },
        {
          "name": "totalDeposits",
          "type": "u64",
          "index": false
        }
      ]
    }
  ],
  "errors": [
    {
      "code": 6000,
      "name": "ContractPaused",
      "msg": "Contract is paused"
    },
    {
      "code": 6001,
      "name": "InvalidAmount",
      "msg": "Invalid amount"
    },
    {
      "code": 6002,
      "name": "InsufficientBalance",
      "msg": "Insufficient balance"
    },
    {
      "code": 6003,
      "name": "ContractAlreadyPaused",
      "msg": "Contract is already paused"
    },
    {
      "code": 6004,
      "name": "ContractNotPaused",
      "msg": "Contract is not paused"
    },
    {
      "code": 6005,
      "name": "BetNotActive",
      "msg": "Bet is not active"
    },
    {
      "code": 6006,
      "name": "UnauthorizedUser",
      "msg": "Unauthorized user"
    },
    {
      "code": 6007,
      "name": "InvalidGameId",
      "msg": "Invalid game ID"
    },
    {
      "code": 6008,
      "name": "InvalidBetType",
      "msg": "Invalid bet type"
    },
    {
      "code": 6009,
      "name": "OverflowError",
      "msg": "Arithmetic overflow"
    },
    {
      "code": 6010,
      "name": "InvalidInput",
      "msg": "Invalid input parameters"
    },
    {
      "code": 6011,
      "name": "InvalidRoomId",
      "msg": "Invalid room ID format"
    }
  ]
};