/* eslint-disable react/prop-types */
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { topBarStyles } from './TopbarStyles';
import Button from './Components/Button/Button';

export default function TopBar({ title, onButtonClick }) {
  const classes = topBarStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={6} xl={6} md={6} lg={6} />
        <Grid item xs={6} className={classes.dFlex}>
          <Grid item className={classes.positionRelative} style={{ marginRight: '20px' }}>
            <Button title={title} onButtonClick={onButtonClick} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
