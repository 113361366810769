/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import moment from "moment";
import {
  Table,
  TableRow,
  TableBody,
  Icon,
  TableCell,
  TableContainer,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Read from "../../Assets/Svg/view.svg";
import Edit from "../../Assets/Svg/edit.svg";
import Delete from "../../Assets/Svg/delete.svg";
import { tableStyles } from "./tableStyles";
import Pagination from "./Components/Pagination";
import Switch from "./Components/Switch";
import TableHeader from "./Components/TableHeader";
import { SwitchStyles1 } from "Components/Modal/ModalStyles";

function MainTable({
  USERLIST,
  TABLE_HEAD,
  getActionsVisibility,
  handleNextPage,
  handlePreviousPage,
  page = 0,
  setPage,
  noPagination,
  setTableData,
}) {
  const classes = tableStyles();
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isTrue = USERLIST?.length % rowsPerPage === 0;
  const maxPage = isTrue
    ? parseInt(USERLIST?.length / rowsPerPage, 10)
    : parseInt(USERLIST?.length / rowsPerPage, 10) + 1;

  return (
    <>
      <TableContainer className={classes.mainBox}>
        <Table>
          <TableHeader headLabel={TABLE_HEAD} rowCount={USERLIST?.length} />
          <TableBody>
            {USERLIST?.length === 0 ? (
              <TableRow>
                <TableCell
                  colSpan={TABLE_HEAD.length}
                  style={{ textAlign: "center", padding: "20px" }}
                >
                  NO DATA FOUND!
                </TableCell>
              </TableRow>
            ) : (
              USERLIST?.map((row, index) => {
                const { id } = row;
                const finalClass =
                  index % 2 === 0
                    ? classes.box
                    : `${classes.box} ${classes.backgroundGrey}`;
                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    className={finalClass}
                  >
                    {TABLE_HEAD?.map((value, tindex) => {
                      const val = value.id;
                      const pageIndex = index;
                      if (tindex === 0) {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            {page * rowsPerPage + index + 1}
                          </TableCell>
                        );
                      }
                      if (val === "status" || val === "isActive") {
                        console.log(USERLIST[pageIndex][val]);
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            <Switch status={USERLIST[pageIndex][val]} />
                          </TableCell>
                        );
                      }
                      if (val === "createdBy") {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            {USERLIST[pageIndex] &&
                              USERLIST[pageIndex][val]?.fullName}
                          </TableCell>
                        );
                      }
                      if (
                        val === "createdByUsername" ||
                        val === "currentOwner"
                      ) {
                        const account =
                          USERLIST[pageIndex][value.flag]?.walletAddress;
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            <a
                              href={`${
                                process.env.REACT_APP_FRONT_END_POINT_URL_DEV
                              }/artist-profile/${
                                USERLIST[pageIndex][value.flag]?.id
                              }`}
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                padding: 0,
                                textDecoration: "underline",
                                margin: 0,
                              }}
                            >
                              {account
                                ? `${account?.slice(0, 4)}...${account?.slice(
                                    -4
                                  )}`
                                : "----"}
                            </a>
                          </TableCell>
                        );
                      }
                      if (val === "isStatus") {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            {USERLIST[pageIndex] &&
                              USERLIST[pageIndex][value.flag]}
                          </TableCell>
                        );
                      }
                      if (val === "createdAt") {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            {USERLIST[pageIndex] &&
                              moment(USERLIST[pageIndex][val]).format(
                                "MMMM Do YYYY"
                              )}
                          </TableCell>
                        );
                      }
                      if (
                        value.flag === "adminRole" ||
                        value.flag === "collection"
                      ) {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            {USERLIST[pageIndex] &&
                            USERLIST[pageIndex][val]?.name
                              ? USERLIST[pageIndex][val]?.name
                              : "----"}
                          </TableCell>
                        );
                      }
                      if (value.flag === "viewCollectiblepaymentMethod") {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="center"
                          >
                            {USERLIST[tindex][val]}
                          </TableCell>
                        );
                      }
                      if (
                        value.flag === "txnHash" ||
                        value.flag === "owner" ||
                        value.flag === "creator" ||
                        value.flag === "collectibleName"
                      ) {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.contentLink}
                            align="left"
                          >
                            {USERLIST[tindex][val]}
                          </TableCell>
                        );
                      }
                      if (value.flag === "tokenIdWithLink") {
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            <a
                              href="https://etherscan.io/address/0x9f7dd5ea934d188a599567ee104e97fa46cb4496#tokentxns"
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                padding: 0,
                                textDecoration: "none",
                                margin: 0,
                              }}
                            >
                              {USERLIST[pageIndex][val]}{" "}
                            </a>
                          </TableCell>
                        );
                      }
                      if (val === "toggleActions") {
                        const { switchValue, switchId } = value.flag || "";
                        const { handleToggleActions } = value?.callback || "";
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                          >
                            <SwitchStyles1
                              name=""
                              checked={USERLIST[pageIndex].isActive}
                              onChange={async () => {
                                const updatedIsActive =
                                  !USERLIST[pageIndex].isActive;
                                const roomId = USERLIST[pageIndex].id; // Ensure room ID is captured

                                // Update local state
                                const newUserList = [...USERLIST];
                                newUserList[pageIndex] = {
                                  ...USERLIST[pageIndex],
                                  isActive: updatedIsActive,
                                };
                                setTableData(newUserList);

                                // Pass both ID and updated status to the handler
                                await handleToggleActions(
                                  roomId,
                                  updatedIsActive
                                );
                              }}
                              id={value.flag.switchId}
                            />
                          </TableCell>
                        );
                      }
                      if (val === "actions") {
                        const { read, edit, remove, add } = value.flag || "";
                        const {
                          handleViewButton,
                          handleEditButton,
                          handleDeleteButton,
                          handleAddButton,
                        } = value?.callback || "";
                        // Get actions visibility condition from props
                        const shouldDisplayActions = getActionsVisibility
                          ? getActionsVisibility(row)
                          : true;
                        if (!read && !edit && !remove && !add) return <></>;
                        return (
                          <TableCell
                            key={value.id}
                            className={classes.content}
                            align="left"
                            style={
                              shouldDisplayActions
                                ? {
                                    width: "150px",
                                  }
                                : {}
                            }
                          >
                            {read && (
                              <Icon
                                onClick={() =>
                                  value.alt === "data"
                                    ? handleViewButton(row)
                                    : handleViewButton(row.id)
                                }
                              >
                                <img
                                  alt="imageAlt"
                                  className={classes.iconRead}
                                  src={Read}
                                />
                              </Icon>
                            )}
                            {edit && shouldDisplayActions && (
                              <Icon
                                onClick={() =>
                                  value?.alt === "announcements"
                                    ? handleEditButton(row?.id)
                                    : handleEditButton(row)
                                }
                              >
                                <img
                                  alt="imageAlt"
                                  className={classes.iconEdit}
                                  src={Edit}
                                />
                              </Icon>
                            )}
                            {remove && shouldDisplayActions && (
                              <Icon onClick={() => handleDeleteButton(row.id)}>
                                <img
                                  alt="imageAlt"
                                  className={classes.iconDelete}
                                  src={Delete}
                                />
                              </Icon>
                            )}
                            {add && (
                              <Icon
                                style={{ cursor: "pointer" }}
                                onClick={() => handleAddButton(row.id)}
                              >
                                <AddCircleIcon />
                              </Icon>
                            )}
                          </TableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={value.id}
                          className={classes.content}
                          align="left"
                        >
                          {USERLIST[pageIndex][val]}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!noPagination && (
        <Pagination
          currentPage={page}
          maxPage={maxPage}
          handlePrevPage={handlePreviousPage}
          handleNextPage={handleNextPage}
        />
      )}
    </>
  );
}

export default MainTable;
