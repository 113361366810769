import { Program, web3 } from "@coral-xyz/anchor";
import { getProvider } from ".";
import { PROGRAM_IDL } from ".";
import { BETTING_SYSTEM_PROGRAM_ID } from "./address";
import { Buffer } from 'buffer';

export const getProgram = (wallet) => {
  return new Program(
    // @ts-ignore
    PROGRAM_IDL,
    BETTING_SYSTEM_PROGRAM_ID,
    getProvider(wallet)
  );
};
export const getProgramPDAs = async (adminPublicKey) => {
  // Generate vault PDA
  const [vault] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from("vault")],
    BETTING_SYSTEM_PROGRAM_ID
  );

  // Generate vault authority PDA
  const [vaultAuthority] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from("vault")],
    BETTING_SYSTEM_PROGRAM_ID
  );

  // Generate user state PDA
  const [adminState] = web3.PublicKey.findProgramAddressSync(
    [Buffer.from("admin_stats"), adminPublicKey.toBuffer()],
    BETTING_SYSTEM_PROGRAM_ID
  );

  return { vault, vaultAuthority, adminState };
};
