import React from "react";
import { Box } from "@material-ui/core";
import { mainStyles } from "./dashboardStyles";

import Topbar from "./Components/Topbar";
import StatCard from "./Components/StatCard";
import GameStatCard from "./Components/GameCard";
import Graph from "./Components/Graph/Graph";

function Dashboard() {
  const classes = mainStyles();
  return (
    <Box className={classes.box}>
      {/* <Topbar /> */}
      <StatCard />
      <Graph />
      <GameStatCard />
    </Box>
  );
}

export default Dashboard;
