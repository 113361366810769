import { makeStyles } from "@material-ui/core/styles";

const tabStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingRight: "0px",
    minHeight: "100vh",
  },
  submitBtn: {
    height: "40px",
    background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
    margin: "30px 10px",
  },
  tabBar: {
    boxShadow: "none !important",
    paddingLeft: "15px",
    backgroundColor: "white !important",
  },
  tabBox: {
    backgroundColor: "white !important",
    color: "black",
    borderBottom: "1px solid grey !important",
  },
  tabValue: {
    fontStyle: "normal",
    fontWeight: 550,
    fontSize: "19px",
    padding: "10px 30px",
    minWidth: "350px",
    textTransform: "none",
  },
}));

// // eslint-disable-next-line import/prefer-default-export
export { tabStyles };

// const gameConfigStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   gameConfig: {
//     padding: theme.spacing(2),
//     // display: "flex",
//     // flexDirection: "row",
//     // justifyContent: "space-between",
//   },
//   gameConfigHeader: {
//     display: "flex",
//     flexDirection: "row",
//     justifyContent: "left",
//     flexWrap: "wrap",
//   },
//   gameConfigBox: {
//     border: "1px solid linear-gradient(180deg, #219653 0%, #299C35 100%)",
//     background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
//     width: "100px",
//     textAlign: "center",
//     margin: "10px",
//     borderRadius: "10px",
//     padding: "10px",
//     cursor: "pointer",
//     // boxShadow: "0px 0px 10px 0px #00000026",
//   },
//   //   gameform: {
//   //     display: "flex",
//   //     flexDirection: "column",
//   //     justifyContent: "space-between",
//   //   },
// }));

// export { gameConfigStyles };

const gameConfigStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  width100: {
    width: "100%",
  },
  gameConfig: {
    padding: theme.spacing(2),
  },
  gameConfigHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    flexWrap: "wrap",
  },
  gameConfigBox: {
    border: "1px solid transparent",
    background: "linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))",
    width: "100px",
    textAlign: "center",
    margin: "10px",
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    transition: "background 0.3s, border 0.3s",
  },
  selectedGame: {
    border: "2px solid #70D99F", // Highlight border color
    background: "rgba(112, 217, 159, 0.2)", // Light highlight background
  },
  formContainer: {
    marginTop: "20px",
    padding: "20px",
    borderRadius: "10px",
    border: "1px solid #ccc",
    background: "#f9f9f9",
  },
  label: {
    display: "block",
    margin: "10px 0 5px",
  },
  button: {
    marginTop: "20px",
    background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  disableButton: {
    marginTop: "20px",
    background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "not-allowed",
  },
  dflex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    gap: "20px",
  },
  inputHeight: {
    backgroundColor: "#E5E5E5",
    height: "30px",
    border: "1px solid #CCCCCC",
    borderRadius: "4px",
    padding: "0px 10px",
    color: "black",
    fontSize: "14px",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "red !important",
    },
    "&:focus": {
      outline: "none",
      border: "1px solid #CCCCCC",
    },
  },
}));
export { gameConfigStyles };
