import React from "react";
import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import CssBaseline from "@material-ui/core/CssBaseline";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { useStyles } from "./SidebarStyles";

import Menu1 from "../../Assets/Svg/Sidebar/menu1.svg";
import Menu2 from "../../Assets/Svg/Sidebar/menu2.svg";
import Menu3 from "../../Assets/Svg/Sidebar/menu3.svg";
import Menu4 from "../../Assets/Svg/Sidebar/menu4.svg";
import Menu5 from "../../Assets/Svg/Sidebar/menu5.svg";
import Menu6 from "../../Assets/Svg/Sidebar/menu6.svg";
import Menu7 from "../../Assets/Svg/Sidebar/menu7.svg";
import Menu8 from "../../Assets/Svg/Sidebar/menu8.svg";
import Menu9 from "../../Assets/Svg/Sidebar/menu9.svg";
import Menu10 from "../../Assets/Svg/cNft.svg";
import Menu11 from "../../Assets/Svg/Sidebar/menu10.svg";
import Menu12 from "../../Assets/Svg/Sidebar/Game Management.svg";
import Menu13 from "../../Assets/Svg/Sidebar/Security Management.svg";
import Menu14 from "../../Assets/Svg/Sidebar/Announcement Management.svg";
import { useQuery } from "@apollo/client";
import { ADMIN_PROFILE } from "graphql/query/admin";

// eslint-disable-next-line react/prop-types
export default function Sidebar({ open, setHeaderText }) {
  const navigate = useNavigate();

  const { data, loading, error, refetch } = useQuery(ADMIN_PROFILE, {
    fetchPolicy: "network-only",
  });

  const isSuperAdmin = data?.me?.role?.name === "SUPER_ADMIN";

  // const adminRoutes = [
  //   "/admin/dashboard",
  //   "/admin/administrator",
  //   "/admin/user",
  //   // "/admin/cms",
  //   // "/admin/platform",
  //   "/admin/game-management",
  //   "/admin/announcement-management",
  //   "/admin/security-management",
  //   "/admin/support",
  // ];

  const routeMapping = {
    Dashboard: "/admin/dashboard",
    Admin: "/admin/administrator",
    User: "/admin/user",
    "Game Management": "/admin/game-management",
    "Announcement Management": "/admin/announcement-management",
    "Security Management": "/admin/security-management",
    Support: "/admin/support",
    "Funds Management": "/admin/funds-management",
    "Staking Management": "/admin/staking-management",
  };

  const adminRouteNames = [
    "Dashboard",
    "Admin",
    "User",
    // "CMS",
    // "Platform Variable",
    "Game Management",
    "Announcement Management",
    "Security Management",
    "Support",
    "Funds Management",
    "Staking Management",
  ];

  const filterAdminRoutes = () => {
    if (isSuperAdmin) {
      // Super Admin has access to all routes
      return adminRouteNames;
    }

    // For normal admins, filter based on permissions
    return adminRouteNames.filter((routeName) => {
      // Always include "Dashboard" and "Security Management" for all users
      if (
        routeName === "Dashboard" ||
        routeName === "Security Management" ||
        routeName === "Support"
      ) {
        return true;
      }

      // Check if the route is "Admin", which is restricted for normal Admins
      if (routeName === "Admin" || routeName === "Funds Management") {
        return false;
      }

      // Map route names to permission keys
      const permissionMap = {
        User: "User",
        "Game Management": ["Game", "Room"],
        "Announcement Management": "Announcement",
        // Add other mappings as necessary
      };

      // Find the permission key corresponding to the route

      const permissionKeys = permissionMap[routeName];

      if (Array.isArray(permissionKeys)) {
        return permissionKeys.some((key) => {
          const permissions = data?.me?.permissions[key];
          return permissions && (permissions.GET || permissions.GET_ALL);
        });
      } else {
        const permissions = data?.me?.permissions[permissionKeys];
        return permissions && (permissions.GET || permissions.GET_ALL);
      }
    });
  };

  const filteredRoutes = filterAdminRoutes();

  const [active, setActive] = React.useState(0);
  const classes = useStyles();
  const location = useLocation();
  const pathName = location.pathname;

  // React.useEffect(() => {
  //   adminRoutes.map((route, index) => {
  //     if (route === pathName) {
  //       setActive(index);
  //       setHeaderText(adminRouteNames[index]);
  //     }
  //     return true;
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [pathName]);

  React.useEffect(() => {
    filteredRoutes.forEach((route, index) => {
      if (routeMapping[route] === pathName) {
        setActive(index);
        setHeaderText(route);
      }
    });
  }, [pathName, filteredRoutes, routeMapping]);
  const onButtonClick = (index, text, path) => {
    refetch();
    setActive(index);
    setHeaderText(text);

    if (text === "Support") {
      window.location.href =
        "https://solulab5059.zendesk.com/hc/en-us/requests/new";
      return;
    }

    navigate(routeMapping[text]);
  };

  return (
    <>
      <div className={classes.root}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, classes.sectionDesktop, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <List>
            {filteredRoutes.map((text, index) => {
              if (active === index) {
                return (
                  <ListItem
                    button
                    key={text}
                    className={`${classes.menuSelected} ${classes.listItem} `}
                    onClick={() =>
                      onButtonClick(index, text, routeMapping[text])
                    }
                  >
                    {text === "Dashboard" && (
                      <Tooltip title="Dashboard">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu1} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Admin" && (
                      <Tooltip title="Admin">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu2} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {/* { text === 'Category' && (
                    <Tooltip title="Category">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu3} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                    ) } */}
                    {/* { text === 'Transaction' && (
                    <Tooltip title="Transaction">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu4} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                    ) } */}
                    {/* { text === 'Collectible' && (
                    <Tooltip title="Collectible">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu5} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                    ) } */}
                    {/* { text === 'Import Collection' && (
                    <Tooltip title="Import Collection">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight} style={{ marginTop: '6px' }}>
                          <img alt="imageAlt" src={Menu11} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                    ) } */}
                    {text === "User" && (
                      <Tooltip title="User">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu6} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Game Management" && (
                      <Tooltip title="Game Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu12} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Security Management" && (
                      <Tooltip title="Security Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu13} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Announcement Management" && (
                      <Tooltip title="Announcement Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu14} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Support" && (
                      <Tooltip title="Support">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu7} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "CMS" && (
                      <Tooltip title="CMS">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu9} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {/* { text === 'Analytics & Reports' && (
                    <Tooltip title="Analytics & Reports">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu8} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                    ) } */}
                    {text === "Platform Variable" && (
                      <Tooltip title="Platform Variable">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu8} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Funds Management" && (
                      <Tooltip title="Funds Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu8} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Staking Management" && (
                      <Tooltip title="Staking Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu8} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Contract Management" && (
                      <Tooltip title="Contract Management">
                        <ListItemIcon
                          className={classes.iconSelected}
                          style={{ marginLeft: "4px" }}
                        >
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu10} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    <ListItemText
                      primary={text}
                      classes={{ primary: classes.menuTextSelected }}
                    />
                  </ListItem>
                );
              }
              return (
                <ListItem
                  button
                  key={text}
                  className={classes.listItem}
                  onClick={() => onButtonClick(index, text, routeMapping[text])}
                >
                  {text === "Dashboard" && (
                    <Tooltip title="Dashboard">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu1} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Admin" && (
                    <Tooltip title="Admin">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu2} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {/* { text === 'Category' && (
                  <Tooltip title="Category">
                    <ListItemIcon className={classes.icon}>
                      <Icon className={classes.iconHeight}>
                        <img alt="imageAlt" src={Menu3} />
                      </Icon>
                    </ListItemIcon>
                  </Tooltip>
                  ) } */}
                  {/* { text === 'Transaction' && (
                  <Tooltip title="Transaction">
                    <ListItemIcon className={classes.icon}>
                      <Icon className={classes.iconHeight}>
                        <img alt="imageAlt" src={Menu4} />
                      </Icon>
                    </ListItemIcon>
                  </Tooltip>
                  ) } */}
                  {/* { text === 'Collectible' && (
                  <Tooltip title="Collectible">
                    <ListItemIcon className={classes.icon}>
                      <Icon className={classes.iconHeight}>
                        <img alt="imageAlt" src={Menu5} />
                      </Icon>
                    </ListItemIcon>
                  </Tooltip>
                  ) } */}
                  {/* { text === 'Import Collection' && (
                  <Tooltip title="Import Collection">
                    <ListItemIcon className={classes.icon}>
                      <Icon className={classes.iconHeight} style={{ marginTop: '6px' }}>
                        <img alt="imageAlt" src={Menu11} />
                      </Icon>
                    </ListItemIcon>
                  </Tooltip>
                  ) } */}
                  {text === "User" && (
                    <Tooltip title="User">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu6} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Game Management" && (
                    <Tooltip title="Game Management">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu12} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Security Management" && (
                    <Tooltip title="Security Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu13} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Announcement Management" && (
                    <Tooltip title="Announcement Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu14} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Support" && (
                    <Tooltip title="Support">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu7} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "CMS" && (
                    <Tooltip title="CMS">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu9} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {/* { text === 'Analytics & Reports' && (
                  <Tooltip title="Analytics & Reports">
                    <ListItemIcon className={classes.icon}>
                      <Icon className={classes.iconHeight}>
                        <img alt="imageAlt" src={Menu8} />
                      </Icon>
                    </ListItemIcon>
                  </Tooltip>
                  ) } */}
                  {text === "Platform Variable" && (
                    <Tooltip title="Platform Variable">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu8} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Funds Management" && (
                    <Tooltip title="Funds Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu8} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Staking Management" && (
                    <Tooltip title="Staking Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu8} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Contract Management" && (
                    <Tooltip title="Contract Management">
                      <ListItemIcon
                        className={classes.icon}
                        style={{ marginLeft: "4px" }}
                      >
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu10} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  <ListItemText
                    primary={text}
                    classes={{ primary: classes.menuText }}
                  />
                </ListItem>
              );
            })}
          </List>
        </Drawer>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, classes.sectionMobile, {
            [classes.drawerClose]: true,
          })}
          classes={{
            paper: clsx({
              [classes.drawerClose]: true,
            }),
          }}
        >
          <List>
            {filteredRoutes.map((text, index) => {
              if (active === index) {
                return (
                  <ListItem
                    button
                    key={text}
                    className={`${classes.menuSelected} ${classes.listItem} `}
                    onClick={() =>
                      onButtonClick(index, text, routeMapping[text])
                    }
                  >
                    {text === "Dashboard" && (
                      <Tooltip title="Dashboard">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu1} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Admin" && (
                      <Tooltip title="Admin">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu2} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Category" && (
                      <Tooltip title="Category">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu3} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Transaction" && (
                      <Tooltip title="Transaction">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu4} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Collectible" && (
                      <Tooltip title="Collectible">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu5} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Import Collection" && (
                      <Tooltip title="Import Collection">
                        <ListItemIcon className={classes.icon}>
                          <Icon
                            className={classes.iconHeight}
                            style={{ marginTop: "6px" }}
                          >
                            <img alt="imageAlt" src={Menu11} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "User" && (
                      <Tooltip title="User">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu6} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Game Management" && (
                      <Tooltip title="Game Management">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu12} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Security Management" && (
                      <Tooltip title="Security Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu13} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Announcement Management" && (
                      <Tooltip title="Announcement Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu14} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Support" && (
                      <Tooltip title="Support">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu7} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Analytics & Reports" && (
                      <Tooltip title="Analytics & Reports">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu8} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Platform Variable" && (
                      <Tooltip title="Platform Variable">
                        <ListItemIcon className={classes.icon}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu9} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Funds Management" && (
                      <Tooltip title="Funds Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu8} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    {text === "Staking Management" && (
                      <Tooltip title="Staking Management">
                        <ListItemIcon className={classes.iconSelected}>
                          <Icon className={classes.iconHeight}>
                            <img alt="imageAlt" src={Menu8} />
                          </Icon>
                        </ListItemIcon>
                      </Tooltip>
                    )}
                    <ListItemText primary={text} className={classes.menuText} />
                  </ListItem>
                );
              }

              return (
                <ListItem
                  button
                  key={text}
                  className={classes.listItem}
                  onClick={() => onButtonClick(index, text, routeMapping[text])}
                >
                  {text === "Dashboard" && (
                    <Tooltip title="Dashboard">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu1} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Admin" && (
                    <Tooltip title="Admin">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu2} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Category" && (
                    <Tooltip title="Category">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu3} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Transaction" && (
                    <Tooltip title="Transaction">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu4} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Collectible" && (
                    <Tooltip title="Collectible">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu5} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Import Collection" && (
                    <Tooltip title="Import Collection">
                      <ListItemIcon className={classes.icon}>
                        <Icon
                          className={classes.iconHeight}
                          style={{ marginTop: "6px" }}
                        >
                          <img alt="imageAlt" src={Menu11} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "User" && (
                    <Tooltip title="User">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu6} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Game Management" && (
                    <Tooltip title="Game Management">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu12} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Security Management" && (
                    <Tooltip title="Security Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu13} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Announcement Management" && (
                    <Tooltip title="Announcement Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu14} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Support" && (
                    <Tooltip title="Support">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu7} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Analytics & Reports" && (
                    <Tooltip title="Analytics & Reports">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu8} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Platform Variable" && (
                    <Tooltip title="Platform Variable">
                      <ListItemIcon className={classes.icon}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu9} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Funds Management" && (
                    <Tooltip title="Funds Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu8} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  {text === "Staking Management" && (
                    <Tooltip title="Staking Management">
                      <ListItemIcon className={classes.iconSelected}>
                        <Icon className={classes.iconHeight}>
                          <img alt="imageAlt" src={Menu8} />
                        </Icon>
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  <ListItemText primary={text} className={classes.menuText} />
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      </div>
    </>
  );
}
