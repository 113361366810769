/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { useStyles, tabStyles } from './styles';
import Topbar from './Components/Topbar/Topbar';
import Table from './Components/Table/Table';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const Transaction = () => {
  const classes = tabStyles();
  const mainClasses = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box>
      <AppBar position="static" className={classes.tabBar}>
        <Tabs TabIndicatorProps={{ style: { background: 'black', height: '2px' } }} className={classes.tabBox} value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab className={classes.tabValue} label="Minted NFT" {...a11yProps(0)} />
          <Tab className={classes.tabValue} label="Other Transactions" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Topbar title="Create New Transaction" />
      <Box className={mainClasses.paddingAll}>
        <Table />
      </Box>
    </Box>
  );
};

export default Transaction;
