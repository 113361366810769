/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { Box } from '@material-ui/core';

import ConfirmationModal from 'Components/Modal/ConfirmationModal';
import { Loader } from 'Components/TableLoader';
import { UPDATE_FEATURED_COLLECTION } from 'graphql/mutation/admin';
import { GET_ALL_FEATURED_COLLECTION, GET_NEXT_FEATURED_COLLECTION, GET_PREV_FEATURED_COLLECTION } from 'graphql/query/admin';
import Table from 'Components/Table/Table';
import EditVariable from 'Components/Modal/EditFeatureCollection';
import SuccessModal from 'Components/Modal/SuccessModal';

const PlatformTable = ({ setErrorMsg, setEditFlag, editFlag }) => {
  const [editConfirmation, setEditConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openSuccessModal1, setOpenSuccessModal1] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [variables, setVariables] = useState([]);
  const [pageInfo, setPageInfo] = useState('');
  const [getNextPage] = useLazyQuery(GET_NEXT_FEATURED_COLLECTION);
  const [getPrevPage] = useLazyQuery(GET_PREV_FEATURED_COLLECTION);
  const [page, setPage] = useState(0);
  const [updateFeaturedCollection] = useMutation(UPDATE_FEATURED_COLLECTION);
  const {
    loading, error, data, refetch,
  } = useQuery(GET_ALL_FEATURED_COLLECTION, {
    variables: {
      orderBy: 'createdAt_DESC',
      first: 10,
      filters: {
        isFeaturedBool: 'true',
      },
    },
  });

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          orderBy: 'createdAt_DESC',
          first: 10,
          filters: {
            isFeaturedBool: 'true',
          },
        },
        fetchPolicy: 'network-only',
      });

      if (nextPageData.data.collections) {
        const list = await nextPageData.data.collections.edges.map((category) => category.node);
        setVariables(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.collections.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          orderBy: 'createdAt_DESC',
          first: 10,
          filters: {
            isFeaturedBool: 'true',
          },
        },
        fetchPolicy: 'network-only',
      });

      if (prevPageData.data.collections) {
        const list = await prevPageData.data.collections.edges.map((category) => category.node);
        setVariables(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.collections.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handleConfirmationClose = () => {
    setOpenConfirmationModal(false);
  };

  const buttonOneClick = () => {
    setOpenConfirmationModal(false);
  };

  const handleOnEditSuccess = () => {
    setEditConfirmation(true);
    setOpenSuccessModal(true);
    setTimeout(() => {
      setOpenSuccessModal(false);
      setEditConfirmation(false);
    }, 3000);
    refetch();
  };

  const handleOnDeleteSuccess = () => {
    setDeleteConfirmation(true);
    setOpenSuccessModal1(true);
    setTimeout(() => {
      setOpenSuccessModal1(false);
    }, 3000);
    refetch();
  };

  const buttonTwoClick = async () => {
    try {
      const response = await updateFeaturedCollection({
        variables: {
          input: {
            id: deleteId,
            isFeatured: false,
          },
        },
      });

      const { status, message } = response.data.featuredCollection;

      // Error
      if (status === 'error') {
        setErrorMsg(message);
      } else {
        // Collection featured Successfully
        setOpenConfirmationModal(false);
        handleOnDeleteSuccess();
      }
    } catch (err) {
      setErrorMsg('Something went wrong !');
      // setLoading(false);
    }
  };

  const setAllCollections = async () => {
    // Display All Data
    if (data && data.collections) {
      const list = await data?.collections?.edges?.map((collection) => collection.node);
      setVariables(list);
      setErrorMsg('');
    }
    setPage(0);
    setPageInfo(data?.collections?.pageInfo);
  };

  useEffect(() => {
    if (error) { setErrorMsg('Error in loading data !'); }

    setAllCollections();
  }, [data, error]);

  const handleSuceessClose = () => {
    setOpenSuccessModal(false);
    setEditConfirmation(false);
  };
  const handleSuceessClose1 = () => {
    setOpenSuccessModal1(false);
    setDeleteConfirmation(false);
  };
  const handleOneditFlagSuccess = () => {
    setEditFlag(false);
  };
  const handleEditFlagClose = () => {
    setEditFlag(false);
  };

  const handleViewButton = () => { };
  const handleEditButton = () => { };
  const handleDeleteButton = (id) => {
    setDeleteId(id);
    setOpenConfirmationModal(true);
  };

  const TABLE_HEAD = [
    {
      id: 'id', flag: 'id', label: 'Sr.No', alignRight: false,
    },
    {
      id: 'name', flag: 'name', label: 'Collection Name', alignRight: false,
    },
    {
      id: 'description', flag: 'description', label: 'Description', alignRight: false,
    },
    {
      id: 'actions',
      flag: { read: false, edit: false, remove: true },
      callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: 'Actions',
      alignRight: false,
    },
  ];

  if (loading) {
    return (
      <Box>
        <Loader />
      </Box>
    );
  }

  return (
    <>
      <Table
        handleNextPage={handleNextPage}
        handlePreviousPage={handlePreviousPage}
        page={page}
        setPage={setPage}
        noPagination
        USERLIST={variables}
        TABLE_HEAD={TABLE_HEAD}
      />
      <EditVariable handleOnEditSuccess={handleOnEditSuccess} handleClose={handleEditFlagClose} open={editFlag} heading="Add Featured Collection" collection="Collection 2" button1="Search" onButton1Click={handleEditFlagClose} onButton2Click={handleOneditFlagSuccess} />
      <ConfirmationModal handleClose={handleConfirmationClose} open={openConfirmationModal} heading="Remove Collection" subtitle="Are you sure you want to remove this collection from the featured collection list?" button1="Cancel" button2="Confirm" onButton1Click={buttonOneClick} onButton2Click={buttonTwoClick} />
      {editConfirmation && <SuccessModal handleClose={handleSuceessClose} open={openSuccessModal} heading="Collection added successfully" subtitle="" type="edit" /> }
      {deleteConfirmation && <SuccessModal handleClose={handleSuceessClose1} open={openSuccessModal1} heading="Collection removed successfully" subtitle="" type="edit" /> }
    </>
  );
};

export default PlatformTable;
