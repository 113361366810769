import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import ArrowUp from "Assets/Svg/arrowUp.svg";
import ArrowDown from "Assets/Svg/arrowDown.svg";
import Icon1 from "Assets/Svg/icon1.svg";
import Icon4 from "Assets/Svg/icon4.svg";
import Icon5 from "Assets/Svg/icon5.svg";
import Icon6 from "Assets/Svg/icon6.svg";
import { statCardStyles } from "../dashboardStyles";
import { useLazyQuery } from "@apollo/client";
import { GET_DASHBOARD_DATA } from "graphql/query/admin";

export default function SpacingGrid() {
  const classes = statCardStyles();

  const [dashboardStats, setDashboardStats] = React.useState({
    totalUsers: { totalCount: 0, growthPercentage: 0 },
    totalBets: { totalCount: 0, growthPercentage: 0 },
    totalPayout: { totalCount: 0, growthPercentage: 0 },
    avgBetAmount: { totalCount: 0, growthPercentage: 0 },
  });

  const stats = [
    {
      icon: Icon4,
      numCount: dashboardStats.totalUsers.totalCount,
      perCount: dashboardStats.totalUsers.growthPercentage,
      title: "Total Users",
      color: "#F4FF77",
      growth: true,
    },
    {
      icon: Icon5,
      numCount: dashboardStats.totalBets.totalCount,
      perCount: dashboardStats.totalBets.growthPercentage,
      title: " Total Bets",
      color: "#D7FFF2",
      growth: dashboardStats.totalBets.growthPercentage === 0 ? null : true,
    },
    {
      icon: Icon6,
      numCount: dashboardStats.totalPayout.totalCount,
      perCount: dashboardStats.totalPayout.growthPercentage,
      title: "Total Payout",
      subtitle: "60,000 USDT",
      color: "#FFDBDF",
      growth: true,
    },
    {
      icon: Icon6,
      numCount: dashboardStats.avgBetAmount.totalCount,
      perCount: null,
      title: "Avg Bet Amount",
      subtitle: "10,000 USDT",
      color: "#D8FFB1",
      growth: null,
    },
  ];

  useEffect(() => {
    getDashboardData();
  }, []);

  const [getDashboardData, { data }] = useLazyQuery(GET_DASHBOARD_DATA, {
    onCompleted: (data) => {
      setDashboardStats({
        totalUsers: {
          totalCount: data.dashboard.userdata.totalCount?.toFixed(2),
          growthPercentage: data.dashboard.userdata.countIncreased.toFixed(2),
        },
        totalBets: {
          totalCount: data.dashboard.BettingHistoryData.totalCount?.toFixed(2),
          growthPercentage:
            data.dashboard.BettingHistoryData.countIncreased?.toFixed(2),
        },
        totalPayout: {
          totalCount: data.dashboard.totalTransaction.totalCount?.toFixed(2),
          growthPercentage:
            data.dashboard.totalTransaction.countIncreased?.toFixed(2),
        },
        avgBetAmount: {
          totalCount: data.dashboard.avgBetAmount.totalCount?.toFixed(2),
          growthPercentage:
            data.dashboard.avgBetAmount.countIncreased?.toFixed(2),
        },
      });
    },
  });

  return (
    <Grid
      container
      className={`${classes.root} ${classes.mainGrid}`}
      spacing={0}
    >
      <Grid item>
        <Typography className={classes.header}>Overview</Typography>
        <Grid container item lg={12} spacing={2}>
          {stats.map((value) => (
            <Grid key={value.color} item lg={3}>
              <Paper
                className={classes.paper}
                style={{
                  backgroundColor: `${value.color}`,
                  borderRadius: "6px",
                }}
              >
                <Grid item className={classes.iconBox}>
                  <Typography
                    className={`${classes.icon} ${classes.backgroundTransparent}`}
                  >
                    <Icon>
                      <img alt="imageAlt" src={value.icon} />
                    </Icon>
                  </Typography>
                </Grid>
                <Grid item>
                  {value.numCount ? (
                    <Grid
                      item
                      className={`${classes.dataBox} ${classes.dFlex}`}
                    >
                      {/* { value.subtitle && <Typography>{value.subtitle}</Typography> } */}
                      <Grid item>
                        <Paper className={classes.dataValues}>
                          {value.numCount}
                        </Paper>
                      </Grid>
                      {/* <Grid item className={classes.dataGrid}>
                        {value.growth === null ? (
                          ""
                        ) : value.growth ? (
                          <Paper
                            className={`${classes.percantageValues} ${classes.perBox}`}
                          >
                            <Icon className={classes.perIcon}>
                              <img alt="imageAlt" src={ArrowUp} />
                            </Icon>
                            <Typography className={classes.arrow}>
                              {value.perCount}
                              {value.title === "Total Payout" ? "" : "%"}
                            </Typography>
                          </Paper>
                        ) : (
                          <Paper
                            className={`${classes.percantageValues} ${classes.negPerBox}`}
                          >
                            <Icon className={classes.negPerIcon}>
                              <img alt="imageAlt" src={ArrowDown} />
                            </Icon>
                            <Typography className={classes.arrow}>
                              {value.perCount}
                              {value.title === "Total Payout" ? "" : "%"}
                            </Typography>
                          </Paper>
                        )}
                      </Grid> */}
                    </Grid>
                  ) : (
                    <Grid item className={classes.mTop35}>
                      {/* <Paper className={classes.dataValues}>
                        {value.perCount}
                        {value.title === "Total Payout" ? "" : "%"}
                      </Paper> */}
                    </Grid>
                  )}
                  <Typography className={classes.titleBox}>
                    {value.title}
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
