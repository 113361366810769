// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GetInTouch .inputRounded .MuiOutlinedInput-root,
.emailVerify .inputRounded .MuiOutlinedInput-root {
  border-radius: 30px;
}
.GetInTouch .inputRounded,
.emailVerify .inputRounded {
  margin-bottom: 2vh !important;
  width: 100%;
}

.StepsContainer .inputRounded .MuiOutlinedInput-root {
  border-radius: 30px;
}
.StepsContainer .inputRounded {
  margin-bottom: 2vh !important;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/Modal/styles.scss"],"names":[],"mappings":"AAEE;;EACE,mBAAA;AAAJ;AAGE;;EACE,6BAAA;EACA,WAAA;AAAJ;;AAKE;EACE,mBAAA;AAFJ;AAKE;EACE,6BAAA;EACA,WAAA;AAHJ","sourcesContent":[".GetInTouch,\n.emailVerify {\n  .inputRounded .MuiOutlinedInput-root {\n    border-radius: 30px;\n  }\n\n  .inputRounded {\n    margin-bottom: 2vh !important;\n    width: 100%;\n  }\n}\n\n.StepsContainer {\n  .inputRounded .MuiOutlinedInput-root {\n    border-radius: 30px;\n  }\n\n  .inputRounded {\n    margin-bottom: 2vh !important;\n    width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
