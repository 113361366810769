/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import { useQuery, useLazyQuery } from '@apollo/client';

import Table from 'Components/Table/Table';
import { Loader } from 'Components/TableLoader';
import {
  GET_ALL_ADMINS, GET_NEXT_ADMINS_PAGE, GET_PREV_ADMINS_PAGE,
} from 'graphql/query/admin';
import { formStyles } from './AdminManagementStyles';

const AdminManagement = ({
  handleEditButtonClick, handleDeleteButtonClick, searchQuery, actionPerformed,
  setActionPerformed,
  filter,
  adminFilter,
  errorMsg,
  setErrorMsg,
}) => {
  const classes = formStyles();
  const [admins, setAdmins] = useState([]);
  const [pageInfo, setPageInfo] = useState('');
  const [page, setPage] = useState(0);
  const navigate = useNavigate();
  const {
    data, refetch, error, loading,
  } = useQuery(GET_ALL_ADMINS, {
    variables: {
      first: 10,
      orderBy: 'createdAt_DESC',
      filters: !filter ? {
        fullName: searchQuery.length === 0 ? '' : searchQuery,
      } : adminFilter,
    },
    fetchPolicy: 'network-only',
  });

  const [getNextPage] = useLazyQuery(GET_NEXT_ADMINS_PAGE);
  const [getPrevPage] = useLazyQuery(GET_PREV_ADMINS_PAGE);

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          first: 10,
          orderBy: 'createdAt_DESC',
          after: pageInfo.endCursor,
        },
        fetchPolicy: 'network-only',
      });

      if (nextPageData.data.admins) {
        const list = await nextPageData.data.admins.edges.map((admin) => admin.node);
        setAdmins(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.admins.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          first: 10,
          orderBy: 'createdAt_DESC',
          before: pageInfo.endCursor,
        },
        fetchPolicy: 'network-only',
      });

      if (prevPageData.data.admins) {
        const list = await prevPageData.data.admins.edges.map((admin) => admin.node);
        setAdmins(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.admins.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handleViewButton = (id) => {
    navigate('/admin/administrator/details', { state: { id } });
  };

  const handleEditButton = (id) => {
    handleEditButtonClick(id);
  };
  const handleDeleteButton = (id) => {
    handleDeleteButtonClick(id);
  };

  console.log(adminFilter);

  const setAllAdmins = async () => {
    // Display All Data
    if (data && data.admins) {
      const list = await data.admins.edges.map((admin) => admin.node);
      setPageInfo(data.admins.pageInfo);
      setAdmins(list);
      setErrorMsg('');
    }
    setPage(0);
  };

  useEffect(() => {
    if (actionPerformed) {
      refetch();
      setActionPerformed(false);
      setAllAdmins();
    }
    if (error) { setErrorMsg('Error in loading data !'); }

    setAllAdmins();
  }, [data, actionPerformed, error, searchQuery]);

  const TABLE_HEAD = [
    {
      id: 'id', flag: 'id', label: 'Sr.No', alignRight: false,
    },
    {
      id: 'isActive', flag: 'isActive', label: 'Status', alignRight: false,
    },
    {
      id: 'fullName', flag: 'fullName', label: 'Admin Name', alignRight: false,
    },
    {
      id: 'role', flag: 'adminRole', label: 'Admin Role', alignRight: false,
    },
    {
      id: 'email', flag: 'email', label: 'Admin Email', alignRight: false,
    },
    {
      id: 'actions',
      flag: { read: true, edit: true, remove: true },
      callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: 'Actions',
      alignRight: false,
    },
  ];

  if (loading) {
    return <Loader />;
  }

  if (admins && admins.length > 0) {
    return (
      <Box style={{ minHeight: '100vh' }}>
        { errorMsg.length > 0 && <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography> }

        <Table
          USERLIST={admins}
          TABLE_HEAD={TABLE_HEAD}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          page={page}
          setPage={setPage}
        />
      </Box>
    );
  }
  return (
    <Box style={{ minHeight: '70vh' }}>
      { errorMsg.length > 0 ? <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography>
        : <Typography variant="h5">No Data Found !</Typography> }
    </Box>
  );
};

export default AdminManagement;
