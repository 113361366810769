/* eslint-disable  */
export const AUTH_TOKEN = "auth-token";

import axios from "axios";
import { toast } from "react-toastify";
const uploadOnS3 = async (url, file) => {
  try {
    const response = await axios.put(url, file);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
export default uploadOnS3;

export const copyToClipboard = (text, message) => {
  navigator.clipboard
    .writeText(text)
    .then(() => {
      toast.success(message || "copied to clipboard!");
    })
    .catch((err) => {
      console.error("Failed to copy text: ", err);
    });
};
