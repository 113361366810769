/* eslint-disable import/prefer-default-export */
import { makeStyles } from "@material-ui/core/styles";

const topBarStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "25px",
    marginLeft: "20px",
    // marginBottom: '20px',
  },
  positionRelative: {
    position: "relative",
  },
  dFlex1: {
    display: "flex",
    justifyContent: "flex-start",
  },
  header1: {
    fontWeight: "bold",
    marginBottom: "20px",
    fontSize: "20px",
  },
  button: {
    width: "auto",
    height: "40px",
    marginRight: "20px",
    background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
    fontStyle: "normal",
    color: "white",
    marginLeft: "15px",
    fontWeight: 450,
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      width: "120px",
      padding: "10px 10px",
      fontSize: "10px",
    },
  },
  closeBtn: {
    marginTop: "2px",
    marginLeft: "10px",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  dFlex: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

export { topBarStyles };
