/* eslint-disable no-nested-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import { Box, Typography } from "@material-ui/core";
import {
  GET_ALL_STAKING_HISTORY,
  GET_NEXT_STAKING_HISTORY,
  GET_PREV_STAKING_HISTORY,
} from "graphql/query/admin";
import Table from "Components/Table/Table";
import { Loader } from "Components/TableLoader";
import { formStyles } from "./TableStyles";
import moment, { duration } from "moment";

const StakingHistoryTable = ({
  setErrorMsg,
  errorMsg,
  searchQuery,
  actionPerformed,
  setActionPerformed,
  filter,
  userFilter,
}) => {
  const classes = formStyles();
  const [stakingHistory, setStakingHistory] = useState([]);
  const [pageInfo, setPageInfo] = useState("");
  const [page, setPage] = useState(0);

  const { data, refetch, error, loading } = useQuery(GET_ALL_STAKING_HISTORY, {
    variables: {
      first: 10,
      orderBy: "createdAt_DESC",
      filters:
        searchQuery?.length === 0
          ? !filter
            ? {}
            : userFilter
          : { usernames: searchQuery },
    },
    fetchPolicy: "network-only",
  });

  const [getNextPage, { loading: nextPageLoader }] = useLazyQuery(
    GET_NEXT_STAKING_HISTORY
  );
  const [getPrevPage, { loading: prevPageLoader }] = useLazyQuery(
    GET_PREV_STAKING_HISTORY
  );

  const handleNextPage = async () => {
    if (pageInfo?.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          first: 10,
          after: pageInfo?.endCursor,
          orderBy: "createdAt_DESC",
          filters:
            searchQuery?.length === 0
              ? !filter
                ? {}
                : userFilter
              : { usernames: searchQuery },
        },
        fetchPolicy: "network-only",
      });

      if (nextPageData?.data?.getStakingHistory) {
        const list = nextPageData?.data?.getStakingHistory?.edges?.map(
          (item) => ({
            ...item?.node,
            username: item?.node?._user?.username,
            amount: Number(item?.node?.amount).toFixed(2),
            duration: String(item?.node?.duration).padStart(2, "0") + " months",
            formattedDate: moment(item?.node?.createdAt).format(
              "MMMM Do YYYY, HH:mm"
            ),
          })
        );
        setStakingHistory(list);
        setPage(page + 1);
        setPageInfo(nextPageData?.data?.getStakingHistory?.pageInfo);
        setErrorMsg("");
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo?.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          last: 10,
          before: pageInfo?.startCursor,
          orderBy: "createdAt_DESC",
          filters:
            searchQuery?.length === 0
              ? !filter
                ? {}
                : userFilter
              : { usernames: searchQuery },
        },
        fetchPolicy: "network-only",
      });

      if (prevPageData?.data?.getStakingHistory) {
        const list = prevPageData?.data?.getStakingHistory?.edges?.map(
          (item) => ({
            ...item?.node,
            username: item?.node?._user?.username,
            amount: Number(item?.node?.amount).toFixed(2),
            duration: String(item?.node?.duration).padStart(2, "0") + " months",
            formattedDate: moment(item?.node?.createdAt).format(
              "MMMM Do YYYY, HH:mm"
            ),
          })
        );
        setStakingHistory(list);
        setPage(page - 1);
        setPageInfo(prevPageData?.data?.getStakingHistory?.pageInfo);
        setErrorMsg("");
      }
    }
  };
  console.log("page", page);
  console.log("pageInfo", pageInfo);

  const setAllStakingHistory = async () => {
    if (data?.getStakingHistory) {
      const list = data?.getStakingHistory?.edges?.map((item) => ({
        ...item?.node,
        username: item?.node?._user?.username,
        amount: Number(item?.node?.amount).toFixed(2),
        duration: String(item?.node?.duration).padStart(2, "0") + " months",
        formattedDate: moment(item?.node?.createdAt).format(
          "MMMM Do YYYY, HH:mm"
        ),
      }));

      setPageInfo(data?.getStakingHistory?.pageInfo);
      setStakingHistory(list);
      setErrorMsg("");
    }
    setPage(0);
  };

  useEffect(() => {
    if (actionPerformed) {
      refetch();
      setActionPerformed(false);
      setAllStakingHistory();
    }
    if (error) {
      setErrorMsg("Error in loading data!");
    }

    setAllStakingHistory();
  }, [data, actionPerformed, error, searchQuery]);

  const TABLE_HEAD = [
    {
      id: "id",
      flag: "id",
      label: "Sr.No",
      alignRight: false,
    },
    {
      id: "txnHash",
      flag: "txnHash",
      label: "Transaction Hash",
      alignRight: false,
    },
    {
      id: "username",
      flag: "username",
      label: "Username",
      alignRight: false,
    },
    {
      id: "amount",
      flag: "amount",
      label: "Amount",
      alignRight: false,
    },
    {
      id: "duration",
      flag: "duration",
      label: "Stake Period",
      alignRight: false,
    },
    {
      id: "formattedDate",
      flag: "formattedDate",
      label: "Timestamp",
      alignRight: false,
    },
  ];

  if (loading || nextPageLoader || prevPageLoader) {
    return <Loader />;
  }
  if (stakingHistory && stakingHistory?.length > 0) {
    return (
      <Box className={classes.mainContainer}>
        <Table
          USERLIST={stakingHistory}
          page={page}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          TABLE_HEAD={TABLE_HEAD}
        />
      </Box>
    );
  }

  return (
    <div>
      <Box className={classes.mainContainer}>
        <h3>Staking History</h3>
        {errorMsg?.length > 0 ? (
          <Typography variant="h2" className={classes.errorText}>
            {errorMsg}
          </Typography>
        ) : (
          <Typography variant="h5">No Data Found!</Typography>
        )}
      </Box>
    </div>
  );
};

export default StakingHistoryTable;
