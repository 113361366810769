import * as token from "@solana/spl-token";
import { Buffer } from "buffer";
import { vaultMintDecimals } from "./config/vars";

export const fetchUserTokenAccount = async (connection, owner, mint) => {
  try {
    // Find associated token account
    const tokenAccount = await token.getAssociatedTokenAddress(
      mint, // Token mint address
      owner // Wallet public key
    );

    // Optional: Verify the token account exists
    const tokenAccountInfo = await connection.getAccountInfo(tokenAccount);
    if (tokenAccountInfo) {
      // If the account exists, get its balance
      const accountData = Buffer.from(tokenAccountInfo.data);
      const balance = accountData.readBigUInt64LE(64);

      // unknown has 6 decimal places
      console.log(balance, "👍");
      const unknownBalance = Number(balance) / 10 ** vaultMintDecimals;

      return { tokenAccount, unknownBalance };
    } else {
      // If the account doesn't exist, the balance is 0
      return { tokenAccount, unknownBalance: 0 };
    }
  } catch (error) {
    console.error("Error fetching token account:", error);
    throw error;
  }
};

export async function getTokenBalance(account, provider) {
  const balance = await provider.connection.getTokenAccountBalance(account);
  console.log(balance);
  return { vaultBalance: balance.value.uiAmount };
}

// Usage in a React component
