import React, { useState } from "react";
import Popover from "@material-ui/core/Popover";
import DateFnsUtils from "@date-io/date-fns";
import Button from "@material-ui/core/Button";
import { Box, Grid } from "@material-ui/core";
import Filter from "Assets/Svg/filter";
import ArrowDownIcon from "Assets/Svg/arrowdown";
import { ReactComponent as CloseIcon } from "Assets/Svg/closeMedium.svg";
import { useStyles } from "./FilterStyles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { startOfDay, endOfDay, isSameDay } from "date-fns";
import moment from "moment";

export default function SimplePopover({
  setFilter,
  setActionPerformed,
  setCategoryFilter,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [userFilters, setUserFilters] = useState({
    createdAtFrom: null,
    createdAtTo: null,
  });
  const [errors, setErrors] = useState({
    fromDate: false,
    toDate: false,
  });

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClose = () => {
    setAnchorEl(null);
    setErrors({ fromDate: false, toDate: false });
  };

  const clearFilters = () => {
    setUserFilters({
      createdAtFrom: null,
      createdAtTo: null,
    });
    setErrors({ fromDate: false, toDate: false });
    setFilter(false);
    setCategoryFilter(null);
    setActionPerformed(false);
    // Close the popover if it's open
    if (open) {
      handleClose();
    }
  };

  const validateDates = () => {
    const { createdAtFrom, createdAtTo } = userFilters;
    const newErrors = {
      fromDate: false,
      toDate: false,
    };

    if (!createdAtFrom && createdAtTo) {
      newErrors.fromDate = true;
    }
    if (createdAtFrom && !createdAtTo) {
      newErrors.toDate = true;
    }

    setErrors(newErrors);
    return !newErrors.fromDate && !newErrors.toDate;
  };

  const filterUser = () => {
    if (!validateDates()) {
      return;
    }

    const { createdAtFrom, createdAtTo } = userFilters;
    let filters = {};

    // Case 1: Both dates are the same - show entries for that specific date (full day)
    if (createdAtFrom && createdAtTo && isSameDay(createdAtFrom, createdAtTo)) {
      filters = {
        createdAtFrom: startOfDay(createdAtFrom).toISOString(),
        createdAtTo: endOfDay(createdAtFrom).toISOString(), // Using createdAtFrom to ensure same day
      };
    }
    // Case 2: Both dates are selected - show entries between these dates
    else if (createdAtFrom && createdAtTo) {
      filters = {
        createdAtFrom: startOfDay(createdAtFrom).toISOString(),
        createdAtTo: endOfDay(createdAtTo).toISOString(),
      };
    }

    if (Object.keys(filters).length > 0) {
      setFilter(true);
      setCategoryFilter(filters);
      setActionPerformed(true);
      handleClose();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFromDateChange = (date) => {
    setUserFilters((prev) => ({
      ...prev,
      createdAtFrom: moment(date).startOf("day"),
    }));
    setErrors((prev) => ({
      ...prev,
      fromDate: false,
    }));
  };

  const handleToDateChange = (date) => {
    setUserFilters((prev) => ({
      ...prev,
      createdAtTo: moment(date).endOf("day"),
    }));
    setErrors((prev) => ({
      ...prev,
      toDate: false,
    }));
  };

  const CustomFromInput = (props) => {
    const { value, onClick, onChange } = props;
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <input
          {...props}
          style={{
            width: "100%",
            height: "40px",
            padding: "8px",
            border: `1px solid ${errors.fromDate ? "#f44336" : "#CCCCCC"}`,
            borderRadius: "4px",
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            textAlign: "center",
            cursor: "pointer",
          }}
        />
        {!value && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "rgba(0, 0, 0, 0.54)",
              pointerEvents: "none",
            }}
          >
            From
          </div>
        )}
        {errors.fromDate && (
          <div
            style={{
              color: "#f44336",
              fontSize: "12px",
              marginTop: "4px",
              position: "absolute",
            }}
          >
            Required!
          </div>
        )}
      </div>
    );
  };

  const CustomToInput = (props) => {
    const { value, onClick, onChange } = props;
    return (
      <div style={{ position: "relative", width: "100%" }}>
        <input
          {...props}
          style={{
            width: "100%",
            height: "40px",
            padding: "8px",
            border: `1px solid ${errors.toDate ? "#f44336" : "#CCCCCC"}`,
            borderRadius: "4px",
            backgroundColor: "rgba(0, 0, 0, 0.02)",
            textAlign: "center",
            cursor: "pointer",
          }}
        />
        {!value && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "rgba(0, 0, 0, 0.54)",
              pointerEvents: "none",
            }}
          >
            To
          </div>
        )}
        {errors.toDate && (
          <div
            style={{
              color: "#f44336",
              fontSize: "12px",
              marginTop: "4px",
              position: "absolute",
            }}
          >
            Required!
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        startIcon={<Filter />}
        endIcon={<ArrowDownIcon />}
        className={classes.filterButton}
      >
        Filter
      </Button>
      {open && (
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{ paper: classes.popUp3 }}
          marginThreshold={20}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          anchorPosition={{ left: 20, top: 0 }}
        >
          <Box className={classes.popUp1}>
            <div className={classes.topBar} style={{ marginBottom: "20px" }}>
              <span
                role="button"
                tabIndex={0}
                onKeyDown={handleClose}
                onClick={handleClose}
                className={`${classes.onHover} ${classes.closeBtn}`}
              >
                <CloseIcon />
              </span>
            </div>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  marginBottom: "30px",
                  marginLeft: "10px",
                }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    autoOk
                    clearable
                    format="dd/MM/yyyy"
                    disableFuture
                    maxDate={userFilters.createdAtTo || undefined}
                    style={{
                      width: "45%",
                      marginRight: "10px",
                    }}
                    TextFieldComponent={CustomFromInput}
                    value={userFilters.createdAtFrom}
                    onChange={handleFromDateChange}
                    views={["year", "month", "date"]}
                  />
                  <DatePicker
                    autoOk
                    clearable
                    format="dd/MM/yyyy"
                    disableFuture
                    minDate={userFilters.createdAtFrom || undefined}
                    style={{
                      width: "45%",
                    }}
                    TextFieldComponent={CustomToInput}
                    value={userFilters.createdAtTo}
                    onChange={handleToDateChange}
                    views={["year", "month", "date"]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item>
                <Button
                  onClick={clearFilters}
                  variant="contained"
                  className={classes.buttonReset}
                  disabled={
                    !userFilters.createdAtFrom && !userFilters.createdAtTo
                  }
                >
                  Clear Filters
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={filterUser}
                  className={classes.buttonApply}
                  color="primary"
                  disabled={
                    !userFilters.createdAtFrom && !userFilters.createdAtTo
                  }
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Popover>
      )}
    </div>
  );
}
