/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { Formik, Form, Field, FieldArray } from "formik";
import { CircularProgress, Typography } from "@material-ui/core";
import { useMutation } from "@apollo/client";

import { UPDATE_VARIABLE } from "graphql/mutation/admin";
import { SwitchStyles1, useStyles } from "./ModalStyles";

const EditPlatformVariable = ({
  variables,
  editId,
  handleOnEditSuccess,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [updateVariable] = useMutation(UPDATE_VARIABLE);
  const classes = useStyles();
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await updateVariable({
        variables: {
          input: {
            variables: values.variables,
          },
          where: {
            id: editId,
          },
        },
      });

      const { status, message } = response.data.updateVariable;
      if (status === "error") {
        setErrorMsg(message);
      } else {
        handleOnEditSuccess();
        handleClose();
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      setErrorMsg("Something went wrong !");
    }
  };

  return (
    <div className={classes.platformBox}>
      <Formik
        initialValues={{
          variables,
        }}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        render={({ values, setFieldValue }) => (
          <Form>
            <FieldArray
              name="variables"
              render={(arrayHelpers) => (
                <div>
                  {values.variables && values.variables.length > 0 ? (
                    values.variables.map((friend, index) => (
                      <div className={classes.dFlex2}>
                        <SwitchStyles1
                          key="1"
                          name={`variables.${index}.isActive`}
                          checked={values.variables[index].isActive}
                          onChange={() =>
                            setFieldValue(
                              `variables.${index}.isActive`,
                              !values.variables[index].isActive
                            )
                          }
                          id={`variables.${index}.isActive`}
                        />
                        <Field
                          placeholder="Variable name"
                          className={classes.inputHeight2}
                          name={`variables.${index}.name`}
                          value={values.variables[index].name}
                        />
                        <Field
                          type="number"
                          placeholder="Value"
                          className={classes.inputHeight2}
                          style={{ width: "150px" }}
                          name={`variables.${index}.value`}
                          value={values.variables[index].value}
                        />
                        <button
                          className={classes.addButton}
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          -
                        </button>
                        <button
                          className={classes.addButton}
                          type="button"
                          onClick={() =>
                            arrayHelpers.insert(values.variables.length, "")
                          }
                        >
                          +
                        </button>
                      </div>
                    ))
                  ) : (
                    <button
                      className={classes.sendButton1}
                      type="button"
                      onClick={() => arrayHelpers.push("")}
                    >
                      {/* show this when user has removed all variables from the list */}
                      Add Variable
                    </button>
                  )}
                  {values.variables.length > 0 && (
                    <div className={classes.dFlex3}>
                      {errorMsg.length > 0 && (
                        <Typography
                          variant="subtitle1"
                          className={classes.errorText1}
                        >
                          {errorMsg}
                        </Typography>
                      )}
                      <button className={classes.sendButton1} type="submit">
                        {loading && (
                          <CircularProgress
                            size={20}
                            style={{ color: "white" }}
                          />
                        )}
                        {!loading && "Update"}
                      </button>
                    </div>
                  )}
                </div>
              )}
            />
          </Form>
        )}
      />
    </div>
  );
};

export default EditPlatformVariable;
