import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ALL_GAMES, GET_GAME_BY_ID } from "graphql/query/admin";
import { gameConfigStyles } from "../gameStyles";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Loader from "Components/Loader";
import { SwitchStyles1 } from "Modules/Admin/Administrator/administratorStyles";
import { UPDATE_GAME } from "graphql/mutation/admin";
import { toast } from "react-toastify";

// Validation schema for the form
const validationSchema = Yup.object().shape({
  betLimit: Yup.number().nullable(),
  payoutRatio: Yup.string()
    .matches(
      /^\d+[:/]\d+$/,
      "Payout ratio must be in the format 'x:y' or 'x/y'"
    ) // Allow ratios in the specified format
    .nullable(),
  isEnabled: Yup.boolean(),
  maintenanceStartTime: Yup.date().nullable(),
  maintenanceEndTime: Yup.date().nullable(),
});

// Helper function to format the timestamp
const formatTimestampToDateTimeLocal = (timestamp) => {
  const date = timestamp ? new Date(parseInt(timestamp, 10)) : new Date(); // Convert string to integer
  return date.toISOString().slice(0, 16); // Get YYYY-MM-DDTHH:MM format
};

const GameConfiguration = () => {
  const classes = gameConfigStyles();
  const { data, error, loading } = useQuery(GET_ALL_GAMES, {
    variables: { first: 10, orderBy: "createdAt_DESC" },
    fetchPolicy: "network-only",
  });

  const [updateGame] = useMutation(UPDATE_GAME);
  const [selectedGameId, setSelectedGameId] = useState(null);

  useEffect(() => {
    if (data?.getAllGames?.edges.length > 0) {
      setSelectedGameId(data.getAllGames.edges[0].node.id);
    }
  }, [data]);

  const {
    data: gameIdData,
    error: errorById,
    loading: loadingById,
  } = useQuery(GET_GAME_BY_ID, {
    variables: { getGameById: selectedGameId },
    skip: !selectedGameId,
    fetchPolicy: "network-only",
  });

  const handleSave = async (values) => {
    try {
      const response = await updateGame({
        variables: {
          updateGameId: selectedGameId,
          input: {
            betLimit: values.betLimit,
            payoutRatio: values.payoutRatio,
            isEnabled: values.isEnabled,
            maintenanceSchedule: {
              startTime: values.maintenanceStartTime,
              endTime: values.maintenanceEndTime,
            },
          },
        },
      });
      toast.success(
        response?.data?.updateGame?.message || "Game updated successfully!"
      );
    } catch (error) {
      console.error("Error updating game:", error);
      toast.error(error?.message || "Failed to update game. Please try again.");
    }
  };

  if (loading || loadingById) {
    return <Loader />;
  }

  if (error || errorById) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <div className={classes.gameConfig}>
        <div className={classes.gameConfigHeader}>
          {data?.getAllGames?.edges?.map((game) => (
            <div
              key={game.node.id}
              className={`${classes.gameConfigBox} ${
                selectedGameId === game.node.id ? classes.selectedGame : ""
              }`}
              onClick={() => setSelectedGameId(game.node.id)}
            >
              {game.node.name}
            </div>
          ))}
        </div>

        {gameIdData && (
          <Formik
            initialValues={{
              betLimit: gameIdData.getGameById?.betLimit || "",
              payoutRatio: gameIdData.getGameById?.payoutRatio || "",
              isEnabled: gameIdData.getGameById?.isEnabled,
              maintenanceStartTime: formatTimestampToDateTimeLocal(
                gameIdData.getGameById?.maintenanceSchedule?.startTime
              ),
              maintenanceEndTime: formatTimestampToDateTimeLocal(
                gameIdData.getGameById?.maintenanceSchedule?.endTime
              ),
            }}
            validationSchema={validationSchema}
            onSubmit={handleSave}
          >
            {({ setFieldValue, values, initialValues }) => {
              const hasChanges =
                JSON.stringify(values) !== JSON.stringify(initialValues);
              return (
                <Form className={classes.formContainer}>
                  <div className={classes.dflex}>
                    <label className={classes.label}>Is Enabled:</label>
                    <SwitchStyles1
                      name="isEnabled"
                      checked={values.isEnabled}
                      onChange={(e) =>
                        setFieldValue("isEnabled", e.target.checked)
                      }
                    />
                  </div>
                  <div className={classes.dflex}>
                    <label className={classes.label}>Bet Limit:</label>
                    <Field
                      className={`${classes.inputHeight}`}
                      type="number"
                      name="betLimit"
                    />
                  </div>
                  <div className={classes.dflex}>
                    <label className={classes.label}>Payout Ratio:</label>
                    <Field
                      className={`${classes.inputHeight}`}
                      type="text"
                      name="payoutRatio"
                      onInput={(e) => {
                        // Allow only digits, ':', and '/'
                        e.target.value = e.target.value.replace(
                          /[^0-9:/]/g,
                          ""
                        );
                      }}
                    />
                  </div>
                  <div className={classes.dflex}>
                    <label className={classes.label}>
                      Maintenance Start Time:
                    </label>
                    <Field
                      className={`${classes.inputHeight}`}
                      type="datetime-local"
                      name="maintenanceStartTime"
                    />
                  </div>
                  <div className={classes.dflex}>
                    <label className={classes.label}>
                      Maintenance End Time:
                    </label>
                    <Field
                      className={`${classes.inputHeight}`}
                      type="datetime-local"
                      name="maintenanceEndTime"
                    />
                  </div>
                  <button
                    type="submit"
                    className={`${
                      hasChanges ? classes.button : classes.disableButton
                    }`}
                    disabled={!hasChanges}
                  >
                    Save
                  </button>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </>
  );
};

export default GameConfiguration;
