import React, { useState, useEffect } from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { SolflareWalletAdapter } from "@solana/wallet-adapter-solflare";
// import { IDL } from "../../../betting/types/betting_system.js";
import { useApolloClient } from "@apollo/client";
import {
  Connection,
  Keypair,
  Transaction,
  TransactionInstruction,
} from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  connection,
  vaultMintDecimals,
  // getProgramPDAs,
  // PROGRAM_IDL,
} from "../../../betting/config";
import {
  ADMIN_ADDRESS,
  // BETTING_SYSTEM_PROGRAM_ID,
  UNKNOWNT_MINT,
  VAULT_ADDRESS,
} from "../../../betting/config/address";
// import { BETTING_STATE_ADDRESS } from "betting/config";
// import { Connection, clusterApiUrl, PublicKey } from "@solana/web3.js";
// import { AnchorProvider, Program } from "@coral-xyz/anchor";
import { fundsManaagement } from "./fundsStyle";
import {
  fetchUserTokenAccount,
  getTokenBalance,
} from "betting/usertokenAccount";
import {
  handleDeposit,
  handleSignTransaction,
} from "../../../betting/instructions/deposit";
// import { SIGN_TRANSACTION_MUTATION, DEPOSIT_MUTATION  } from "@/graphql/mutation/admin";

// @ts-ignore
import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { toast } from "react-toastify";
import handleWithdraw from "../../../betting/instructions/withdraw";
import { AnchorProvider } from "@coral-xyz/anchor";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { copyToClipboard } from "utils";

const { PublicKey } = require("@solana/web3.js");

const FundsManagement = () => {
  const classes = fundsManaagement();
  // Inside FundsManagement component, add:
  const client = useApolloClient();
  const [wallet, setWallet] = useState(null);

  const [balanceUpdated, setBalanceUpdated] = useState(false);
  const [depositAmount, setDepositAmount] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [recipientAddress, setRecipientAddress] = useState(
    "DARjcRLJ4pvr2QhJXNCa7n4BscWs8EZBmZXu9wN26N7M"
  );
  // const [userData, setUserData] = useState(null);

  const [walletState, setWalletState] = useState({
    isOpen: false,
    isAccountOpen: false,
    publicKey: null,
    balance: 0,
    solBalance: 0,
    vaultBalance: 0,
    loadingBalance: true,
  });
  // const network = "devnet";
  // const connection = new Connection(clusterApiUrl(network), "confirmed");

  // const admin = new PublicKey("J9VQ1xDqKBq52f18ne3SduwQBsTRZz24x1nGFjUA1Rwz");

  // useEffect(() => {
  //   const solflare = new SolflareWalletAdapter({ network: WalletAdapterNetwork.Devnet });
  //   console.log(solflare);

  //   setWallet(solflare);
  // }, []);

  useEffect(() => {
    const phantom = new PhantomWalletAdapter({
      network: WalletAdapterNetwork.Devnet,
    });
    console.log(phantom);

    setWallet(phantom);
  }, []);

  // useEffect(() => {
  //   const connection = new Connection('https://api.devnet.solana.com');
  //   const solflare = new SolflareWalletAdapter({ network: 'devnet' });

  //   solflare.on('connect', () => {
  //     console.log('Wallet connected:', solflare.publicKey.toBase58());
  //   });

  //   solflare.on('disconnect', () => {
  //     console.log('Wallet disconnected');
  //   });

  //   setWallet(solflare);

  //   return () => {
  //     solflare.disconnect(); // Cleanup wallet connection
  //   };
  // }, []);

  const handleConnectWallet = async () => {
    if (!wallet) return;

    try {
      await wallet.connect();
      const publicKey = wallet.publicKey;

      if (publicKey.toBase58() !== ADMIN_ADDRESS?.toBase58()) {
        toast.error("Only admin can connect to the wallet!");
        handleDisconnectWallet();
        return;
      }
      setWalletState((prev) => ({
        ...prev,
        publicKey,
        loadingBalance: true,
      }));
      // fetchBalance(publicKey);
    } catch (error) {
      console.error("Error connecting wallet:", error.message);
    }
  };

  const handleDisconnectWallet = async () => {
    if (!wallet) return;

    try {
      await wallet.disconnect();
      setWalletState({
        isOpen: false,
        isAccountOpen: false,
        publicKey: null,
        balance: 0,
        solBalance: 0,
        vaultBalance: 0,
        loadingBalance: true,
      });
      setDepositAmount(null);
      setWithdrawAmount(null);
    } catch (error) {
      console.error("Error disconnecting wallet:", error.message);
    }
  };

  useEffect(() => {
    if (wallet && walletState.publicKey !== null) {
      const provider = new AnchorProvider(connection, wallet, {});

      console.log(VAULT_ADDRESS.toBase58());
      getTokenBalance(VAULT_ADDRESS, provider).then(({ vaultBalance }) =>
        setWalletState((prev) => ({
          ...prev,
          vaultBalance,
          loadingBalance: false,
        }))
      );
    }
    setBalanceUpdated(false);
  }, [wallet, balanceUpdated, walletState.publicKey]);

  console.log("Vault Bal", walletState.vaultBalance);

  useEffect(() => {
    if (wallet && walletState.publicKey !== null) {
      fetchUserTokenAccount(
        connection,
        walletState.publicKey,
        UNKNOWNT_MINT
      ).then(({ unknownBalance }) =>
        setWalletState((prev) => ({
          ...prev,
          balance: unknownBalance,
          loadingBalance: false,
        }))
      );
      fetchBalance(walletState.publicKey);
    }
    setBalanceUpdated(false);
  }, [wallet, balanceUpdated, walletState.publicKey]);

  // Fetch balance of the connected wallet
  const fetchBalance = async (walletPublicKey) => {
    setWalletState((prev) => ({
      ...prev,
      loadingBalance: true,
    }));
    try {
      const balanceLamports = await connection.getBalance(walletPublicKey);
      setWalletState((prev) => ({
        ...prev,
        solBalance: balanceLamports / 10 ** vaultMintDecimals,
      })); // Convert lamports to SOL
    } catch (error) {
      console.error("Error fetching balance:", error.message);
    } finally {
      setWalletState((prev) => ({
        ...prev,
        loadingBalance: false,
      }));
    }
  };
  const handleDepositClick = async () => {
    if (!wallet || !walletState.publicKey) {
      toast.error("Please connect your wallet.");
      return;
    } else if (
      !depositAmount ||
      depositAmount <= 0 ||
      depositAmount > walletState.balance
    ) {
      toast.error("Please enter a valid deposit amount.");
      return;
    }

    try {
      // Step 1: Get instruction through handleDeposit
      const depositInput = {
        value: depositAmount,
      };
      const instruction = await handleDeposit(client, depositInput);
      console.log("Instruction :", instruction);

      // Step 2: Create and sign transaction
      const transaction = new Transaction();
      transaction.add(
        new TransactionInstruction({
          keys: instruction.key,
          programId: instruction.programId,
          data: instruction.data,
        })
      );
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      console.log("transaction", transaction);
      transaction.feePayer = wallet.publicKey;
      const signedTx = await wallet.signTransaction(transaction);
      console.log(signedTx, "------signedTx---------");
      console.log(wallet, "------wallet---------");

      const serializedTransaction = signedTx.serialize();
      const serializedTransactions = signedTx.serialize().toString("base64");

      console.log(serializedTransaction, "---serializedTransaction--------");
      console.log(serializedTransactions, "---serializedTransactions--------");

      const signedTransaction = {
        signedTransaction: serializedTransactions,
      };
      const result = await handleSignTransaction(client, signedTransaction);
      if (result.status === "success") {
        toast.success("Amount Deposited Successfully!");
        setBalanceUpdated(true);
      }
      console.log("Transaction result:", result);
    } catch (error) {
      console.log(error);
      toast.error("Transaction Failed!");
    }
  };

  const handleWithdrawClick = async () => {
    if (!wallet || !walletState.publicKey) {
      toast.error("Please connect your wallet.");
      return;
    } else if (
      !withdrawAmount ||
      withdrawAmount <= 0 ||
      withdrawAmount > walletState.balance
    ) {
      toast.error("Please enter a valid deposit amount.");
      return;
    }

    try {
      // Step 1: Get instruction through handleDeposit
      const withdrawInput = {
        value: withdrawAmount,

        recipientTokenAccount: recipientAddress,
      };
      const instruction = await handleWithdraw(client, withdrawInput);
      console.log("Instruction :", instruction);

      // Step 2: Create and sign transaction
      const transaction = new Transaction();
      transaction.add(
        new TransactionInstruction({
          keys: instruction.key,
          programId: instruction.programId,
          data: instruction.data,
        })
      );
      transaction.recentBlockhash = (
        await connection.getLatestBlockhash()
      ).blockhash;
      console.log("transaction", transaction);
      transaction.feePayer = wallet.publicKey;
      const signedTx = await wallet.signTransaction(transaction);
      console.log(signedTx, "------signedTx---------");
      console.log(wallet, "------wallet---------");
      // const simulation = await wallet.simulateTransaction(signedTx, {
      //   sigVerify: false,
      // });

      // if (simulation.value.err) {
      //   console.log(simulation);
      // }

      // console.log("Simulation successful:", {
      //   logs: simulation.value.logs,
      //   unitsConsumed: simulation.value.unitsConsumed,
      // });

      const serializedTransaction = signedTx.serialize();
      const serializedTransactions = signedTx.serialize().toString("base64");

      console.log(serializedTransaction, "---serializedTransaction--------");
      console.log(serializedTransactions, "---serializedTransactions--------");

      const signedTransaction = {
        signedTransaction: serializedTransactions,
      };
      const result = await handleSignTransaction(client, signedTransaction);
      if (result.status === "success") {
        toast.success("Amount Withdrawn Successfully!");
        setBalanceUpdated(true);
      }
      console.log("Transaction result:", result);
    } catch (error) {
      console.error("Withdraw failed:", error);
      toast.error("Transaction Failed!");
    }
  };

  const KeyValue = ({ label, value, loading }) => (
    <Box className={classes.keyValue}>
      <Typography style={{ color: "#616161" }}>{label}</Typography>
      <Typography variant="body1" style={{ fontWeight: 700 }}>
        {loading ? "Loading..." : value}
      </Typography>
    </Box>
  );

  return (
    <div className={classes.root}>
      <div>
        {!walletState.publicKey ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnectWallet}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "#FFFFFF" }}
            onClick={handleDisconnectWallet}
          >
            Disconnect Wallet
          </Button>
        )}
      </div>

      <Grid container>
        {walletState.publicKey ? (
          <Grid item xs={12}>
            <div className={`${classes.marginTop} ${classes.walletContainer}`}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                Wallet Address:
              </Typography>
              <Typography
                variant="body1"
                className={classes.dFlex}
                style={{
                  fontWeight: 700,
                  textDecoration: "underline !important",
                }}
              >
                {walletState.publicKey?.toBase58()}
                <div
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    copyToClipboard(
                      walletState.publicKey?.toBase58(),
                      "Wallet Address copied successfully!"
                    )
                  }
                >
                  <FileCopyIcon />
                </div>
              </Typography>
            </div>
            <Box className={classes.bothContainer}>
              <Box className={classes.txnContainer}>
                <KeyValue
                  label="Vault Balance:"
                  value={`${walletState.vaultBalance} TBET`}
                  loading={walletState.loadingBalance}
                />
                <Box>
                  <TextField
                    type="number"
                    label="Deposit Amount"
                    value={depositAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || Number(value) < 0) {
                        setDepositAmount(null);
                      } else {
                        setDepositAmount(Number(value));
                      }
                    }}
                    variant="outlined"
                    margin="normal"
                    placeholder="0"
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDepositClick}
                  disabled={!walletState.publicKey}
                >
                  Deposit {depositAmount} TBET
                </Button>

                <Box className={classes.marginTop}>
                  <TextField
                    type="number"
                    label="Withdraw Amount"
                    value={withdrawAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === "" || Number(value) < 0) {
                        setWithdrawAmount(null);
                      } else {
                        setWithdrawAmount(Number(value));
                      }
                    }}
                    variant="outlined"
                    margin="normal"
                    placeholder="0"
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleWithdrawClick}
                  disabled={!walletState.publicKey}
                >
                  Withdraw {withdrawAmount} TBET
                </Button>
              </Box>

              <Box className={classes.balanceContainer}>
                <KeyValue
                  label="TBET Balance:"
                  value={`${walletState.balance} TBET`}
                  loading={walletState.loadingBalance}
                />
                <KeyValue
                  label="SOL Balance:"
                  value={`${walletState.solBalance} SOL`}
                  loading={walletState.loadingBalance}
                />
              </Box>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography className={classes.marginTop} variant="h6">
              Connect your Phantom wallet to view and manage funds!
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FundsManagement;
