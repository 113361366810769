import React from 'react';

function UnderDevelopment() {
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <div style={{ height: '100vh', padding: '20px 15px 0', fontSize: '1.5rem' }}>
      Current Module is Under Development. Stay Tuned !
    </div>
  );
}

export default UnderDevelopment;
