/* eslint-disable */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable arrow-body-style */
import { Container, Grid, Typography, Button } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router'
import web3 from 'web3'
// import Box from '@material-ui/core/Box';
import { VIEW_COLLECTIBLE } from 'graphql/query/admin'
import { useNftMarketplaceContract, useUserAccount } from 'hooks'
import Avatar from 'Assets/Images/collectible.jpg'
import Default from 'Assets/Images/default.png'
// import { SwitchStyles } from '../../administratorStyles';
import SuccessModal from 'Components/Modal/SuccessModal'
import { Loader } from 'Components/TableLoader/index'
import ConfirmationModal from 'Components/Modal/ConfirmationModal'
import { useStyles } from './ViewMarketplaceStyles'
// import Topbar from './Components/Topbar/Topbar';
// import Table from './Components/Table/Table';

const ViewMarketplace = () => {
	// const [openModal,setModal] = React.useState(false);
	const classes = useStyles()
	const [openSuccessModal, setOpenSuccessModal] = React.useState(false)
	const [openSuccessModal1, setOpenSuccessModal1] = React.useState(false)
	const [disableConfirmation, setDisableConfirmation] = React.useState(false)
	const [disable, setDisable] = React.useState(false)
	const [nsfwConfirmation, setNsfwConfirmation] = React.useState(false)
	const [nsfw, setNsfw] = React.useState(false)
	const { state } = useLocation()
	const { id = '' } = state || ''
	const marketplaceContract = useNftMarketplaceContract()
	console.log(marketplaceContract)
	const { account } = useUserAccount()

	const { data, loading, error, refetch } = useQuery(VIEW_COLLECTIBLE, {
		variables: {
			itemId: id,
		},
		fetchPolicy: 'network-only',
	})

	const handleOnDisableSuccess = async () => {
		const tokenId = data?.item?.blockchainItemId
		const value = await web3.utils.toWei('100', 'ether')
		const response = await marketplaceContract.methods
			.blackListMarketItems(tokenId)
			.send({
				from: account,
				to: process.env
					.REACT_APP_NFT_MARKETPLACE_MAINNET_CONTRACT_ADDRESS,
			})
		console.log('XXXX', response)
		setOpenSuccessModal(true)
		setDisableConfirmation(true)
		setDisable(false)
	}
	const handleSuceessClose = () => {
		setOpenSuccessModal(false)
		setDisableConfirmation(false)
	}
	const handleDisableClose = () => {
		setDisable(false)
	}

	const handleOnDisableSuccess1 = () => {
		setOpenSuccessModal1(true)
		setNsfwConfirmation(true)
		setNsfw(false)
	}
	const handleSuceessClose1 = () => {
		setOpenSuccessModal(false)
		setNsfwConfirmation(false)
	}
	const handleDisableClose1 = () => {
		setNsfw(false)
	}

	if (loading) {
		return <Loader />
	}

	if (error) {
		return (
			<Container
				maxWidth={false}
				disableGutters
				className={classes.roleContainer}
			>
				<Typography variant="subtitle1" className={classes.error}>
					Something Went Wrong !
				</Typography>
			</Container>
		)
	}

	const { item } = data
	const {
		title,
		description,
		tokenId,
		mediaUrl,
		previewImage,
		collection,
		currentOwner,
		nftContractAddress,
		royalties,
		numberOfCopies,
		properties,
		tokenUri,
		isBlockchainVerified,
		blockchainCurrency,
		initialPrice,
		isUnlockable,
		unlockableContent,
		isAdultContent,
		isBlackListed,
		isActive,
		isDeleted,
		createdBy,
		status,
		blockchainItemId,
		isImportedNFT,
	} = item

	return (
		<Container maxWidth={false} disableGutters className={classes.app}>
			<Typography className={classes.path}>
				<Link to="/admin/collectible" className={classes.link}>
					Collectible
				</Link>
				&gt;
				<Typography className={classes.cursorPointer}>
					{' '}
					View Collectible{' '}
				</Typography>
			</Typography>
			<Grid container className={classes.profileHeadContainer}>
				<Grid lg={12} md={12} xs={12} item>
					<Grid container>
						<Grid lg={8} md={8} sm={8} item>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
									height: '60vh',
									marginTop: '30px',
								}}
							>
								<table className={classes.profileInfo}>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Collectible Name
										</td>
										<td className={classes.profileColumn}>
											{title}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Contract Address
										</td>
										<td
											className={classes.profileColumn}
											style={{
												color: 'blue',
												textDecoration: 'underline',
											}}
										>
											{nftContractAddress}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Creator Address
										</td>
										<td className={classes.profileColumn}>
											{createdBy
												? createdBy?.walletAddress
												: '---'}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Owner Address
										</td>
										<td className={classes.profileColumn}>
											{currentOwner
												? currentOwner?.walletAddress
												: '---'}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Description
										</td>
										<td className={classes.profileColumn}>
											{description}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Token Id
										</td>
										<td className={classes.profileColumn}>
											{tokenId}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Number of copies
										</td>
										<td className={classes.profileColumn}>
											{numberOfCopies}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Status
										</td>
										<td className={classes.profileColumn}>
											{status}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											Adult Content
										</td>
										<td className={classes.profileColumn}>
											{isAdultContent ? 'Yes' : 'No'}
										</td>
									</tr>
									<tr className={classes.profileRow}>
										<td className={classes.profileColumn1}>
											{' '}
											Is imported ?
										</td>
										<td className={classes.profileColumn}>
											{isImportedNFT ? 'Yes' : 'No'}
										</td>
									</tr>
									{/* <tr className={classes.profileRow}>
                    <td className={classes.profileColumn1}>Expiry</td>
                    <td className={classes.profileColumn}>15/07/2021 10:00 A.M</td>
                  </tr> */}
								</table>
							</div>
						</Grid>
						<Grid lg={3} md={3} sm={3} item>
							{/* <Button color="primary" onClick={() => setNsfw(true)} size="small" variant="outlined" style={{ width: '120px', height: '40px', background: 'white', marginRight: '20px', fontWeight: 'bold', marginLeft: '-30px', marginTop: '-50px' }}>Mark as NSFW</Button> */}
							{!isBlackListed && blockchainItemId?.length > 0 && (
								<Button
									color="primary"
									onClick={() => setDisable(true)}
									size="small"
									variant="contained"
									style={{
										width: '120px',
										height: '40px',
										background:
											'linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)',
										fontWeight: 'bold',
										marginTop: '-50px',
									}}
								>
									Blacklist NFT
								</Button>
							)}
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									width: '100%',
									height: '100%',
									marginLeft: '0px',
									marginTop: '-10px',
									justifyContent: 'center',
								}}
							>
								<img
									className={classes.avatar}
									onError={(e) => (e.target.src = Default)}
									src={mediaUrl}
									alt="image1"
								/>
							</div>
						</Grid>
						<Grid lg={1} md={1} sm={1} item />
					</Grid>
				</Grid>
			</Grid>
			{/* <Topbar /> */}
			{/* <Box style={{ paddingRight: '40px' }}>
        <Table />
      </Box> */}
			{disableConfirmation && (
				<SuccessModal
					handleClose={handleSuceessClose}
					open={openSuccessModal}
					heading="Blacklist NFT Confirmation"
					subtitle="NFT Blacklisted"
					type="delete"
				/>
			)}
			{disable && (
				<ConfirmationModal
					handleClose={handleDisableClose}
					open={handleDisableClose}
					heading="Blacklist NFT"
					subtitle="Are you sure you want to blacklist this NFT? This NFT will no longer be displayed on the platform."
					button1="Cancel"
					button2="Confirm"
					onButton1Click={handleDisableClose}
					onButton2Click={handleOnDisableSuccess}
				/>
			)}
			{nsfwConfirmation && (
				<SuccessModal
					handleClose={handleSuceessClose1}
					open={openSuccessModal1}
					heading="Mark as NSFW Confirmation"
					subtitle="NFT marked as NSFW"
					type="delete"
				/>
			)}
			{nsfw && (
				<ConfirmationModal
					handleClose={handleDisableClose1}
					open={handleDisableClose1}
					heading="Mark as NSFW"
					subtitle="Are you sure you want to mark Stephen Curry Chase Center as NSFW? This NFT will no longer be displayed to the user below the age of 18."
					button1="Cancel"
					button2="Confirm"
					onButton1Click={handleDisableClose1}
					onButton2Click={handleOnDisableSuccess1}
				/>
			)}
		</Container>
	)
}
export default ViewMarketplace
