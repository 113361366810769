import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";

import { tabStyles } from "../../styles";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { GET_ALL_CMS, GET_CMS_BY_TAB } from "graphql/query/admin";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CMS } from "graphql/mutation/admin";
import { toast } from "react-toastify";
import Loader from "Components/Loader";

function Aboutus() {
  const classes = tabStyles();
  const initialText = "<h2>About us content here</h2>";
  const contentBlock = htmlToDraft(initialText);
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  );
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState)
  );
  const [content, setContent] = useState("");

  const { data, loading, error, refetch } = useQuery(GET_CMS_BY_TAB, {
    variables: { tab: "aboutUs" },
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.getCmsByTab) {
        const cmsData = data.getCmsByTab[0];
        setContent(cmsData.content);
        const contentBlock = htmlToDraft(cmsData.content);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setEditorState(EditorState.createWithContent(contentState));
      }
    },
  });

  const [updateCms] = useMutation(UPDATE_CMS, {
    onCompleted: async (data) => {
      toast.success(
        `${data?.updateCms?.message}` || "About Us updated successfully!"
      );
      await refetch();
    },
    onError: (error) => {
      console.error(error);
      toast.error(`${error.message}` || "Failed to update About Us.");
    },
  });

  const submitData = () => {
    const input = {
      title: "About Us",
      content,
      tab: "aboutUs",
    };
    updateCms({ variables: { input, updateCmsId: data.getCmsByTab[0].id } });
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <div style={{ height: "auto", padding: "20px 15px 0", fontSize: "1.5rem" }}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        toolbar={{
          options: [
            "inline",
            "blockType",
            "fontSize",
            "list",
            "textAlign",
            "history",
            "embedded",
            "emoji",
            "image",
          ],
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
        }}
        editorStyle={{
          height: "auto",
          // border: '1px solid grey',
          lineHeight: "auto",
        }}
        onEditorStateChange={(newState) => {
          setEditorState(newState);
          setContent(draftToHtml(convertToRaw(newState.getCurrentContent())));
        }}
      />
      <Button
        onClick={submitData}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        className={classes.submitBtn}
      >
        Submit
      </Button>
    </div>
  );
}

export default Aboutus;
