import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Dialog,
  DialogContent,
  Box,
  FormControl,
  FormHelperText,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { useMutation } from "@apollo/client";
import Select from "react-select";
import CountryList from "country-list-with-dial-code-and-flag";
import { UPDATE_NUMBER } from "../../graphql/mutation/admin";
import OtpModal from "./VerifyOtp";
import { useStyles } from "./ModalStyles";
import { ReactComponent as CloseIcon } from "../../Assets/Svg/close.svg";
import "./styles.scss";

const validationSchema = yup.object({
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Must be only digits"),
  countryCode: yup
    .string("Select the Country code")
    .required("Country code is required"),
});

const CreateProfileVerificationModal = ({
  id,
  open,
  handleClose,
  heading,
  button1,
  setActionPerformed,
}) => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [UpdateNumber] = useMutation(UPDATE_NUMBER);

  const handleVerification = async (data, resetForm) => {
    try {
      setLoading(true);
      const response = await UpdateNumber({
        variables: {
          input: data,
        },
      });
      const { status, message } = response.data.updateMobile;
      if (status === "error") {
        setErrorMsg(message);
      } else {
        setFormData(data);
        handleClose();
        setOpenModal(true);
        resetForm();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setErrorMsg("Something went wrong !");
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Format country data for react-select
  const countryOptions = CountryList.getAll().map((country) => ({
    value: country.dial_code,
    label: ` ${country.dial_code} (${country.code})`,
  }));

  const initialCountryCode =
    countryOptions.find((country) => country.value === "+1")?.value || "+1";

  const formik = useFormik({
    initialValues: {
      mobile: "",
      countryCode: initialCountryCode,
      grantType: "UPDATE",
      id,
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      handleVerification(values, resetForm);
    },
  });

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        classes={{ paper: classes.dialogPaper1 }}
      >
        <DialogContent classes={{ root: classes.dialogContext }}>
          <div
            role="button"
            tabIndex={0}
            onKeyDown={handleClose}
            onClick={handleClose}
            className={classes.topBar}
          >
            <span className={classes.title}>{heading}</span>
            <span className={`${classes.onHover} ${classes.closeBtn}`}>
              <CloseIcon />
            </span>
          </div>
          <Box className={classes.phoneContainer} id="alert-dialog-description">
            <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
              <Grid
                container
                style={{ marginTop: "5vh", justifyContent: "center" }}
              >
                <Box
                  className={classes.dFlex}
                  style={{
                    justifyContent: "center !important",
                    allignItems: "center !important",
                  }}
                >
                  <Box>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                    >
                      <Select
                        id="countryCode"
                        name="countryCode"
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: "50px",
                            width: "125px",
                            marginRight: "3px",
                            zIndex: 100,
                          }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        value={countryOptions.find(
                          (option) => option.value === formik.values.countryCode
                        )}
                        onChange={(option) =>
                          formik.setFieldValue("countryCode", option.value)
                        }
                        options={countryOptions}
                        classNamePrefix="country-select"
                      />
                      <FormHelperText style={{ color: "red" }}>
                        {formik.touched.countryCode &&
                          formik.errors.countryCode}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                  <TextField
                    id="mobile"
                    name="mobile"
                    placeholder="Admin Mobile Number"
                    variant="outlined"
                    className={classes.width1001}
                    value={formik.values.mobile}
                    onChange={formik.handleChange}
                    style={{ marginTop: "0px !important" }}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.mobile && Boolean(formik.errors.mobile)
                    }
                    helperText={formik.touched.mobile && formik.errors.mobile}
                    InputProps={{
                      classes: {
                        input: classes.placeHolder,
                      },
                      className: classes.inputHeight1,
                    }}
                  />
                </Box>
                {errorMsg.length > 0 && (
                  <Typography variant="subtitle1" className={classes.errorText}>
                    {errorMsg}
                  </Typography>
                )}
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: "4vh",
                  marginBottom: "40px",
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    width: "155px",
                    height: "40px",
                    fontWeight: "550",
                    border: "none",
                    color: "white",
                    boxShadow: "none",
                    fontSize: "14px",
                    borderRadius: "5px",
                  }}
                  type="submit"
                  className={classes.sendButton}
                >
                  {loading && (
                    <CircularProgress size={20} style={{ color: "white" }} />
                  )}
                  {!loading && button1}
                </Button>
              </Grid>
            </form>
          </Box>
        </DialogContent>
      </Dialog>
      <OtpModal
        data={formData}
        setActionPerformed={setActionPerformed}
        open={openModal}
        handleClose={handleCloseModal}
        type="update"
      />
    </>
  );
};

export default CreateProfileVerificationModal;
