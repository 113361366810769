// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-pick1 .MuiInputLabel-formControl {
  top: 3px;
  left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/Modules/Admin/Administrator/Components/TopBar/Filter/style.scss"],"names":[],"mappings":"AACI;EACI,QAAA;EACA,SAAA;AAAR","sourcesContent":[".date-pick1 {\n    .MuiInputLabel-formControl {\n        top: 3px;\n        left: 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
