import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";

import { useStyles } from "./styles";
import Table from "./Components/Table/Table";
import Button from "./Components/Button/Button";
import FeatureTable from "./Components/Table/FeatureTable";

const Platform = () => {
  const classes = useStyles();
  const [editFlag, setEditFlag] = useState(false);
  const [editFlag1, setEditFlag1] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [actionPerformed, setActionPerformed] = useState(false);

  // const onCreateClick = () => {
  //   setEditFlag(true);
  // };

  const onCreateClick = () => {
    setEditFlag1(true);
  };

  return (
    <Box>
      <Box className={classes.paddingAll}>
        <Box className={classes.dFlex}>
          <Typography variant="h5" className={classes.header1}>
            Platform Variables
          </Typography>
          <Button
            title="Edit Platform Variables"
            onCreateClick={onCreateClick}
          />
        </Box>
        <Table
          editFlag={editFlag1}
          setEditFlag={setEditFlag1}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          actionPerformed={actionPerformed}
          setActionPerformed={setActionPerformed}
        />
        {/* <Box className={classes.dFlex} style={{ marginTop: "30px" }}>
          <Typography variant="h5" className={classes.header1}>
            Featured Collections
          </Typography>
          <Button
            title="Add Featured Collection"
            onCreateClick={onCreateClick}
          />
        </Box>
        <FeatureTable
          errorMsg={errorMsg}
          editFlag={editFlag}
          setEditFlag={setEditFlag}
          setErrorMsg={setErrorMsg}
          actionPerformed={actionPerformed}
          setActionPerformed={setActionPerformed}
        /> */}
      </Box>
    </Box>
  );
};

export default Platform;
