import React, { useState } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';

import SuccessModal from 'Components/Modal/SuccessModal';
import CreateContract from 'Components/Form/Create';
import { useUserAccount } from 'hooks';
import { useStyles } from './styles';
import Topbar from './Components/Topbar/Topbar';
import Table from './Components/Table/Table';
import CreateContractForm from './Components/Form/CreateContractForm';

const ContractManagement = () => {
  const classes = useStyles();
  const { account } = useUserAccount();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [actionPerformed, setActionPerformed] = useState(false);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => () => {
    setState({ ...state, [anchor]: open });
  };

  const handleSuceessClose = () => {
    setOpenSuccessModal(false);
  };

  const handleOnCreateSuccess = () => {
    setOpenSuccessModal(true);
    setActionPerformed(true);
    setTimeout(() => {
      setOpenSuccessModal(false);
    }, 2000);
  };

  const disableEdit = () => {};

  if (account) {
    return (
      <Box>
        <Topbar onButtonClick={toggleDrawer} title="Add Contract Address" />
        <Box className={classes.paddingAll}>
          <Typography variant="h5" className={classes.header1}>Whitelisted Contracts</Typography>
          <Table
            actionPerformed={actionPerformed}
            setActionPerformed={setActionPerformed}
          />
          <CreateContract state={state} disableEdit={disableEdit} header="Add Contract Address" toggleDrawer={toggleDrawer}>
            <CreateContractForm
              handleOnSuccess={handleOnCreateSuccess}
              toggleDrawer={toggleDrawer}
            />
          </CreateContract>
        </Box>
        <SuccessModal handleClose={handleSuceessClose} open={openSuccessModal} heading="Contract Created" subtitle="New contract created successfully" type="create" />
      </Box>
    );
  }

  return (
    <Grid container className={classes.mainContainer}>
      <Box style={{ marginLeft: 'auto', marginRight: 'auto' }}>
        <Typography className={classes.header3}>Please connect wallet to continue</Typography>
      </Box>
    </Grid>
  );
};

export default ContractManagement;
