import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import { announcementStyles } from "./announcementStyles";
import Topbar from "./Components/TopBar/TopBar";
import Table from "./Components/Table/Table";
import AnnouncementModal from "Components/Modal/AnnouncementModal";
import { useApolloClient, useQuery } from "@apollo/client";
import { ADMIN_PROFILE } from "graphql/query/admin";
import { ADMIN_ADDRESS } from "../../../betting/config/address";
import { toast } from "react-toastify";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import { PhantomWalletAdapter } from "@solana/wallet-adapter-wallets";
import { copyToClipboard } from "utils";

const AnnouncementManagement = () => {
  const classes = announcementStyles();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState(false);
  const [userFilter, setUserFilter] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("create");

  const client = useApolloClient();
  const [wallet, setWallet] = useState(null);

  const [balanceUpdated, setBalanceUpdated] = useState(false);
  const [depositAmount, setDepositAmount] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState(null);
  const [recipientAddress, setRecipientAddress] = useState(
    "DARjcRLJ4pvr2QhJXNCa7n4BscWs8EZBmZXu9wN26N7M"
  );
  // const [userData, setUserData] = useState(null);

  const [walletState, setWalletState] = useState({
    isOpen: false,
    isAccountOpen: false,
    publicKey: null,
    balance: 0,
    solBalance: 0,
    vaultBalance: 0,
    loadingBalance: true,
  });
  // const network = "devnet";
  // const connection = new Connection(clusterApiUrl(network), "confirmed");

  // const admin = new PublicKey("J9VQ1xDqKBq52f18ne3SduwQBsTRZz24x1nGFjUA1Rwz");

  // useEffect(() => {
  //   const solflare = new SolflareWalletAdapter({ network: WalletAdapterNetwork.Devnet });
  //   console.log(solflare);

  //   setWallet(solflare);
  // }, []);

  useEffect(() => {
    const phantom = new PhantomWalletAdapter({
      network: WalletAdapterNetwork.Devnet,
    });
    console.log(phantom);

    setWallet(phantom);
  }, []);

  const handleConnectWallet = async () => {
    if (!wallet) return;

    try {
      await wallet.connect();
      const publicKey = wallet.publicKey;

      if (publicKey.toBase58() !== ADMIN_ADDRESS?.toBase58()) {
        toast.error("Only admin can connect to the wallet!");
        handleDisconnectWallet();
        return;
      }
      setWalletState((prev) => ({
        ...prev,
        publicKey,
        loadingBalance: true,
      }));
      // fetchBalance(publicKey);
    } catch (error) {
      console.error("Error connecting wallet:", error.message);
    }
  };

  const handleDisconnectWallet = async () => {
    if (!wallet) return;

    try {
      await wallet.disconnect();
      setWalletState({
        isOpen: false,
        isAccountOpen: false,
        publicKey: null,
        balance: 0,
        solBalance: 0,
        vaultBalance: 0,
        loadingBalance: true,
      });
      setDepositAmount(null);
      setWithdrawAmount(null);
    } catch (error) {
      console.error("Error disconnecting wallet:", error.message);
    }
  };

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
    refetch: profileRefetch,
  } = useQuery(ADMIN_PROFILE, {
    fetchPolicy: "network-only",
  });

  const canUpdateAnnouncement =
    profileData?.me?.permissions?.Announcement?.UPDATE ?? false;

  const clearFilters = () => {
    setUserFilter(undefined);
    setFilter(false);
    setSearchQuery("");
  };

  return (
    <div className={classes.root}>
      {/* <div style={{ marginLeft: "20px" }}>
        {!walletState.publicKey ? (
          <Button
            variant="contained"
            color="primary"
            onClick={handleConnectWallet}
            style={{ width: "148px", height: "40px" }}
          >
            Connect Wallet
          </Button>
        ) : (
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "#FFFFFF" }}
            onClick={handleDisconnectWallet}
          >
            Disconnect Wallet
          </Button>
        )}
      </div> */}
      <div className={`${classes.marginTop} ${classes.walletContainer}`}>
        {walletState.publicKey && (
          <>
            <Typography
              variant="h6"
              style={{
                fontWeight: 700,
                marginTop: "20px",
                marginLeft: "20px",
              }}
            >
              Wallet Address:
            </Typography>
            <Typography
              variant="body1"
              className={classes.dFlex}
              style={{
                fontWeight: 700,
                textDecoration: "underline !important",
                marginLeft: "20px",
              }}
            >
              {walletState.publicKey?.toBase58()}
              <span
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  marginTop: "5px",
                }}
                onClick={() =>
                  copyToClipboard(
                    walletState.publicKey?.toBase58(),
                    "Wallet Address copied successfully!"
                  )
                }
              >
                <FileCopyIcon />
              </span>
            </Typography>
          </>
        )}
      </div>
      <Box>
        <Topbar
          title={canUpdateAnnouncement ? "Create Announcement" : ""}
          onButtonClick=""
          userFilter={userFilter}
          setActionPerformed={setActionPerformed}
          setUserFilter={setUserFilter}
          setFilter={setFilter}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <Box className={classes.paddingAll}>
          <h1 style={{ paddingTop: "5px" }}>Staking History</h1>
          <hr />
          <Table
            userFilter={userFilter}
            filter={filter}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg}
            actionPerformed={actionPerformed}
            setActionPerformed={setActionPerformed}
            canUpdateAnnouncement={canUpdateAnnouncement}
            searchQuery={searchQuery}
          />
        </Box>
        {openModal && (
          <AnnouncementModal
            open={openModal}
            setActionPerformed={setActionPerformed}
            mode={mode}
            handleClose={() => setOpenModal(false)}
            heading="Click"
            handleOnEditSuccess={() => {
              setOpenModal(false);
            }}
          />
        )}
      </Box>
    </div>
  );
};
export default AnnouncementManagement;
