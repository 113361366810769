import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useMutation } from "@apollo/client";
import { UPDATE_REVENUE_TRACKING } from "graphql/mutation/admin";
import { Typography } from "@material-ui/core";
import { statCardStyles } from "../../dashboardStyles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      // text: "Revenue Tracking",
    },
  },
};

// The month labels (same as before)
const labels = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function Graph() {
  const [updateRevenueTrackingDashboard] = useMutation(UPDATE_REVENUE_TRACKING);

  // State to store chart data
  const [chartData, setChartData] = useState({
    totalPayout: Array(12).fill(0), // Total Payout for each month
    adminProfit: Array(12).fill(0), // Admin Profit for each month
  });

  useEffect(() => {
    const updateRevenueTracking = async () => {
      try {
        const { data } = await updateRevenueTrackingDashboard({
          variables: {
            input: {
              interval: "yearly",
            },
          },
        });

        // Extract the revenue data for each month (data1 to data12)
        const monthlyData =
          data.revenueTrackingDashboard.totalRevenueBettingHistory[0].arrMonth;

        // Prepare the data for chart (initialize with zeros)
        const totalPayoutData = Array(12).fill(0); // initialize all with zero
        const adminProfitData = Array(12).fill(0); // initialize all with zero

        // Define a map for converting month names to label indices
        const monthNameToIndex = {
          Jan: 0,
          Feb: 1,
          Mar: 2,
          Apr: 3,
          May: 4,
          Jun: 5,
          Jul: 6,
          Aug: 7,
          Sep: 8,
          Oct: 9,
          Nov: 10,
          Dec: 11,
        };

        // Iterate over the data to populate the chart data
        Object.values(monthlyData).forEach((monthData) => {
          if (monthData && monthData.datetime) {
            // Extract the month name from the datetime
            const month = monthData.datetime.split("-")[2]; // "2024-01-Dec" -> "Dec"
            const monthIndex = monthNameToIndex[month]; // Map to index

            const monthValues = monthData.value;

            // Ensure the month exists and is a valid index
            if (monthIndex !== undefined && monthValues) {
              // Set the data for the correct month index
              totalPayoutData[monthIndex] = monthValues.totalAmount || 0;
              adminProfitData[monthIndex] = monthValues.adminProfit || 0;
            }
          }
        });

        // Update the state with the chart data
        setChartData({
          totalPayout: totalPayoutData,
          adminProfit: adminProfitData,
        });
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    updateRevenueTracking();
  }, [updateRevenueTrackingDashboard]);

  const data = {
    labels, // Month labels
    datasets: [
      {
        label: "Total Bet Amount",
        data: chartData.totalPayout,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Admin Profit",
        data: chartData.adminProfit,
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
    ],
  };

  const classes = statCardStyles();

  return (
    <>
      <Typography
        className={`${classes.headerGraph} ${classes.mainGrid} ${classes.marginTop}`}
      >
        Revenue Tracking
      </Typography>
      <div
        style={{ height: "auto", padding: "20px 15px 0", fontSize: "1.5rem" }}
      >
        <Line options={options} data={data} />
      </div>
    </>
  );
}

export default Graph;
