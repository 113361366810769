import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import { useMutation } from "@apollo/client";
import OtpInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router";

import { VERIFY_SECURITY_CODE } from "graphql/mutation/register/index";
import { useStyles } from "./LoginStyles";
import { toast } from "react-toastify";
import AdminLogo from "../../../Assets/Images/140.png";

const VerifySecurityCode = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [otp, setOTP] = useState("");
  const [loader, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [VerifyCode] = useMutation(VERIFY_SECURITY_CODE);

  const { state } = useLocation();
  const { email = "", password = "" } = state || "";

  const handleChange = (otpString) => {
    setOTP(otpString);
  };

  const handleSubmit = async () => {
    try {
      setErrorMsg("");
      if (otp.length < 6) setErrorMsg("Please Enter Valid OTP");
      else {
        setLoading(true);

        // Verify security code api call
        const { data } = await VerifyCode({
          variables: {
            input: {
              email,
              password,
              grantType: "googleAuth",
              code: otp,
            },
          },
        });

        const { token, message } = data.signIn;

        // Verification success
        if (token) {
          await localStorage.setItem("auth-token", token);
          navigate("/admin/dashboard");
          toast.success(message || "Login Successfully");
          setLoading(false);
        }
      }
    } catch (err) {
      console.log(err);
      setErrorMsg("Wrong Security Code");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state === null) navigate("/login");
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleSubmit();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <Box
        style={{
          marginRight: "50px",
          background: "white",
          padding: "10px 10px 0px 10px",
          borderRadius: "50px",
        }}
      >
        <img
          src={AdminLogo}
          alt="logo"
          // width={200} height={200}
        />
      </Box>
      <Container maxWidth={false} disableGutters className={classes.app}>
        <Grid container className={classes.loginBox1}>
          <form
            onSubmit={(e) => {
              handleSubmit();
              e.preventDefault();
            }}
          >
            <Grid item className={`${classes.displayFlex} ${classes.width100}`}>
              <Typography variant="h6" className={classes.heading1}>
                {" "}
                Trust
              </Typography>
              <Typography className={classes.heading2}>BET ON-CHAIN</Typography>
            </Grid>
            <Grid item className={`${classes.displayFlex} ${classes.width100}`}>
              <Typography variant="h6" className={classes.heading3}>
                Verify security code
              </Typography>
            </Grid>
            <Grid
              item
              className={`${classes.displayFlex} ${classes.width100}`}
              // style={{
              //   marginTop: "15px !important",
              //   width: "100%",
              //   justifyContent: "space-between !important",
              // }}
            >
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                // renderSeparator={<span>-</span>}
                renderInput={(props) => (
                  <input {...props} className={classes.otpInput} />
                )}
                separator={false}
                // inputStyle={classes.otpInput1}
                // isInputNum
              />
            </Grid>
            {errorMsg.length > 0 && (
              <Typography variant="subtitle1" className={classes.errorText}>
                {errorMsg}
              </Typography>
            )}
            <Button
              color="primary"
              variant="contained"
              disabled={loader}
              fullWidth
              type="submit"
              className={classes.loginBtn}
              style={{ marginTop: "15px" }}
              onClick={() => handleSubmit()}
            >
              {loader && <CircularProgress size={20} />}
              {!loader && "Proceed"}
            </Button>
          </form>
        </Grid>
      </Container>
    </>
  );
};

export default VerifySecurityCode;
