import { WITHDRAW_MUTATION } from "../../graphql/mutation/admin";

import { deserializeInstruction } from "./deposit";

async function handleWithdraw(client, withdrawInput) {
  try {
    // Execute the mutation
    const { data } = await client.mutate({
      mutation: WITHDRAW_MUTATION,
      variables: {
        input: withdrawInput,
      },
    });
    console.log(data);

    if (!data?.withdraw?.instruction) {
      throw new Error("No instruction received from withdraw mutation");
    }

    // Deserialize the instruction
    const instruction = deserializeInstruction(data.withdraw.instruction);

    console.log("Instruction ", instruction);

    return instruction;
  } catch (error) {
    console.error("Error processing deposit:", error);
    throw error;
  }
}

export default handleWithdraw;
