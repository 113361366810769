/* eslint-disable react/jsx-props-no-spreading */
import { Button, Container, Grid, Typography, Box } from "@material-ui/core";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";

import { labels } from "_mocks/permissonLabels";
import { Loader } from "Components/TableLoader/index";
import SuccessModal from "Components/Modal/SuccessModal";
import ConfirmationModal from "Components/Modal/ConfirmationModal";
import { VIEW_ADMIN } from "graphql/query/admin";
import { DISABLE_ADMIN, ENABLE_ADMIN } from "graphql/mutation/admin";
import Switch from "../Switch/Switch";
import { useStyles } from "./AdminDetailsStyle";

const AdminDetails = () => {
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [disableConfirmation, setDisableConfirmation] = React.useState(false);
  const [enableConfirmation, setEnableConfirmation] = React.useState(false);
  const [disable, setDisable] = React.useState(false);
  const [enable, setEnable] = React.useState(false); // Track if enabling the admin
  const [actionSuccess, setActionSuccess] = useState(""); // New state for success message

  const { state } = useLocation();
  const { id = "" } = state || "";
  const [disableAdmin] = useMutation(DISABLE_ADMIN);
  const [enableAdmin] = useMutation(ENABLE_ADMIN); // Mutation for enabling admin
  const { data, loading, error, refetch } = useQuery(VIEW_ADMIN, {
    variables: {
      adminId: id,
    },
    fetchPolicy: "network-only",
  });

  // Handle enabling the admin
  const handleOnEnableSuccess = async () => {
    try {
      setErrorMsg("");
      setEnable(true); // Enable flag set before mutation

      const response = await enableAdmin({
        variables: {
          input: { id },
        },
      });

      const { message, status } = response.data.enableAdmin;
      if (status === "error") {
        setErrorMsg(message);
        setEnable(false);
      } else {
        refetch();
        setActionSuccess("enabled"); // Set success message to 'enabled'
        setOpenSuccessModal(true);
        setEnableConfirmation(true);
        setEnable(false); // Reset enable flag
        setTimeout(() => {
          setEnableConfirmation(false);
        }, 2000);
      }
    } catch (err) {
      setErrorMsg("Something went wrong!");
      setEnable(false);
    }
  };

  // Handle disabling the admin
  const handleOnDisableSuccess = async () => {
    try {
      setErrorMsg("");
      setDisable(true); // Disable flag set before mutation

      const response = await disableAdmin({
        variables: {
          input: { id },
        },
      });

      const { message, status } = response.data.disableAdmin;
      if (status === "error") {
        setErrorMsg(message);
        setDisable(false);
      } else {
        refetch();
        setActionSuccess("disabled"); // Set success message to 'disabled'
        setOpenSuccessModal(true);
        setDisableConfirmation(true);
        setDisable(false); // Reset disable flag
        setTimeout(() => {
          setDisableConfirmation(false);
        }, 2000);
      }
    } catch (err) {
      setErrorMsg("Something went wrong!");
      setDisable(false);
    }
  };

  // Modify button click handler to properly manage state
  const handleButtonClick = () => {
    if (isActive) {
      setDisable(true); // Open disable confirmation modal
    } else {
      setEnable(true); // Open enable confirmation modal
    }
  };

  const handleSuceessClose = () => {
    setOpenSuccessModal(false);
    setDisableConfirmation(false);
    setEnableConfirmation(false);
  };

  const handleDisableClose = () => {
    setDisable(false);
    setEnable(false); // Reset enable flag when modal is closed
  };

  useEffect(() => {
    if (state === null) setErrorMsg("No Admin exists !");
  }, [state]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return (
      <Container
        maxWidth={false}
        disableGutters
        className={classes.roleContainer}
      >
        <Typography variant="subtitle1" className={classes.error}>
          Something Went Wrong !
        </Typography>
      </Container>
    );
  }

  const handleDeleteClick = () => {
    console.log("Delete clicked");
  };

  const { admin } = data;
  const {
    fullName,
    email,
    mobile,
    countryCode,
    role,
    permissions,
    createdAt,
    isActive,
  } = admin;
  return (
    <Container maxWidth={false} disableGutters className={classes.app}>
      <Typography className={classes.path}>
        <Link to="/admin/administrator" className={classes.link}>
          Admin
        </Link>
        &gt;
        <Typography className={classes.cursorPointer}>
          {" "}
          Admin Details{" "}
        </Typography>
      </Typography>
      <Grid container className={classes.profileHeadContainer}>
        <Grid lg={8} md={12} xs={12} item>
          <Grid container>
            <Grid lg={9} md={9} sm={8} item>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                  marginLeft: "40px",
                }}
              >
                <table className="profileInfo">
                  <tr>
                    <td>Name</td>
                    <td>{fullName}</td>
                  </tr>
                  <tr>
                    <td>Phone Number</td>
                    <td>{countryCode + mobile}</td>
                  </tr>
                  <tr>
                    <td>Email id</td>
                    <td>{email}</td>
                  </tr>
                  <tr>
                    <td>Role</td>
                    <td>{role.name}</td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>{isActive ? "Active" : "Disabled"}</td>
                  </tr>
                  <tr>
                    <td>Joining Date</td>
                    <td>
                      {moment(createdAt).format("MMMM Do YYYY, h:mm:ss a")}
                    </td>
                  </tr>
                </table>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid lg={2} md={0} xs={0} item />
      </Grid>
      <Box className={classes.permissonContainer}>
        {Object.entries(permissions).map(([key, value]) => {
          if (value && value.label) {
            return (
              <Switch
                key={key + value.label}
                value={value}
                permissionLabel={labels[key] || "Unknown Label"}
                permissionName={key}
                componentName="View"
              />
            );
          }
          return null;
        })}
      </Box>
      {errorMsg.length > 0 && (
        <Typography variant="h2" className={classes.error}>
          {errorMsg}
        </Typography>
      )}
      <Grid container>
        {/* <Grid lg={3} md={0} xs={0} item /> */}
        {/* <Grid
          item
          lg={4}
          md={6}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={() => handleDeleteClick()}
            color="primary"
            size="small"
            variant="outlined"
            style={{
              width: "200px",
              height: "40px",
              background: "white",
              fontWeight: "bold",
            }}
          >
            Delete Admin
          </Button>
        </Grid> */}
        {/* <Grid
          item
          lg={4}
          md={6}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "20px",
          }}
        > */}
        <Grid
          lg={4}
          md={6}
          sm={6}
          xs={12}
          item
          style={{ marginTop: "20px", marginLeft: "40px" }}
        >
          <Button
            onClick={() => handleButtonClick()} // Change this to setEnable if enabling admin
            color="primary"
            size="small"
            variant="contained"
            style={{
              width: "200px",
              height: "40px",
              background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
              fontWeight: "bold",
            }}
          >
            {isActive ? "Disable Admin" : "Enable Admin"}
          </Button>
        </Grid>
        {/* </Grid> */}
        {/* <Grid lg={5} md={0} xs={0} item /> */}
      </Grid>
      {(disableConfirmation || enableConfirmation) && (
        <SuccessModal
          handleClose={handleSuceessClose}
          open={openSuccessModal}
          heading={
            actionSuccess === "enabled"
              ? "Admin Enabled Successfully"
              : "Admin Disabled Successfully"
          }
          subtitle={
            actionSuccess === "enabled"
              ? "Admin has been enabled"
              : "Admin has been disabled"
          }
          type="delete"
        />
      )}
      {(disable || enable) && (
        <ConfirmationModal
          handleClose={handleDisableClose}
          open={handleDisableClose}
          heading={isActive ? "Disable Admin" : "Enable Admin"}
          subtitle={
            isActive
              ? "Are you sure you want to disable the admin?"
              : "Are you sure you want to enable the admin?"
          }
          button1="Cancel"
          button2={isActive ? "Disable" : "Enable"}
          onButton1Click={handleDisableClose}
          onButton2Click={
            isActive ? handleOnDisableSuccess : handleOnEnableSuccess
          } // Conditional handler
        />
      )}
    </Container>
  );
};
export default AdminDetails;
