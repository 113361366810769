import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_ALL_GAMES,
  GET_ALL_ROOMS,
  GET_NEXT_ALL_ROOMS,
  GET_PREV_ALL_ROOMS,
} from "graphql/query/admin";
import { gameConfigStyles } from "../gameStyles";
import { Box, Table } from "@material-ui/core";
import MainTable from "Components/Table/Table";
import Loader from "Components/Loader";

const TableRoomManagement = () => {
  const classes = gameConfigStyles();
  const [selectedGameId, setSelectedGameId] = useState(null);

  const [tableData, setTableData] = useState([]);
  const [pageInfo, setPageInfo] = useState("");
  const [page, setPage] = useState(0);

  const { data, error, loading } = useQuery(GET_ALL_GAMES, {
    variables: { first: 10, orderBy: "createdAt_DESC" },
    fetchPolicy: "network-only",
  });

  const { allRoomsData, allRoomsError, allRoomsLoading } = useQuery(
    GET_ALL_ROOMS,
    {
      variables: {
        first: 10,
        orderBy: "createdAt_DESC",
      },
      fetchPolicy: "network-only",
    }
  );

  console.log(allRoomsData, "ssssssssss");

  const [getBetNextPage, { loading: nextBetPageLoader }] =
    useLazyQuery(GET_NEXT_ALL_ROOMS);
  const [getBetPrevPage, { loading: prevBetPageLoader }] =
    useLazyQuery(GET_PREV_ALL_ROOMS);

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getBetNextPage({
        variables: {
          first: 10,
          after: pageInfo.endCursor,
          orderBy: "createdAt_DESC",
        },
        fetchPolicy: "network-only",
      });

      if (nextPageData.data.getAllRooms) {
        const list = nextPageData.data.getAllRooms.edges.map(
          (item) => item.node
        );
        setTableData(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.getAllRooms.pageInfo);
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getBetPrevPage({
        variables: {
          last: 10,
          before: pageInfo.startCursor,
          orderBy: "createdAt_DESC",
        },
        fetchPolicy: "network-only",
      });

      if (prevPageData.data.getAllRooms) {
        const list = prevPageData.data.getAllRooms.edges.map(
          (item) => item.node
        );
        setTableData(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.getAllRooms.pageInfo);
      }
    }
  };

  useEffect(() => {
    if (allRoomsData) {
      setTableData(
        allRoomsData.data.edges.map((item) => ({
          ...item.node,
        }))
      );
      setPageInfo(allRoomsData.data.pageInfo);
    }
  }, [allRoomsData]);

  const TABLE_HEAD = [
    { id: "id", label: "Sr.No", alignRight: false },
    { id: "name", label: "Name", alignRight: false },
    { id: "no", label: "Table No", alignRight: false },
    { id: "no", label: "No of Players", alignRight: false },
    { id: "total", label: "Entry Chips", alignRight: false },
    { id: "action", label: "Action", alignRight: false },
  ];

  if (allRoomsLoading || nextBetPageLoader || prevBetPageLoader) {
    return <Loader />;
  }

  if (allRoomsError) {
    return <p>Error: {allRoomsError.message}</p>;
  }

  return (
    <>
      <div className={classes.gameConfig}>
        <div className={classes.gameConfigHeader}>
          {data?.getAllGames?.edges?.map((game) => (
            <div
              key={game.node.id}
              className={`${classes.gameConfigBox} ${
                selectedGameId === game.node.id ? classes.selectedGame : ""
              }`}
              onClick={() => setSelectedGameId(game.node.id)}
            >
              {game.node.name}
            </div>
          ))}
        </div>
      </div>

      <Box className={classes.paddingAll}>
        <MainTable
          TABLE_HEAD={TABLE_HEAD}
          USERLIST={tableData}
          page={page}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </Box>
    </>
  );
};

export default TableRoomManagement;
