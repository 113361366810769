import React, { useState } from "react";
import { Box } from "@material-ui/core";

import { announcementStyles } from "./announcementStyles";
import Topbar from "./Components/TopBar/TopBar";
import Table from "./Components/Table/Table";
import AnnouncementModal from "Components/Modal/AnnouncementModal";

const AnnouncementManagement = () => {
  const classes = announcementStyles();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState(false);
  const [userFilter, setUserFilter] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("create");

  const handleCreateAnnouncement = () => {
    setOpenModal(true);
    setMode("create");
  };

  return (
    <Box>
      <Topbar
        title="Create Announcement"
        onButtonClick={handleCreateAnnouncement}
        userFilter={userFilter}
        setActionPerformed={setActionPerformed}
        setUserFilter={setUserFilter}
        setFilter={setFilter}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Box className={classes.paddingAll}>
        <Table
          userFilter={userFilter}
          filter={filter}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          actionPerformed={actionPerformed}
          setActionPerformed={setActionPerformed}
          searchQuery={searchQuery}
        />
      </Box>
      {openModal && (
        <AnnouncementModal
          open={openModal}
          mode={mode}
          handleClose={() => setOpenModal(false)}
          heading="Create Announcement"
          handleOnEditSuccess={() => {
            setOpenModal(false);
          }}
        />
      )}
    </Box>
  );
};

export default AnnouncementManagement;
