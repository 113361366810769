import { Wallet } from "@coral-xyz/anchor";
import { Transaction } from "@solana/web3.js";
import { DEPOSIT_MUTATION, SIGN_TRANSACTION_MUTATION } from "../../graphql/mutation/admin";
const { PublicKey } = require("@solana/web3.js");

const convertToHexString = (data) => {
  // Convert to hex string
  const hexString = Array.from(data)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join(" ");
  console.log(`<Buffer ${hexString}>`);

  return `<Buffer ${hexString}>`;
};

// Function to deserialize the instruction
function deserializeInstruction(serializedInstruction) {
  console.log("serialized", serializedInstruction);

  try {
    const a = {
      key: serializedInstruction.keys.map((key) => ({
        pubkey: new PublicKey(key.pubkey),
        isWritable: key.isWritable,
        isSigner: key.isSigner,
      })),

      programId: new PublicKey(serializedInstruction.programId),
      data: Buffer.from(serializedInstruction.data, "base64"),
      // data: convertToHexString(
      //   Uint8Array.from(atob(serializedInstruction.data), (c) =>
      //     c.charCodeAt(0)
      //   )
      // ),

      // s: serializedInstruction.programId,
    };
    console.log(a);
    return a;
  } catch (error) {
    console.error("Deserialization failed:", error);
    throw error;
  }
}

async function handleDeposit(client, depositInput) {
  try {
    // Execute the mutation
    const { data } = await client.mutate({
      mutation: DEPOSIT_MUTATION,
      variables: {
        input: depositInput,
      },
    });
    console.log(data);

    if (!data?.deposit?.instruction) {
      throw new Error("No instruction received from deposit mutation");
    }

    // Deserialize the instruction
    const instruction = deserializeInstruction(data.deposit.instruction);

    console.log("Instruction ", instruction);

    return instruction;
  } catch (error) {
    console.error("Error processing deposit:", error);
    throw error;
  }
}

async function handleSignTransaction(client, signTransactionInput) {
  try {
    const { data } = await client.mutate({
      mutation: SIGN_TRANSACTION_MUTATION,
      variables: {
        input: signTransactionInput,
      },
    });

    console.log("Sign Transaction Response:", data);
    
    return {
      message: data.signTransaction.message,
      status: data.signTransaction.status,
      confirmed: data.signTransaction.confirmed
    };
  } catch (error) {
    console.error("Error processing sign transaction:", error);
    throw error;
  }
}

function createInstructionFromResponse(response) {
  try {
    const instruction = deserializeInstruction(response.instruction);
    return instruction;
  } catch (error) {
    console.error("Error creating instruction:", error);
    throw error;
  }
}

// Example of processing multiple instructions
async function processMultipleDeposits(client, depositInputs) {
  try {
    const instructions = await Promise.all(
      depositInputs.map((input) => handleDeposit(client, input))
    );
    return instructions;
  } catch (error) {
    console.error("Error processing multiple deposits:", error);
    throw error;
  }
}

export {
  deserializeInstruction,
  handleDeposit,
  handleSignTransaction,
  createInstructionFromResponse,
  processMultipleDeposits,
};
