import React from "react";
import FormGroup from "@material-ui/core/FormGroup";
import { Grid, Box } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { permissons } from "_mocks/permissonLabels"; // Assuming permissions are defined here
import { SwitchStyles1, topBarStyles } from "../../administratorStyles";

export default function Switch1({
  permissionLabel,
  originalPermissions = {}, // Default to empty object if undefined
  permissionName,
  componentName,
  value,
  formik,
  fromAdmin,
}) {
  const classes = topBarStyles();

  // Ensure we always check for valid data before accessing it
  const originalPermission = originalPermissions[permissionName] || {};

  return (
    <FormGroup>
      <Typography component="div">
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography className={classes.permissKey}>
              {permissionLabel}
            </Typography>
            <Box className={classes.displayFlex}>
              {/* Debugging: Checking originalPermissions */}
              {Object.entries(value).map(([key2, value1]) => {
                if (key2 === "label") return null; // Skip the 'label' key if present

                const currentPermission = originalPermission[key2];

                return (
                  <Box
                    className={classes.displayFlex1}
                    key={`${key2}-${permissionName}`}
                  >
                    <SwitchStyles1
                      name={
                        componentName === "Role"
                          ? `permissions.${permissionName}.${key2}`
                          : `permissions.${permissionName}.${key2}`
                      }
                      checked={value1}
                      disabled={fromAdmin}
                      // Ensure formik is always called with the correct arguments
                      onChange={(e) => formik(e)} // Always call formik
                      id={`permissions.${permissionName}.${key2}`}
                    />
                    <Typography className={classes.labelKey}>
                      {permissons[permissionName]?.[key2] || key2}{" "}
                      {/* Display default key if not found */}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      </Typography>
    </FormGroup>
  );
}
