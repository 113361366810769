/* eslint-disable  */
export const AUTH_TOKEN = "auth-token";

import axios from "axios";
const uploadOnS3 = async (url, file) => {
  try {
    const response = await axios.put(url, file);
    if (response.status === 200) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
};
export default uploadOnS3;
