/* eslint-disable react/prop-types */
import React from "react";
import Grid from "@material-ui/core/Grid";
import { topBarStyles } from "./TopbarStyles";
import Button1 from "Modules/Admin/AnnouncementManagement/Components/TopBar/Components/Button/Button";

export default function TopBar({
  setSearchQuery,
  searchQuery,
  userFilter,
  setUserFilter,
  setActionPerformed,
  setFilter,
  title,
  onButtonClick,
}) {
  const classes = topBarStyles();
  const onClick = () => {
    setFilter(false);
    setUserFilter();
  };
  return (
    <div className={classes.root} style={{ marginBottom: "30px" }}>
      <Grid container spacing={3}>
        <Grid item xs={12} xl={12} md={12} lg={12} className={classes.dFlex}>
          {/* <Grid item className={classes.dFlex1}>
            <Typography variant="h4" className={classes.header1}>
              Announcements
            </Typography>
            <Filter
              setFilter={setFilter}
              setActionPerformed={setActionPerformed}
              setCategoryFilter={setUserFilter}
            />
            {userFilter && <Selected text="Clear Filters" onClick={onClick} />}
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.dFlex}>
          <Grid item className={classes.positionRelative}>
            <Search
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              text="Search Announcements..."
              flag
            />
          </Grid> */}
          <Grid item>
            <Button1 title={title} onButtonClick={onButtonClick} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
