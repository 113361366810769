/* eslint-disable import/prefer-default-export */
import { makeStyles } from "@material-ui/core/styles";

const buttonStyles = makeStyles((theme) => ({
  button: {
    width: "240px",
    height: "40px",
    marginRight: "20px",
    background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
    fontStyle: "normal",
    marginLeft: "15px",
    fontWeight: 450,
    fontSize: "15px",
    [theme.breakpoints.down("md")]: {
      width: "120px",
      padding: "10px 10px",
      fontSize: "10px",
    },
  },
}));

export { buttonStyles };
