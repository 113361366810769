import { makeStyles } from "@material-ui/core/styles";

const fundsManaagement = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "20px",
  },
  width100: {
    width: "100%",
  },
  dFlex: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    gap: "10px",
  },
  dFlexWalletBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    alignItems: "center",
    marginBottom: "20px",
  },
  button: {
    background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
    color: "white",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  keyValue: {
    display: "flex",
    justifyContent: "left",
    marginTop: "10px",
    fontSize: "1.2rem",
    gap: "20px",
    marginBottom: "20px",
  },
  marginTop: {
    marginTop: "20px",
  },
  bothContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    gap: "20px",
    marginTop: "20px",
  },
  balanceContainer: {
    display: "flex",
    border: "1px solid #ccc",
    maxWidth: "fit-content",
    borderRadius: "5px",
    height: "150px",
    flexDirection: "column",
    alignItems: "start",
    padding: "20px",
  },
  txnContainer: {
    display: "flex",
    border: "1px solid #ccc",
    maxWidth: "fit-content",
    borderRadius: "5px",
    flexDirection: "column",
    alignItems: "start",
    padding: "20px 20px 30px 20px",
  },
  walletContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    gap: "20px",
  },
}));
export { fundsManaagement };
