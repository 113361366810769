/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@material-ui/core';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import Table from 'Components/Table/Table';
import { Loader } from 'Components/TableLoader/index';
import {
  GET_ALL_ROLES, GET_NEXT_ROLES_PAGE, GET_PREV_ROLES_PAGE,
} from 'graphql/query/admin';
import { formStyles } from './RoleManagementStyles';

const RoleManagement = ({
  handleEditButtonClick,
  handleDeleteButtonClick,
  roleFilter,
  filter,
  actionPerformed,
  setActionPerformed,
  errorMsg,
  setErrorMsg,
  searchQuery,
}) => {
  const navigate = useNavigate();
  const classes = formStyles();
  const [roles, setRoles] = useState([]);
  const [pageInfo, setPageInfo] = useState('');
  const [page, setPage] = useState(0);
  const {
    data, refetch, error, loading,
  } = useQuery(GET_ALL_ROLES, {
    variables: {
      first: 10,
      orderBy: 'createdAt_DESC',
      filters: !filter ? {
        isActiveBool: 'true',
        name: searchQuery.length === 0 ? '' : searchQuery,
      } : { ...roleFilter, isActiveBool: 'true' },
    },
    fetchPolicy: 'network-only',
  });

  const [getNextPage] = useLazyQuery(GET_NEXT_ROLES_PAGE);
  const [getPrevPage] = useLazyQuery(GET_PREV_ROLES_PAGE);

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          first: 10,
          after: pageInfo.endCursor,
          orderBy: 'createdAt_DESC',
          filters: {
            isActiveBool: 'true',
          },
        },
        fetchPolicy: 'network-only',
      });

      if (nextPageData.data.roles) {
        const list = await nextPageData.data.roles.edges.map((role) => role.node);
        setRoles(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.roles.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          first: 10,
          after: pageInfo.startCursor,
          orderBy: 'createdAt_DESC',
          filters: {
            isActiveBool: 'true',
          },
        },
        fetchPolicy: 'network-only',
      });

      if (prevPageData.data.roles) {
        const list = await prevPageData.data.roles.edges.map((role) => role.node);
        setRoles(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.roles.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handleViewButton = (id) => {
    navigate('/admin/administrator/view-role', { state: { id } });
  };

  const handleEditButton = (values) => {
    handleEditButtonClick(values);
  };
  const handleDeleteButton = (id) => {
    handleDeleteButtonClick(id);
  };

  console.log(filter, roleFilter);
  const setAllRoles = async () => {
    // Display All Data
    if (data && data.roles) {
      const list = await data.roles.edges.map((role) => role.node);
      setPageInfo(data.roles.pageInfo);
      setRoles(list);
      setErrorMsg('');
    }
    setPage(0);
  };

  useEffect(() => {
    console.log('updated');
    if (actionPerformed) {
      refetch();
      setActionPerformed(false);
    }
    if (error) { setErrorMsg('Error in loading data !'); }

    setAllRoles();
  }, [data, actionPerformed, error, searchQuery, filter]);

  const TABLE_HEAD = [
    { id: 'id', label: 'Sr.No', alignRight: false },
    { id: 'name', label: 'Role Name', alignRight: false },
    { id: 'createdBy', label: 'Created By', alignRight: false },
    { id: 'createdAt', label: 'Creation Date', alignRight: false },
    {
      id: 'actions',
      flag: { read: true, edit: true, remove: true },
      callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: 'Actions',
      alignRight: false,
    },
  ];

  if (loading) {
    return <Loader />;
  }

  if (roles.length > 0) {
    return (
      <Box className={classes.noDataBox}>
        { errorMsg.length > 0 && <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography> }
        <Table
          USERLIST={roles}
          TABLE_HEAD={TABLE_HEAD}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          page={page}
          setPage={setPage}
        />
      </Box>
    );
  }

  return (
    <Box className={classes.noDataBox}>
      { errorMsg.length > 0 ? <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography>
        : <Typography variant="h5">No Data Found !</Typography> }
    </Box>
  );
};

export default RoleManagement;
