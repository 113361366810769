import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Selected from "Components/Selected/Selected";
import Filter from "./Components/Filter/Filter";
import Search from "./Components/SearchBar/Search";
import Button1 from "./Components/Button/Button";
import { topBarStyles } from "./TopbarStyles";
import { useQuery, useApolloClient } from "@apollo/client";
import { GET_STAKING_SYSTEM_DETAILS } from "graphql/query/admin";
import {
  HANDLE_CONTRACT_STATUS,
  HANDLE_MIN_STAKE_AMOUNT,
} from "graphql/mutation/admin";
import { toast } from "react-toastify";

export default function TopBar({
  setSearchQuery,
  searchQuery,
  userFilter,
  setUserFilter,
  setActionPerformed,
  setFilter,
  title,
  onButtonClick,
  handleSearch,
  handleDateFilter,
}) {
  const classes = topBarStyles();
  const onClick = () => {
    setFilter(false);
    setUserFilter();
  };
  const [isPaused, setIsPaused] = useState(false);
  const client = useApolloClient();
  const { data, refetch } = useQuery(GET_STAKING_SYSTEM_DETAILS);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [minStakeAmount, setMinStakeAmount] = useState("");
  const [displayMinStake, setDisplayMinStake] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleMinStakeUpdate = async () => {
    if (isUpdateLoading) return;
    setIsUpdateLoading(true);
    try {
      const handleMinStakeInput = {
        value: Number(minStakeAmount),
      };
      const { data } = await client.mutate({
        mutation: HANDLE_MIN_STAKE_AMOUNT,
        variables: {
          input: handleMinStakeInput,
        },
      });
      if (data?.handleMinStakeAmount?.status !== false) {
        toast.success("Min stake amount updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
        });
        setDisplayMinStake(minStakeAmount); // Update the display value
        setMinStakeAmount(""); // Clear the input
        refetch();
      } else {
        toast.error("Failed to update min stake amount");
      }
    } catch (error) {
      console.log("Min stake update error:", error.message);
      toast.error(error.message || "Failed to update min stake amount");
    } finally {
      setIsUpdateLoading(false);
    }
  };

  const handleContractStatus = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const handleContractStatusInput = {
        value: isPaused ? true : false,
      };
      const { data } = await client.mutate({
        mutation: HANDLE_CONTRACT_STATUS,
        variables: {
          input: handleContractStatusInput,
        },
      });
      if (data?.handleContractStatus?.status !== false) {
        toast.success("Contract status updated successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
        });
        refetch();
      }
    } catch (error) {
      console.log("Contract status error:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data) {
      setIsPaused(data?.getStakingSystemDetails?.isContractPaused);
      setDisplayMinStake(data?.getStakingSystemDetails?.minStakeAmount);
    }
  }, [data]);

  const buttonStyle = {
    width: "150px",
    height: "40px",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    fontWeight: "500",
    background: isPaused
      ? "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)"
      : "#FF0000",
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} style={{ marginBottom: "20px" }}>
        <Grid
          item
          xs={6}
          xl={6}
          md={6}
          lg={6}
          className={classes.dFlex}
          style={{ justifyContent: "unset" }}
        >
          <Grid item className={classes.dFlex1}>
            <button
              style={buttonStyle}
              onClick={handleContractStatus}
              disabled={isLoading}
            >
              {isLoading
                ? "Processing..."
                : isPaused
                ? "Unpause Staking"
                : "Pause Staking"}
            </button>
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.dFlex}>
          <Grid item className={classes.positionRelative}>
            <Search
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              text="Search by username..."
              flag
              onSearch={handleSearch}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid
          item
          xs={6}
          xl={6}
          md={6}
          lg={6}
          className={classes.dFlex}
          style={{ justifyContent: "unset" }}
        >
          <Grid item className={classes.dFlex1}>
            <Filter
              setFilter={setFilter}
              setActionPerformed={setActionPerformed}
              setCategoryFilter={setUserFilter}
              onFilterApply={handleDateFilter}
            />
            {userFilter && <Selected text="Clear Filters" onClick={onClick} />}
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.dFlex}>
          <Grid item className={classes.positionRelative}>
            <input
              type="number"
              min="0"
              step="1"
              value={minStakeAmount}
              onChange={(e) => setMinStakeAmount(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "-" || e.key === "e" || e.key === ".") {
                  e.preventDefault();
                }
              }}
              placeholder={`Update Min. Stake Amount ( ${displayMinStake} )`}
              style={{
                width: "256px",
                height: "40px",
                textAlign: "center",
                padding: "10px 24px",
                borderRadius: "2px",
              }}
            />
          </Grid>
          {title && (
            <Grid item>
              <Button1
                title={isUpdateLoading ? "Updating..." : "Update"}
                onButtonClick={handleMinStakeUpdate}
                disabled={isUpdateLoading}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
