import React, { useEffect } from 'react';
import axios from 'axios';

import Table from 'Components/Table/Table';
import TRANSACTIONS from '_mocks/transactions';

const CategortTable = () => {
  const handleViewButton = () => { };
  const handleEditButton = () => { };
  const handleDeleteButton = () => { };

  useEffect(() => {
    const body = {
      pageNum: 1,
      pageSize: 10,
      address: '0x509eca2f1f11e49bf45a181057bad74729edf7c0',
    };
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    axios.post('https://e.mtv.ac/transaction/list', body, { headers })
      .then((response) => console.log(response));
  }, []);

  const TABLE_HEAD = [
    {
      id: 'id', flag: 'id', label: 'Sr.No', alignRight: false,
    },
    {
      id: 'txnHash', flag: 'txnHash', label: 'Transaction Hash', alignRight: false,
    },
    {
      id: 'status1', flag: 'status1', label: 'Status', alignRight: false,
    },
    {
      id: 'method', flag: 'method', label: 'Method', alignRight: false,
    },
    {
      id: 'age', flag: 'age', label: 'Age', alignRight: false,
    },
    {
      id: 'from', flag: 'from', label: 'From', alignRight: false,
    },
    {
      id: 'to', flag: 'to', label: 'To', alignRight: false,
    },
    {
      id: 'value', flag: 'value', label: 'Value', alignRight: false,
    },
    {
      id: 'txnFee', flag: 'txnFee', label: 'Txn Fee', alignRight: false,
    },

    {
      id: 'actions',
      flag: { read: false, edit: false, remove: false },
      callback: { handleViewButton, handleEditButton, handleDeleteButton },
      label: 'Actions',
      alignRight: false,
    },
  ];

  return (
    <Table USERLIST={TRANSACTIONS} TABLE_HEAD={TABLE_HEAD} />
  );
};

export default CategortTable;
