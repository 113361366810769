/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useQuery, useLazyQuery } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';

import Table from 'Components/Table/Table';
import { Loader } from 'Components/TableLoader';
import {
  GET_COLLECTIBLES, GET_NEXT_COLLECTIBLES, GET_PREV_COLLECTIBLES,
} from 'graphql/query/admin';
import { useStyles } from './TableStyles';

const CollectionRequest = ({
  errorMsg,
  setErrorMsg,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [collectibles, setCollectibles] = useState();
  // eslint-disable-next-line no-unused-vars
  const [pageInfo, setPageInfo] = useState('');
  const [page, setPage] = useState(0);

  const {
    data, refetch, error, loading,
  } = useQuery(GET_COLLECTIBLES, {
    variables: {
      first: 10,
      orderBy: 'createdAt_DESC',
    },
    fetchPolicy: 'network-only',
  });

  const [getNextPage] = useLazyQuery(GET_NEXT_COLLECTIBLES);
  const [getPrevPage] = useLazyQuery(GET_PREV_COLLECTIBLES);

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          first: 10,
          orderBy: 'createdAt_DESC',
          after: pageInfo.endCursor,
        },
        fetchPolicy: 'network-only',
      });

      if (nextPageData.data.items) {
        const list = await nextPageData.data.items.edges.map((item) => item.node);
        setCollectibles(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.items.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handlePreviousPage = async () => {
    console.log(pageInfo);
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          first: 10,
          orderBy: 'createdAt_DESC',
          before: pageInfo.endCursor,
        },
        // fetchPolicy: 'network-only',
      });

      if (prevPageData.data.items) {
        const list = await prevPageData.data.items.edges.map((item) => item.node);
        setCollectibles(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.items.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const setAllAdmins = () => {
    // Display All Data
    if (data && data.items) {
      const list = data.items.edges.map((item) => item.node);
      console.log(list);
      setPageInfo(data.items.pageInfo);
      setCollectibles(list);
      setErrorMsg('');
    }
    setPage(0);
  };

  useEffect(() => {
    setAllAdmins();
  }, [data]);

  const handleViewButton = (id) => {
    navigate('/admin/collectible/viewCollectible', { state: {
      id,
    } });
  };

  const TABLE_HEAD = [
    { id: 'id', flag: 'id', label: 'Sr.No', alignRight: false },
    { id: 'currentOwner', flag: 'currentOwner', label: 'Owner', alignRight: false },
    { id: 'createdByUsername', flag: 'createdBy', label: 'Creator', alignRight: false },
    { id: 'title', flag: 'title', label: 'Name', alignRight: false },
    { id: 'collection', flag: 'collection', label: 'Collection Name', alignRight: false },
    { id: 'tokenId', flag: 'tokenId', label: 'Token Id', alignRight: false },
    // { id: 'saleType', flag: 'saleType', label: 'Sale Type', alignRight: false },
    // { id: 'listedDate', flag: 'listedDate', label: 'Listed Date', alignRight: false },
    {
      id: 'actions',
      flag: { read: true, edit: false, remove: false },
      callback: { handleViewButton },
      label: 'Actions',
      alignRight: false,
    },
  ];

  if (loading) {
    return <Loader />;
  }

  if (collectibles && collectibles.length > 0) {
    return (
      <Box style={{ minHeight: '100vh' }}>
        { errorMsg.length > 0 && <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography> }

        <Table
          USERLIST={collectibles}
          TABLE_HEAD={TABLE_HEAD}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          page={page}
          setPage={setPage}
        />
      </Box>
    );
  }
  return (
    <Box style={{ minHeight: '70vh' }}>
      { errorMsg.length > 0 ? <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography>
        : <Typography variant="h5">No Data Found !</Typography> }
    </Box>
  );
};

export default CollectionRequest;

// const DesignPending = () => <h2> Design Pending</h2>;

// export default DesignPending;
