/* eslint-disable import/prefer-default-export */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  paddingAll: {
    padding: "0px 20px",
  },
  header1: {
    fontWeight: "bold",
    marginBottom: "20px",
    fontSize: "20px",
  },
  header6: {
    fontWeight: "normal",
    marginBottom: "20px",
    fontSize: "14px",
  },
  marginUp: {
    margin: "20px 0px",
  },
  dFlex: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  twofa: {
    display: "flex",
    margin: "20px 0px 10px 0px",
    justifyContent: "start",
    alignItems: "center",
  },
  label: {
    fontSize: "18px",
  },
  my: {
    margin: "5px 0px 10px 0px",
  },
  button: {
    width: "180px !important",
    height: "40px !important",
    marginRight: "20px !important",
    background: "linear-gradient(90deg, #70D99F 0%, #3D83B5 100%)",
    fontStyle: "normal",
    marginLeft: "15px !important",
    color: "white",
    fontWeight: 450,
    fontSize: "15px !important",
  },
}));

export { useStyles };
