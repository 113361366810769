export const permissons = {
  Admin: {
    GET: "View Details",
    GET_ALL: "View All",
    CREATE: "Create",
    UPDATE: "Update",
    DELETE: "Delete",
  },
  Dashboard: {
    GET_REPORT: "Get Reports",
    GET_SALES: "Get Sales",
    GET_NOTIFICATIONS: "Get Notifications",
    MARK_NOTIFICATION_READ: "Mark Notification Read",
  },
  // Category: {
  //   GET: 'View Details',
  //   GET_ALL: 'View All',
  //   CREATE: 'Create',
  //   UPDATE: 'Update',
  //   DELETE: 'Delete',
  // },
  Transaction: {
    GET: "View Details",
    GET_ALL: "View All",
    CREATE: "Create",
    UPDATE: "Update",
    DELETE: "Delete",
  },
  ActivityLog: {
    GET: "View Details",
    GET_ALL: "View All",
  },
  Report: {
    USER_ON_BOARDING: "User Onboarding",
  },
  Support: {
    GET: "View Details",
    GET_ALL: "View All",
    SEND: "Send",
  },
  Analytics: {
    GET_ONBOARDING_REPORTS: "Get Onboarding Reports",
    EXPORT_ONBOARDING_REPORTS: "Export Onboarding Reports",
    GET_SALES_REPORTS: "Get Sales Reports",
    EXPORT_SALES_REPORTS: "Export Sales Reports",
    GET_ISSUES_REPORTS: "Get Issues Reports",
    EXPORT_ISSUES_REPORTS: "Export Issues Reports",
  },
  User: {
    BLOCK: "Block",
    UN_BLOCK: "Unblock",
    GET_VERIFICATION_REQUESTS: "Get Verification Request",
    ACCEPT_REQUEST: "Accept Request",
    REJECT_REQUEST: "Reject Request",
    UN_VERIFY: "Unverify",
  },
  Platform_Variables: {
    UPDATE_PLATFORM_VARIABLES: "Update Platform Variables",
    UPDATE_FEATURED_COLLECTIONS: "Update Featured Collections",
    CREATE_PLATFORM_VARIABLES: "Create Platform Variables",
    CREATE_FEATURED_COLLECTIONS: "Create Featured Collections",
  },
};

export const labels = {
  Admin: "Admin Management",
  // Category: 'Category Management',
  Transaction: "Transaction Management",
  User: "User Management",
  Platform_Variables: "Platform Variables",
  Support: "Support Management",
  // Analytics: 'Analytics',
  Report: "Report",
  ActivityLog: "ActivityLog",
  Dashboard: "Dashboard",
};
