/* eslint-disable  */

import { gql } from "@apollo/client";

// Role Management

export const GET_ALL_ROLES = gql`
  query Permissions(
    $first: Int
    $filters: RoleWhereInput
    $orderBy: RoleOrderByInput!
  ) {
    roles(first: $first, filters: $filters, orderBy: $orderBy) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          createdAt
          createdById {
            fullName
            email
          }
          isActive
          permissions {
            User {
              BLOCK
              GET
            }
            Game {
              GET
              UPDATE
            }
            Room {
              GET
              UPDATE
            }
            Announcement {
              GET
              UPDATE
            }
          }
        }
      }
    }
  }
`;

export const GET_NEXT_ROLES_PAGE = gql`
  query Permissions(
    $first: Int
    $after: String
    $filters: RoleWhereInput
    $orderBy: RoleOrderByInput!
  ) {
    roles(first: $first, after: $after, filters: $filters, orderBy: $orderBy) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          createdAt
          createdById {
            fullName
            email
          }
          isActive
          permissions {
            User {
              BLOCK
              GET
            }
            Game {
              GET
              UPDATE
            }
            Room {
              GET
              UPDATE
            }
            Announcement {
              GET
              UPDATE
            }
          }
        }
      }
    }
  }
`;

export const GET_PREV_ROLES_PAGE = gql`
  query Permissions(
    $first: Int
    $before: String
    $filters: RoleWhereInput
    $orderBy: RoleOrderByInput!
  ) {
    roles(
      first: $first
      before: $before
      filters: $filters
      orderBy: $orderBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          createdAt
          createdById {
            fullName
            email
          }
          isActive
          permissions {
            User {
              BLOCK
              GET
            }
            Game {
              GET
              UPDATE
            }
            Room {
              GET
              UPDATE
            }
            Announcement {
              GET
              UPDATE
            }
          }
        }
      }
    }
  }
`;

export const VIEW_ROLE = gql`
  query Query($roleId: String!) {
    role(id: $roleId) {
      id
      name
      isActive
      createdById {
        fullName
        email
      }
      permissions {
        User {
          BLOCK
          GET
        }
        Game {
          GET
          UPDATE
        }
        Room {
          GET
          UPDATE
        }
        Announcement {
          GET
          UPDATE
        }
      }
    }
  }
`;

export const SEARCH_ROLE = gql`
  query Query($filters: RoleWhereInput, $first: Int) {
    roles(filters: $filters, first: $first) {
      edges {
        cursor
        node {
          id
          name
          createdAt
          createdById {
            fullName
            email
          }
          isActive
          permissions {
            User {
              BLOCK
              GET
            }
            Game {
              GET
              UPDATE
            }
            Room {
              GET
              UPDATE
            }
            Announcement {
              GET
              UPDATE
            }
          }
        }
      }
    }
  }
`;

// Admin Management

export const GET_ALL_ADMINS = gql`
  query Admins(
    $first: Int
    $orderBy: AdminOrderByInput!
    $filters: AdminsWhereInput
  ) {
    admins(first: $first, orderBy: $orderBy, filters: $filters) {
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
      edges {
        node {
          id
          isActive
          fullName
          email
          mobile
          countryCode
          role {
            name
            id
          }
          permissions {
            User {
              BLOCK
              GET
            }
            Game {
              GET
              UPDATE
            }
            Room {
              GET
              UPDATE
            }
            Announcement {
              GET
              UPDATE
            }
          }
        }
      }
    }
  }
`;

export const GET_NEXT_ADMINS_PAGE = gql`
  query Admins(
    $first: Int
    $orderBy: AdminOrderByInput!
    $filters: AdminsWhereInput
    $after: String
  ) {
    admins(first: $first, orderBy: $orderBy, filters: $filters, after: $after) {
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
      edges {
        node {
          id
          isActive
          fullName
          email
          mobile
          countryCode
          role {
            name
            id
          }
          permissions {
            User {
              BLOCK
              GET
            }
            Game {
              GET
              UPDATE
            }
            Room {
              GET
              UPDATE
            }
            Announcement {
              GET
              UPDATE
            }
          }
        }
      }
    }
  }
`;

export const GET_PREV_ADMINS_PAGE = gql`
  query Admins(
    $first: Int
    $orderBy: AdminOrderByInput!
    $filters: AdminsWhereInput
    $before: String
  ) {
    admins(
      first: $first
      orderBy: $orderBy
      filters: $filters
      before: $before
    ) {
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
      edges {
        node {
          id
          isActive
          fullName
          email
          mobile
          countryCode
          role {
            name
            id
          }
          permissions {
            User {
              BLOCK
              GET
            }
            Game {
              GET
              UPDATE
            }
            Room {
              GET
              UPDATE
            }
            Announcement {
              GET
              UPDATE
            }
          }
        }
      }
    }
  }
`;

export const VIEW_ADMIN = gql`
  query Query($adminId: String!) {
    admin(id: $adminId) {
      id
      isActive
      fullName
      email
      mobile
      createdAt
      countryCode
      role {
        name
        id
      }
      permissions {
        User {
          BLOCK
          GET
        }
        Game {
          GET
          UPDATE
        }
        Room {
          GET
          UPDATE
        }
        Announcement {
          GET
          UPDATE
        }
      }
    }
  }
`;

export const SEARCH_ADMIN = gql`
  query Admins(
    $first: Int
    $orderBy: AdminOrderByInput
    $filters: AdminsWhereInput
  ) {
    admins(first: $first, orderBy: $orderBy, filters: $filters) {
      pageInfo {
        endCursor
        hasPreviousPage
        hasNextPage
        startCursor
      }
      edges {
        node {
          id
          isActive
          fullName
          email
          mobile
          countryCode
          role {
            name
            id
            permissions {
              User {
                BLOCK
                GET
              }
              Game {
                GET
                UPDATE
              }
              Room {
                GET
                UPDATE
              }
              Announcement {
                GET
                UPDATE
              }
            }
          }
        }
      }
    }
  }
`;

// Admin Profile

export const ADMIN_PROFILE = gql`
  query Me {
    me {
      id
      fullName
      email
      permissions {
        User {
          BLOCK
          GET
        }
        Game {
          GET
          UPDATE
        }
        Room {
          GET
          UPDATE
        }
        Announcement {
          GET
          UPDATE
        }
      }
      mobile
      countryCode
      profilePic
      role {
        id
        name
        isActive
        createdById {
          id
          fullName
          username
          email
          description
          mobile
          address
          countryCode
          profilePic
          coverPic
          walletAddress
          createdAt
          lastSigninAt
          floorPrice
          firstName
          lastName
          parsedFullName
          availableBalance
          isCurrentlyInRoom
          isActive
          failedLoginAttempts
          accountMetadata {
            isBlocked
            isBlockedByAdmin
            isBanned
            unblocksAt
            customBlockMessage
          }
          image {
            url
            key
          }
          lockoutUntil
        }
        createdAt
      }
      createdBy {
        id
        fullName
        email
        mobile
        countryCode
        profilePic
        createdAt
        isActive
        isVerified
        isBlocked
        isBanned
        isQrGenerated
        secret
        isTwoFaEnabled
        ipAddress
        device
        lastSigninAt
      }
      updatedBy {
        id
        fullName
        email
        mobile
        countryCode
        profilePic
        createdAt
        isActive
        isVerified
        isBlocked
        isBanned
        isQrGenerated
        secret
        isTwoFaEnabled
        ipAddress
        device
        lastSigninAt
      }
      createdAt
      isActive
      isVerified
      isBlocked
      isBanned
      isQrGenerated
      secret
      isTwoFaEnabled
      ipAddress
      device
      lastSigninAt
    }
  }
`;

// Category Management
export const GET_ALL_CATEGORIES = gql`
  query Query(
    $first: Int
    $filters: CategoryWhereInput
    $orderBy: CategoryOrderByInput!
  ) {
    categories(first: $first, filters: $filters, orderBy: $orderBy) {
      edges {
        node {
          id
          name
          createdAt
          description
          createdBy {
            fullName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_NEXT_CATEGORIES = gql`
  query Query(
    $first: Int
    $filters: CategoryWhereInput
    $orderBy: CategoryOrderByInput!
    $after: String
  ) {
    categories(
      first: $first
      filters: $filters
      orderBy: $orderBy
      after: $after
    ) {
      edges {
        node {
          id
          name
          createdAt
          description
          createdBy {
            fullName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_PREV_CATEGORIES = gql`
  query Query(
    $first: Int
    $filters: CategoryWhereInput
    $orderBy: CategoryOrderByInput!
    $before: String
  ) {
    categories(
      first: $first
      filters: $filters
      orderBy: $orderBy
      before: $before
    ) {
      edges {
        node {
          id
          name
          createdAt
          description
          createdBy {
            fullName
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

// game management
export const GET_ALL_GAMES = gql`
  query GetAllGames($orderBy: GameOrderByInput!, $first: Int) {
    getAllGames(orderBy: $orderBy, first: $first) {
      edges {
        cursor
        node {
          id
          name
          betLimit
          isEnabled
          createdAt
          updatedAt
          playersAllowedInRoom
          roundStartsInSeconds
          straight_up
          split
          street
          corner
          line
          column
          dozen
          red_black
          odd_even
          high_low
          blackjackPlayerWinnerMultipier
          blackjackDealerBustedMultiplier
          bankerMultiplier
          playerMultiplier
          tieMultiplier
          slotWinningMultiplier
          smallBlindAmount
          rakePercentage
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_GAME_BY_ID = gql`
  query GetGameById($getGameById: ID!) {
    getGameById(id: $getGameById) {
      id
      name
      betLimit
      isEnabled
      createdAt
      updatedAt
      playersAllowedInRoom
      roundStartsInSeconds
      straight_up
      split
      street
      corner
      line
      column
      dozen
      red_black
      odd_even
      high_low
      blackjackPlayerWinnerMultipier
      blackjackDealerBustedMultiplier
      bankerMultiplier
      playerMultiplier
      tieMultiplier
      slotWinningMultiplier
      smallBlindAmount
      rakePercentage
    }
  }
`;

export const GET_ALL_ROOMS = gql`
  query GetAllRooms(
    $orderBy: RoomOrderByInput!
    $filters: RoomWhereInput
    $first: Int
  ) {
    getAllRooms(orderBy: $orderBy, filters: $filters, first: $first) {
      edges {
        cursor
        node {
          id
          _game {
            id
            name
            betLimit
            isEnabled
            createdAt
            updatedAt
            playersAllowedInRoom
            roundStartsInSeconds
            straight_up
            split
            street
            corner
            line
            column
            dozen
            red_black
            odd_even
            high_low
            blackjackPlayerWinnerMultipier
            blackjackDealerBustedMultiplier
            bankerMultiplier
            playerMultiplier
            tieMultiplier
            slotWinningMultiplier
            smallBlindAmount
            rakePercentage
          }
          roomId
          isActive
          isDefaultRoom
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_NEXT_ALL_ROOMS = gql`
  query GetAllRooms(
    $orderBy: RoomOrderByInput!
    $first: Int
    $after: String
    $filters: RoomWhereInput
  ) {
    getAllRooms(
      orderBy: $orderBy
      filters: $filters
      first: $first
      after: $after
    ) {
      edges {
        cursor
        node {
          id
          _game {
            id
            name
            betLimit
            isEnabled
            createdAt
            updatedAt
            playersAllowedInRoom
            roundStartsInSeconds
            straight_up
            split
            street
            corner
            line
            column
            dozen
            red_black
            odd_even
            high_low
            blackjackPlayerWinnerMultipier
            blackjackDealerBustedMultiplier
            bankerMultiplier
            playerMultiplier
            tieMultiplier
            slotWinningMultiplier
            smallBlindAmount
            rakePercentage
          }
          roomId
          isActive
          isDefaultRoom
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_PREV_ALL_ROOMS = gql`
  query GetAllRooms(
    $orderBy: RoomOrderByInput!
    $last: Int
    $before: String
    $filters: RoomWhereInput
  ) {
    getAllRooms(
      orderBy: $orderBy
      filters: $filters
      last: $last
      before: $before
    ) {
      edges {
        cursor
        node {
          id
          _game {
            id
            name
            betLimit
            isEnabled
            createdAt
            updatedAt
            playersAllowedInRoom
            roundStartsInSeconds
            straight_up
            split
            street
            corner
            line
            column
            dozen
            red_black
            odd_even
            high_low
            blackjackPlayerWinnerMultipier
            blackjackDealerBustedMultiplier
            bankerMultiplier
            playerMultiplier
            tieMultiplier
            slotWinningMultiplier
            smallBlindAmount
            rakePercentage
          }
          roomId
          isActive
          isDefaultRoom
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

// User management

export const GET_ALL_USERS = gql`
  query Users(
    $orderBy: UserOrderByInput!
    $first: Int
    $filters: UsersWhereInput
  ) {
    users(orderBy: $orderBy, first: $first, filters: $filters) {
      edges {
        cursor
        node {
          id
          fullName
          username
          email
          description
          mobile
          address
          countryCode
          profilePic
          coverPic
          walletAddress
          createdAt
          lastSigninAt
          floorPrice
          firstName
          lastName
          parsedFullName
          availableBalance
          isCurrentlyInRoom
          isActive
          failedLoginAttempts
          accountMetadata {
            isBlocked
            isBlockedByAdmin
            isBanned
            unblocksAt
            customBlockMessage
          }
          image {
            url
            key
          }
          lockoutUntil
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
export const VIEW_USER = gql`
  query User($userId: String!) {
    user(id: $userId) {
      user {
        id
        fullName
        username
        email
        description
        mobile
        address
        countryCode
        profilePic
        coverPic
        walletAddress
        createdAt
        lastSigninAt
        floorPrice
        firstName
        lastName
        parsedFullName
        availableBalance
        isCurrentlyInRoom
        isActive
        failedLoginAttempts
        accountMetadata {
          isBlocked
          isBlockedByAdmin
          isBanned
          unblocksAt
          customBlockMessage
        }
        image {
          url
          key
        }
        lockoutUntil
      }
      totalBetPlaced
    }
  }
`;

export const GET_NEXT_USERS = gql`
  query Query(
    $orderBy: UserOrderByInput!
    $first: Int
    $filters: UsersWhereInput
    $after: String
  ) {
    users(orderBy: $orderBy, first: $first, filters: $filters, after: $after) {
      edges {
        cursor
        node {
          id
          fullName
          username
          email
          description
          mobile
          address
          countryCode
          profilePic
          coverPic
          walletAddress
          createdAt
          lastSigninAt
          floorPrice
          firstName
          lastName
          parsedFullName
          availableBalance
          isCurrentlyInRoom
          isActive
          failedLoginAttempts
          accountMetadata {
            isBlocked
            isBlockedByAdmin
            isBanned
            unblocksAt
            customBlockMessage
          }
          image {
            url
            key
          }
          lockoutUntil
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;
export const GET_PREV_USERS = gql`
  query Query(
    $orderBy: UserOrderByInput!
    $last: Int
    $filters: UsersWhereInput
    $before: String
  ) {
    users(orderBy: $orderBy, filters: $filters, before: $before, last: $last) {
      edges {
        cursor
        node {
          id
          fullName
          username
          email
          description
          mobile
          address
          countryCode
          profilePic
          coverPic
          walletAddress
          createdAt
          lastSigninAt
          floorPrice
          firstName
          lastName
          parsedFullName
          availableBalance
          isCurrentlyInRoom
          isActive
          failedLoginAttempts
          accountMetadata {
            isBlocked
            isBlockedByAdmin
            isBanned
            unblocksAt
            customBlockMessage
          }
          image {
            url
            key
          }
          lockoutUntil
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

// announcement management

export const UPLOAD_IMAGE = gql`
  query UploadImage($input: UploadImageInput!) {
    uploadImage(input: $input) {
      message
      status
      imageUrl {
        preSignedUrl
        keyName
        s3Url
        keyPath
        fullPath
      }
    }
  }
`;

export const GET_ALL_ANNOUNCEMENTS = gql`
  query GetAllAnnouncements(
    $orderBy: AnnouncementOrderByInput!
    $first: Int
    $filters: AnnouncementWhereInput
  ) {
    getAllAnnouncements(orderBy: $orderBy, first: $first, filters: $filters) {
      edges {
        cursor
        node {
          id
          title
          image {
            preSignedUrl
            keyName
            s3Url
            keyPath
            fullPath
          }
          imageUrl
          content
          createdBy
          status
          scheduledAt
          publishedAt
          createdAt
          updatedAt
          history {
            action
            actionBy
            actionAt
            details
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_NEXT_ANNOUNCEMENTS = gql`
  query GetAllAnnouncements(
    $orderBy: AnnouncementOrderByInput!
    $first: Int
    $filters: AnnouncementWhereInput
    $after: String
  ) {
    getAllAnnouncements(
      orderBy: $orderBy
      first: $first
      filters: $filters
      after: $after
    ) {
      edges {
        cursor
        node {
          id
          title
          image {
            preSignedUrl
            keyName
            s3Url
            keyPath
            fullPath
          }
          imageUrl
          content
          createdBy
          status
          scheduledAt
          publishedAt
          createdAt
          updatedAt
          history {
            action
            actionBy
            actionAt
            details
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_PREV_ANNOUNCEMENTS = gql`
  query GetAllAnnouncements(
    $orderBy: AnnouncementOrderByInput!
    $filters: AnnouncementWhereInput
    $before: String
    $last: Int
  ) {
    getAllAnnouncements(
      orderBy: $orderBy
      filters: $filters
      before: $before
      last: $last
    ) {
      edges {
        cursor
        node {
          id
          title
          image {
            preSignedUrl
            keyName
            s3Url
            keyPath
            fullPath
          }
          imageUrl
          content
          createdBy
          status
          scheduledAt
          publishedAt
          createdAt
          updatedAt
          history {
            action
            actionBy
            actionAt
            details
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_ANNOUNCEMENT_BY_ID = gql`
  query GetAnnouncementById($getAnnouncementByIdId: ID!) {
    getAnnouncementById(id: $getAnnouncementByIdId) {
      id
      title
      image
      content
      createdBy
      status
      scheduledAt
      publishedAt
      createdAt
      updatedAt
      history {
        action
        actionBy
        actionAt
        details
      }
    }
  }
`;

// user transaction
export const GET_USER_TRANSACTIONS = gql`
  query UserTransaction(
    $orderBy: OrderByInput!
    $first: Int
    $filters: BettingWhereInput
  ) {
    userTransaction(orderBy: $orderBy, first: $first, filters: $filters) {
      edges {
        cursor
        node {
          _id
          _user
          _bettingHistory
          status
          type
          amount
          gameType
          walletAddress
          txnHash
          roomId
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_NEXT_USER_TRANSACTIONS = gql`
  query UserTransaction(
    $orderBy: OrderByInput!
    $first: Int
    $filters: BettingWhereInput
    $after: String
  ) {
    userTransaction(
      orderBy: $orderBy
      first: $first
      filters: $filters
      after: $after
    ) {
      edges {
        cursor
        node {
          _id
          _user
          _bettingHistory
          status
          type
          amount
          gameType
          walletAddress
          txnHash
          roomId
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_PREV_USER_TRANSACTIONS = gql`
  query UserTransaction(
    $orderBy: OrderByInput!
    $last: Int
    $filters: BettingWhereInput
    $before: String
  ) {
    userTransaction(
      orderBy: $orderBy
      last: $last
      filters: $filters
      before: $before
    ) {
      edges {
        cursor
        node {
          _id
          _user
          _bettingHistory
          status
          type
          amount
          gameType
          walletAddress
          txnHash
          roomId
          createdAt
          updatedAt
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

// user bets
export const GET_USER_BETS = gql`
  query UserBetting(
    $orderBy: UserOrderByInput!
    $first: Int
    $filters: BettingWhereInput
  ) {
    userBetting(orderBy: $orderBy, first: $first, filters: $filters) {
      edges {
        cursor
        node {
          _id
          _user
          betAmount
          potentialPayout
          status
          gameType
          createdAt
          updatedAt
          transactionData {
            _id
            _user
            _bettingHistory
            status
            type
            amount
            gameType
            walletAddress
            txnHash
            roomId
            createdAt
            updatedAt
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_NEXT_USER_BETS = gql`
  query UserBetting(
    $orderBy: UserOrderByInput!
    $first: Int
    $filters: BettingWhereInput
    $after: String
  ) {
    userBetting(
      orderBy: $orderBy
      first: $first
      filters: $filters
      after: $after
    ) {
      edges {
        cursor
        node {
          _id
          _user
          betAmount
          potentialPayout
          status
          gameType
          createdAt
          updatedAt
          transactionData {
            _id
            _user
            _bettingHistory
            status
            type
            amount
            gameType
            walletAddress
            txnHash
            roomId
            createdAt
            updatedAt
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_PREV_USER_BETS = gql`
  query UserBetting(
    $orderBy: UserOrderByInput!
    $last: Int
    $filters: BettingWhereInput
    $before: String
  ) {
    userBetting(
      orderBy: $orderBy
      last: $last
      filters: $filters
      before: $before
    ) {
      edges {
        cursor
        node {
          _id
          _user
          betAmount
          potentialPayout
          status
          gameType
          createdAt
          updatedAt
          transactionData {
            _id
            _user
            _bettingHistory
            status
            type
            amount
            gameType
            walletAddress
            txnHash
            roomId
            createdAt
            updatedAt
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

// Platform Variables

export const GET_ALL_PLATFORM_VARIABLES = gql`
  query Query {
    variables {
      variables {
        isActive
        name
        value
      }
      id
    }
  }
`;
export const GET_ALL_FEATURED_COLLECTION = gql`
  query Collections(
    $orderBy: CollectionOrderByInput!
    $first: Int
    $filters: CollectionWhereInput
  ) {
    collections(orderBy: $orderBy, first: $first, filters: $filters) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
        }
      }
    }
  }
`;
export const GET_NEXT_FEATURED_COLLECTION = gql`
  query Collections(
    $orderBy: CollectionOrderByInput!
    $first: Int
    $filters: CollectionWhereInput
    $after: String
  ) {
    collections(
      orderBy: $orderBy
      first: $first
      filters: $filters
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
        }
      }
    }
  }
`;
export const GET_PREV_FEATURED_COLLECTION = gql`
  query Collections(
    $orderBy: CollectionOrderByInput!
    $first: Int
    $filters: CollectionWhereInput
    $before: String
  ) {
    collections(
      orderBy: $orderBy
      first: $first
      filters: $filters
      before: $before
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          name
          description
        }
      }
    }
  }
`;
export const SEARCH_COLLECTIONS = gql`
  query Collections(
    $orderBy: CollectionOrderByInput!
    $first: Int
    $filters: CollectionWhereInput
  ) {
    collections(orderBy: $orderBy, first: $first, filters: $filters) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

// Import Collection

export const GET_ALL_IMPORT_COLLECTION_REQUEST = gql`
  query Forms($orderBy: FormOrderByInput!, $first: Int) {
    forms(orderBy: $orderBy, first: $first) {
      edges {
        node {
          id
          collectionName
          collectionAddress
          collectionTotalNFTCount
          ownerAddress
          category {
            name
          }
          status
          isDeleted
          pendingNFTs
          collectionDescription
          collectionLinkExampleUri
          contactInfo
          avatar
          backgroundImg
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_NEXT_IMPORT_COLLECTION_REQUEST = gql`
  query Forms($orderBy: FormOrderByInput!, $first: Int, $after: String) {
    forms(orderBy: $orderBy, first: $first, after: $after) {
      edges {
        node {
          id
          collectionName
          collectionAddress
          collectionTotalNFTCount
          ownerAddress
          category {
            name
          }
          status
          isDeleted
          pendingNFTs
          collectionDescription
          collectionLinkExampleUri
          contactInfo
          avatar
          backgroundImg
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const GET_PREV_IMPORT_COLLECTION_REQUEST = gql`
  query Forms($orderBy: FormOrderByInput!, $first: Int, $before: String) {
    forms(orderBy: $orderBy, first: $first, before: $before) {
      edges {
        node {
          id
          collectionName
          collectionAddress
          collectionTotalNFTCount
          ownerAddress
          category {
            name
          }
          status
          isDeleted
          pendingNFTs
          collectionDescription
          collectionLinkExampleUri
          contactInfo
          avatar
          backgroundImg
        }
        cursor
      }
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const VIEW_COLLECTION_REQUEST = gql`
  query Form($formId: String!) {
    form(id: $formId) {
      id
      collectionName
      collectionAddress
      collectionTotalNFTCount
      ownerAddress
      category {
        name
      }
      owner {
        username
      }
      createdAt
      status
      isDeleted
      pendingNFTs
      collectionDescription
      collectionLinkExampleUri
      contactInfo
      avatar
      backgroundImg
    }
  }
`;

// Collectibles
export const GET_COLLECTIBLES = gql`
  query Items($orderBy: ItemOrderByInput!, $first: Int) {
    items(orderBy: $orderBy, first: $first) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          currentOwner {
            walletAddress
            id
          }
          createdBy {
            walletAddress
            id
          }
          tokenId
          title
          collection {
            name
          }
          nftType
          isImportedNFT
        }
      }
    }
  }
`;

export const GET_NEXT_COLLECTIBLES = gql`
  query Items($orderBy: ItemOrderByInput!, $first: Int, $after: String) {
    items(orderBy: $orderBy, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          currentOwner {
            walletAddress
            id
          }
          createdBy {
            walletAddress
            id
          }
          tokenId
          title
          collection {
            name
          }
          nftType
          isImportedNFT
        }
      }
    }
  }
`;

export const GET_PREV_COLLECTIBLES = gql`
  query Items($orderBy: ItemOrderByInput!, $first: Int, $before: String) {
    items(orderBy: $orderBy, first: $first, before: $before) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      edges {
        cursor
        node {
          id
          currentOwner {
            walletAddress
            id
          }
          createdBy {
            walletAddress
            id
          }
          tokenId
          title
          collection {
            name
          }
          nftType
          isImportedNFT
        }
      }
    }
  }
`;

export const VIEW_COLLECTIBLE = gql`
  query Item($itemId: String!) {
    item(id: $itemId) {
      id
      title
      description
      tokenId
      mediaUrl
      previewImage
      collection {
        name
      }
      currentOwner {
        walletAddress
      }
      nftContractAddress
      royalties
      numberOfCopies
      properties {
        trait_type
        value
      }
      tokenUri
      isBlockchainVerified
      blockchainCurrency
      initialPrice
      isUnlockable
      unlockableContent
      isAdultContent
      isBlackListed
      isActive
      isDeleted
      createdBy {
        walletAddress
      }
      status
      blockchainItemId
      isImportedNFT
    }
  }
`;

//CMS Queries
export const GET_ALL_CMS = gql`
  query Query {
    getAllCms {
      content
      createdAt
      id
      tab
      title
      updatedAt
    }
  }
`;

export const GET_CMS_BY_TAB = gql`
  query GetCmsByTab($tab: CmsTab!) {
    getCmsByTab(tab: $tab) {
      content
      createdAt
      id
      tab
      title
      updatedAt
    }
  }
`;

export const GET_DASHBOARD_DATA = gql`
  query Dashboard {
    dashboard {
      message
      status
      userdata {
        totalCount
        countIncreased
      }
      avgBetAmount {
        totalCount
        countIncreased
      }
      totalTransaction {
        totalCount
        countIncreased
      }
      BettingHistoryData {
        totalCount
        countIncreased
      }
    }
  }
`;

export const GET_GAME_DATA_DASHBOARD = gql`
  query GameDataDashboard {
    gameDataDashboard {
      message
      status
      gameData {
        gameType
        totalBets
        winCount
        lossCount
        winLossRatio
        totalBetAmount
        totalPayout
        avgBetAmount
      }
    }
  }
`;
