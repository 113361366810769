/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router';
import { Typography, Box } from '@material-ui/core';

import {
  GET_ALL_IMPORT_COLLECTION_REQUEST,
  GET_NEXT_IMPORT_COLLECTION_REQUEST,
  GET_PREV_IMPORT_COLLECTION_REQUEST,
} from 'graphql/query/admin';
import Table from 'Components/Table/Table';
import { Loader } from 'Components/TableLoader';
import { useStyles } from './TableStyles';

const ImportCollectionRequest = ({
  actionPerformed,
  setActionPerformed,
  errorMsg,
  setErrorMsg,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [collections, setCollections] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [pageInfo, setPageInfo] = useState('');
  const [page, setPage] = useState(0);
  const {
    data, refetch, error, loading,
  } = useQuery(GET_ALL_IMPORT_COLLECTION_REQUEST, {
    variables: {
      first: 10,
      orderBy: 'createdAt_DESC',
    },
    fetchPolicy: 'network-only',
  });

  const [getNextPage] = useLazyQuery(GET_NEXT_IMPORT_COLLECTION_REQUEST);
  const [getPrevPage] = useLazyQuery(GET_PREV_IMPORT_COLLECTION_REQUEST);

  const handleNextPage = async () => {
    if (pageInfo.hasNextPage) {
      const nextPageData = await getNextPage({
        variables: {
          first: 10,
          after: pageInfo.endCursor,
          orderBy: 'createdAt_DESC',
        },
        fetchPolicy: 'network-only',
      });

      if (nextPageData.data.forms) {
        const list = await nextPageData.data.forms.edges.map((form) => form.node);
        setCollections(list);
        setPage(page + 1);
        setPageInfo(nextPageData.data.forms.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const handlePreviousPage = async () => {
    if (pageInfo.hasPreviousPage) {
      const prevPageData = await getPrevPage({
        variables: {
          first: 10,
          before: pageInfo.startCursor,
          orderBy: 'createdAt_DESC',
        },
        fetchPolicy: 'network-only',
      });

      if (prevPageData.data.forms) {
        const list = await prevPageData.data.forms.edges.map((form) => form.node);
        setCollections(list);
        setPage(page - 1);
        setPageInfo(prevPageData.data.forms.pageInfo);
        setErrorMsg('');
      }
    }
  };

  const setAllCollectionRequest = async () => {
    // Display All Data
    if (data && data.forms) {
      const list = await data.forms.edges.map((collection) => collection.node);
      setPageInfo(data.forms.pageInfo);
      setCollections(list);
      setErrorMsg('');
    }
    setPage(0);
  };

  useEffect(() => {
    if (actionPerformed) {
      refetch();
      setActionPerformed(false);
      setAllCollectionRequest();
    }
    if (error) { setErrorMsg('Error in loading data !'); }

    setAllCollectionRequest();
  }, [data, actionPerformed, error]);

  const handleViewButton = (id) => {
    navigate('/admin/import-collection/view', { state: { id } });
  };

  const TABLE_HEAD = [
    {
      id: 'id', flag: 'id', label: 'Sr.No', alignRight: false,
    },
    {
      id: 'collectionName', flag: 'collectionName', label: 'Collection Name', alignRight: false,
    },
    {
      id: 'collectionAddress', flag: 'collectionAddress', label: 'Collection Address', alignRight: false,
    },
    {
      id: 'category', flag: 'adminRole', label: 'Category', alignRight: false,
    },
    {
      id: 'isStatus', flag: 'status', label: 'Status', alignRight: false,
    },
    {
      id: 'actions',
      flag: { read: true, edit: false, remove: false },
      callback: { handleViewButton },
      label: 'Actions',
      alignRight: false,
    },
  ];

  if (loading) {
    return <Loader />;
  }

  if (collections && collections.length > 0) {
    return (
      <Box style={{ minHeight: '100vh' }}>
        { errorMsg.length > 0 && <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography> }

        <Table
          USERLIST={collections}
          TABLE_HEAD={TABLE_HEAD}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          page={page}
          setPage={setPage}
        />
      </Box>
    );
  }
  return (
    <Box style={{ minHeight: '70vh' }}>
      { errorMsg.length > 0 ? <Typography variant="h2" className={classes.errorText}>{errorMsg}</Typography>
        : <Typography variant="h5">No Data Found !</Typography> }
    </Box>
  );
};

export default ImportCollectionRequest;
