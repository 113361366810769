/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  paddingAll: {
    padding: '0px 20px',
  },
  header1: {
    fontWeight: 'bold',
    marginBottom: '20px',
    fontSize: '20px',
  },
  dFlex: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
}));

export { useStyles };
