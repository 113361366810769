import React, { useState } from "react";
import { Box } from "@material-ui/core";

import { announcementStyles } from "./announcementStyles";
import Topbar from "./Components/TopBar/TopBar";
import Table from "./Components/Table/Table";
import AnnouncementModal from "Components/Modal/AnnouncementModal";
import { useQuery } from "@apollo/client";
import { ADMIN_PROFILE } from "graphql/query/admin";

const AnnouncementManagement = () => {
  const classes = announcementStyles();
  const [actionPerformed, setActionPerformed] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [filter, setFilter] = useState(false);
  const [userFilter, setUserFilter] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("create");

  const handleCreateAnnouncement = () => {
    setOpenModal(true);
    setMode("create");
  };

  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
    refetch: profileRefetch,
  } = useQuery(ADMIN_PROFILE, {
    fetchPolicy: "network-only",
  });

  const canUpdateAnnouncement =
    profileData?.me?.permissions?.Announcement?.UPDATE ?? false;

  return (
    <Box>
      <Topbar
        title={canUpdateAnnouncement ? "Create Announcement" : ""}
        onButtonClick={handleCreateAnnouncement}
        userFilter={userFilter}
        setActionPerformed={setActionPerformed}
        setUserFilter={setUserFilter}
        setFilter={setFilter}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <Box className={classes.paddingAll}>
        <Table
          userFilter={userFilter}
          filter={filter}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          actionPerformed={actionPerformed}
          setActionPerformed={setActionPerformed}
          canUpdateAnnouncement={canUpdateAnnouncement}
          searchQuery={searchQuery}
        />
      </Box>
      {openModal && (
        <AnnouncementModal
          open={openModal}
          setActionPerformed={setActionPerformed}
          mode={mode}
          handleClose={() => setOpenModal(false)}
          heading="Create Announcement"
          handleOnEditSuccess={() => {
            setOpenModal(false);
          }}
        />
      )}
    </Box>
  );
};
export default AnnouncementManagement;
