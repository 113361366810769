/* eslint-disable react/prop-types */
import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import { Grid, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { permissons } from '_mocks/permissonLabels';
import { SwitchStyles1, topBarStyles } from '../../administratorStyles';

export default function Switch1({
  permissionLabel,
  originalPermissions,
  permissionName,
  componentName,
  value,
  formik,
}) {
  const classes = topBarStyles();
  return (
    <FormGroup>
      <Typography component="div">
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography className={classes.permissKey}>{permissionLabel}</Typography>
            <Box className={classes.displayFlex}>
              { console.log(originalPermissions)}
              {Object.entries(value).map(([key2, value1]) => [
                key2 !== 'label' ? (
                  <Box className={classes.displayFlex1}>
                    <SwitchStyles1
                      key="1"
                      name={componentName === 'Role' ? `permissions.${permissionName}.${key2}` : `permissions.${permissionName}.${key2}`}
                      checked={value1}
                      onChange={(value1 && originalPermissions[permissionName][key2]) ? '' : formik}
                      id={`permissions.${permissionName}.${key2}`}
                    />
                    <Typography className={classes.labelKey}>
                      {permissons[permissionName][key2]}
                    </Typography>
                  </Box>
                ) : null,
              ])}
            </Box>
          </Grid>
        </Grid>
      </Typography>
    </FormGroup>
  );
}
